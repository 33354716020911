* {
  font-family: 'IM FELL Double Pica', sans-serif;
}

@import url('https://fonts.cdnfonts.com/css/dingle-berries');
@import url('https://fonts.cdnfonts.com/css/kawalkingonmars');
@import url('https://fonts.cdnfonts.com/css/underdog');
@import url('https://fonts.cdnfonts.com/css/im-fell-double-pica');

.status {
  color: #0094f3;
}

a {
  color: rgb(193, 208, 248);
  font-size: large;
  cursor: pointer;
}

@media screen and (max-width: 499px) {
  .ser {
    color: rgb(0, 0, 0);
    font-size: 16px;
  }

  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }

  .closeNote2 {
    position: fixed;
    right: 0;
    top: 2%;
    left: 75%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote2:hover,
  .closeNote2:focus {
    color: rgb(71, 70, 70);
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    display: none;

  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.856);
    position: fixed;
    width: 80%;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #414141;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #252525;
    z-index: 100000;
    padding: 5%;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    background-color: #ffffffb0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }


  .mainImage {
    position: relative;
    bottom: 0;

  }

  .beast1 {
    width: 480px;
    bottom: 0;
  }

  .beast2 {
    width: 480px;
    bottom: 0;
    position: relative;
    animation: mainImageAnimation ease 2s infinite;

  }

  @keyframes mainImageAnimation {
    0% {
      opacity: 0;
    }

    49% {

      opacity: 0;

    }

    50% {

      opacity: 1;

    }

    100% {
      opacity: 1;
    }
  }


  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 14s, 5348ms;
    animation-delay: 0ms, 3421ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(44vh) scale(0.62);
    }

    4% {
      transform: translateX(-4vw) translateY(50vh) scale(0.77);
    }

    8% {
      transform: translateX(43vw) translateY(-35vh) scale(0.85);
    }

    12% {
      transform: translateX(6vw) translateY(-16vh) scale(0.32);
    }

    16% {
      transform: translateX(40vw) translateY(-28vh) scale(0.61);
    }

    20% {
      transform: translateX(16vw) translateY(-33vh) scale(0.62);
    }

    24% {
      transform: translateX(-13vw) translateY(12vh) scale(0.99);
    }

    28% {
      transform: translateX(-6vw) translateY(15vh) scale(0.52);
    }

    32% {
      transform: translateX(39vw) translateY(40vh) scale(0.42);
    }

    36% {
      transform: translateX(-19vw) translateY(-31vh) scale(0.72);
    }

    40% {
      transform: translateX(47vw) translateY(18vh) scale(0.74);
    }

    44% {
      transform: translateX(-24vw) translateY(1vh) scale(0.8);
    }

    48% {
      transform: translateX(36vw) translateY(-6vh) scale(0.65);
    }

    52% {
      transform: translateX(-6vw) translateY(11vh) scale(0.28);
    }

    56% {
      transform: translateX(34vw) translateY(35vh) scale(0.79);
    }

    60% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.48);
    }

    64% {
      transform: translateX(33vw) translateY(-1vh) scale(0.47);
    }

    68% {
      transform: translateX(-45vw) translateY(-41vh) scale(0.54);
    }

    72% {
      transform: translateX(-20vw) translateY(40vh) scale(0.92);
    }

    76% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.64);
    }

    80% {
      transform: translateX(-23vw) translateY(-18vh) scale(0.92);
    }

    84% {
      transform: translateX(-37vw) translateY(7vh) scale(0.98);
    }

    88% {
      transform: translateX(-31vw) translateY(-35vh) scale(0.92);
    }

    92% {
      transform: translateX(1vw) translateY(-43vh) scale(0.29);
    }

    96% {
      transform: translateX(-41vw) translateY(-20vh) scale(0.44);
    }

    100% {
      transform: translateX(-4vw) translateY(-33vh) scale(0.38);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 11s, 6289ms;
    animation-delay: 0ms, 6547ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-17vw) translateY(40vh) scale(0.32);
    }

    3.5714285714% {
      transform: translateX(9vw) translateY(34vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-21vw) translateY(-5vh) scale(0.64);
    }

    10.7142857143% {
      transform: translateX(47vw) translateY(6vh) scale(0.76);
    }

    14.2857142857% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.93);
    }

    17.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.36);
    }

    21.4285714286% {
      transform: translateX(-34vw) translateY(38vh) scale(0.46);
    }

    25% {
      transform: translateX(-23vw) translateY(-42vh) scale(0.58);
    }

    28.5714285714% {
      transform: translateX(6vw) translateY(-31vh) scale(0.93);
    }

    32.1428571429% {
      transform: translateX(-37vw) translateY(31vh) scale(0.47);
    }

    35.7142857143% {
      transform: translateX(49vw) translateY(-37vh) scale(0.58);
    }

    39.2857142857% {
      transform: translateX(13vw) translateY(-42vh) scale(0.86);
    }

    42.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.48);
    }

    46.4285714286% {
      transform: translateX(-13vw) translateY(-13vh) scale(0.85);
    }

    50% {
      transform: translateX(7vw) translateY(49vh) scale(0.39);
    }

    53.5714285714% {
      transform: translateX(44vw) translateY(-15vh) scale(0.9);
    }

    57.1428571429% {
      transform: translateX(-2vw) translateY(-20vh) scale(0.27);
    }

    60.7142857143% {
      transform: translateX(-3vw) translateY(34vh) scale(0.51);
    }

    64.2857142857% {
      transform: translateX(-40vw) translateY(-5vh) scale(0.39);
    }

    67.8571428571% {
      transform: translateX(1vw) translateY(39vh) scale(0.53);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(-12vh) scale(0.45);
    }

    75% {
      transform: translateX(-17vw) translateY(-36vh) scale(0.63);
    }

    78.5714285714% {
      transform: translateX(27vw) translateY(36vh) scale(0.92);
    }

    82.1428571429% {
      transform: translateX(18vw) translateY(-5vh) scale(0.8);
    }

    85.7142857143% {
      transform: translateX(40vw) translateY(13vh) scale(0.92);
    }

    89.2857142857% {
      transform: translateX(3vw) translateY(-4vh) scale(0.33);
    }

    92.8571428571% {
      transform: translateX(-31vw) translateY(43vh) scale(0.71);
    }

    96.4285714286% {
      transform: translateX(-42vw) translateY(-18vh) scale(0.64);
    }

    100% {
      transform: translateX(24vw) translateY(14vh) scale(0.67);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 9s, 7991ms;
    animation-delay: 0ms, 1922ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-22vw) translateY(24vh) scale(0.62);
    }

    3.5714285714% {
      transform: translateX(-44vw) translateY(37vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-47vw) translateY(46vh) scale(0.91);
    }

    10.7142857143% {
      transform: translateX(39vw) translateY(15vh) scale(0.67);
    }

    14.2857142857% {
      transform: translateX(7vw) translateY(44vh) scale(0.73);
    }

    17.8571428571% {
      transform: translateX(40vw) translateY(11vh) scale(0.56);
    }

    21.4285714286% {
      transform: translateX(23vw) translateY(-30vh) scale(0.98);
    }

    25% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.4);
    }

    28.5714285714% {
      transform: translateX(31vw) translateY(30vh) scale(0.78);
    }

    32.1428571429% {
      transform: translateX(-10vw) translateY(-22vh) scale(0.7);
    }

    35.7142857143% {
      transform: translateX(-31vw) translateY(2vh) scale(0.87);
    }

    39.2857142857% {
      transform: translateX(45vw) translateY(-3vh) scale(0.33);
    }

    42.8571428571% {
      transform: translateX(-7vw) translateY(-30vh) scale(0.88);
    }

    46.4285714286% {
      transform: translateX(8vw) translateY(22vh) scale(0.51);
    }

    50% {
      transform: translateX(31vw) translateY(25vh) scale(0.31);
    }

    53.5714285714% {
      transform: translateX(47vw) translateY(13vh) scale(0.76);
    }

    57.1428571429% {
      transform: translateX(-19vw) translateY(7vh) scale(0.36);
    }

    60.7142857143% {
      transform: translateX(11vw) translateY(6vh) scale(0.83);
    }

    64.2857142857% {
      transform: translateX(48vw) translateY(-38vh) scale(0.62);
    }

    67.8571428571% {
      transform: translateX(41vw) translateY(-18vh) scale(0.68);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(50vh) scale(0.34);
    }

    75% {
      transform: translateX(-31vw) translateY(-15vh) scale(0.51);
    }

    78.5714285714% {
      transform: translateX(-41vw) translateY(9vh) scale(0.91);
    }

    82.1428571429% {
      transform: translateX(-39vw) translateY(16vh) scale(0.45);
    }

    85.7142857143% {
      transform: translateX(-44vw) translateY(9vh) scale(0.83);
    }

    89.2857142857% {
      transform: translateX(-41vw) translateY(8vh) scale(0.66);
    }

    92.8571428571% {
      transform: translateX(-44vw) translateY(42vh) scale(0.56);
    }

    96.4285714286% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.86);
    }

    100% {
      transform: translateX(-4vw) translateY(-8vh) scale(0.42);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 17s, 5968ms;
    animation-delay: 0ms, 1513ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(45vw) translateY(13vh) scale(0.5);
    }

    5.2631578947% {
      transform: translateX(37vw) translateY(14vh) scale(0.98);
    }

    10.5263157895% {
      transform: translateX(-6vw) translateY(-16vh) scale(0.7);
    }

    15.7894736842% {
      transform: translateX(-24vw) translateY(-47vh) scale(0.29);
    }

    21.0526315789% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.63);
    }

    26.3157894737% {
      transform: translateX(-11vw) translateY(-16vh) scale(0.49);
    }

    31.5789473684% {
      transform: translateX(-11vw) translateY(7vh) scale(0.45);
    }

    36.8421052632% {
      transform: translateX(13vw) translateY(-6vh) scale(0.34);
    }

    42.1052631579% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.37);
    }

    47.3684210526% {
      transform: translateX(5vw) translateY(25vh) scale(0.53);
    }

    52.6315789474% {
      transform: translateX(27vw) translateY(14vh) scale(0.74);
    }

    57.8947368421% {
      transform: translateX(-26vw) translateY(-28vh) scale(0.64);
    }

    63.1578947368% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.46);
    }

    68.4210526316% {
      transform: translateX(-23vw) translateY(17vh) scale(0.37);
    }

    73.6842105263% {
      transform: translateX(9vw) translateY(-6vh) scale(0.69);
    }

    78.9473684211% {
      transform: translateX(-41vw) translateY(-42vh) scale(0.75);
    }

    84.2105263158% {
      transform: translateX(34vw) translateY(1vh) scale(0.86);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-26vh) scale(0.91);
    }

    94.7368421053% {
      transform: translateX(-26vw) translateY(13vh) scale(0.49);
    }

    100% {
      transform: translateX(1vw) translateY(-1vh) scale(0.76);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 13s, 9409ms;
    animation-delay: 0ms, 3495ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(34vw) translateY(18vh) scale(0.64);
    }

    4.1666666667% {
      transform: translateX(34vw) translateY(11vh) scale(0.39);
    }

    8.3333333333% {
      transform: translateX(-37vw) translateY(-43vh) scale(0.47);
    }

    12.5% {
      transform: translateX(17vw) translateY(-5vh) scale(0.49);
    }

    16.6666666667% {
      transform: translateX(30vw) translateY(7vh) scale(0.47);
    }

    20.8333333333% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.76);
    }

    25% {
      transform: translateX(-20vw) translateY(28vh) scale(0.7);
    }

    29.1666666667% {
      transform: translateX(-16vw) translateY(-5vh) scale(0.84);
    }

    33.3333333333% {
      transform: translateX(-17vw) translateY(-41vh) scale(0.42);
    }

    37.5% {
      transform: translateX(19vw) translateY(-20vh) scale(0.81);
    }

    41.6666666667% {
      transform: translateX(12vw) translateY(15vh) scale(0.75);
    }

    45.8333333333% {
      transform: translateX(30vw) translateY(-32vh) scale(0.47);
    }

    50% {
      transform: translateX(-40vw) translateY(-38vh) scale(0.59);
    }

    54.1666666667% {
      transform: translateX(4vw) translateY(44vh) scale(0.82);
    }

    58.3333333333% {
      transform: translateX(44vw) translateY(-14vh) scale(0.55);
    }

    62.5% {
      transform: translateX(-15vw) translateY(39vh) scale(0.33);
    }

    66.6666666667% {
      transform: translateX(-28vw) translateY(-30vh) scale(0.29);
    }

    70.8333333333% {
      transform: translateX(40vw) translateY(27vh) scale(0.61);
    }

    75% {
      transform: translateX(22vw) translateY(-21vh) scale(0.53);
    }

    79.1666666667% {
      transform: translateX(-49vw) translateY(21vh) scale(0.64);
    }

    83.3333333333% {
      transform: translateX(7vw) translateY(14vh) scale(0.81);
    }

    87.5% {
      transform: translateX(12vw) translateY(14vh) scale(0.8);
    }

    91.6666666667% {
      transform: translateX(-44vw) translateY(-42vh) scale(0.49);
    }

    95.8333333333% {
      transform: translateX(43vw) translateY(41vh) scale(0.6);
    }

    100% {
      transform: translateX(6vw) translateY(-29vh) scale(0.7);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 10229ms;
    animation-delay: 0ms, 7081ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(23vw) translateY(18vh) scale(0.76);
    }

    4.1666666667% {
      transform: translateX(-26vw) translateY(6vh) scale(0.41);
    }

    8.3333333333% {
      transform: translateX(-1vw) translateY(-18vh) scale(0.63);
    }

    12.5% {
      transform: translateX(22vw) translateY(43vh) scale(0.63);
    }

    16.6666666667% {
      transform: translateX(25vw) translateY(48vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(3vw) translateY(-29vh) scale(0.33);
    }

    25% {
      transform: translateX(26vw) translateY(0vh) scale(0.86);
    }

    29.1666666667% {
      transform: translateX(-33vw) translateY(5vh) scale(0.51);
    }

    33.3333333333% {
      transform: translateX(4vw) translateY(-9vh) scale(0.39);
    }

    37.5% {
      transform: translateX(26vw) translateY(12vh) scale(0.65);
    }

    41.6666666667% {
      transform: translateX(31vw) translateY(18vh) scale(0.42);
    }

    45.8333333333% {
      transform: translateX(25vw) translateY(-45vh) scale(0.9);
    }

    50% {
      transform: translateX(-11vw) translateY(49vh) scale(0.46);
    }

    54.1666666667% {
      transform: translateX(-45vw) translateY(48vh) scale(0.81);
    }

    58.3333333333% {
      transform: translateX(46vw) translateY(5vh) scale(0.57);
    }

    62.5% {
      transform: translateX(35vw) translateY(-47vh) scale(0.56);
    }

    66.6666666667% {
      transform: translateX(22vw) translateY(-44vh) scale(0.83);
    }

    70.8333333333% {
      transform: translateX(-40vw) translateY(-11vh) scale(0.73);
    }

    75% {
      transform: translateX(-19vw) translateY(13vh) scale(0.69);
    }

    79.1666666667% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.83);
    }

    83.3333333333% {
      transform: translateX(33vw) translateY(21vh) scale(0.67);
    }

    87.5% {
      transform: translateX(-36vw) translateY(27vh) scale(0.79);
    }

    91.6666666667% {
      transform: translateX(5vw) translateY(17vh) scale(0.56);
    }

    95.8333333333% {
      transform: translateX(29vw) translateY(-10vh) scale(0.51);
    }

    100% {
      transform: translateX(-1vw) translateY(-14vh) scale(0.29);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 18s, 6470ms;
    animation-delay: 0ms, 8336ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(40vw) translateY(47vh) scale(1);
    }

    3.5714285714% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.78);
    }

    7.1428571429% {
      transform: translateX(24vw) translateY(17vh) scale(0.28);
    }

    10.7142857143% {
      transform: translateX(41vw) translateY(23vh) scale(0.96);
    }

    14.2857142857% {
      transform: translateX(14vw) translateY(13vh) scale(0.95);
    }

    17.8571428571% {
      transform: translateX(-17vw) translateY(49vh) scale(0.69);
    }

    21.4285714286% {
      transform: translateX(9vw) translateY(20vh) scale(0.29);
    }

    25% {
      transform: translateX(-11vw) translateY(30vh) scale(0.96);
    }

    28.5714285714% {
      transform: translateX(32vw) translateY(-27vh) scale(0.43);
    }

    32.1428571429% {
      transform: translateX(-48vw) translateY(-7vh) scale(0.9);
    }

    35.7142857143% {
      transform: translateX(-19vw) translateY(49vh) scale(0.41);
    }

    39.2857142857% {
      transform: translateX(7vw) translateY(-36vh) scale(0.5);
    }

    42.8571428571% {
      transform: translateX(-3vw) translateY(-40vh) scale(0.78);
    }

    46.4285714286% {
      transform: translateX(47vw) translateY(6vh) scale(0.26);
    }

    50% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.3);
    }

    53.5714285714% {
      transform: translateX(-38vw) translateY(-10vh) scale(0.71);
    }

    57.1428571429% {
      transform: translateX(36vw) translateY(-4vh) scale(0.96);
    }

    60.7142857143% {
      transform: translateX(16vw) translateY(6vh) scale(0.62);
    }

    64.2857142857% {
      transform: translateX(-30vw) translateY(-39vh) scale(0.52);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(33vh) scale(0.45);
    }

    71.4285714286% {
      transform: translateX(-10vw) translateY(-28vh) scale(0.78);
    }

    75% {
      transform: translateX(48vw) translateY(16vh) scale(0.71);
    }

    78.5714285714% {
      transform: translateX(-45vw) translateY(-39vh) scale(0.63);
    }

    82.1428571429% {
      transform: translateX(22vw) translateY(-49vh) scale(0.37);
    }

    85.7142857143% {
      transform: translateX(49vw) translateY(27vh) scale(0.26);
    }

    89.2857142857% {
      transform: translateX(23vw) translateY(-44vh) scale(0.73);
    }

    92.8571428571% {
      transform: translateX(-2vw) translateY(29vh) scale(0.47);
    }

    96.4285714286% {
      transform: translateX(-2vw) translateY(-30vh) scale(0.57);
    }

    100% {
      transform: translateX(-10vw) translateY(-29vh) scale(0.96);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 15s, 6398ms;
    animation-delay: 0ms, 565ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(8vw) translateY(-41vh) scale(0.92);
    }

    4.5454545455% {
      transform: translateX(-26vw) translateY(43vh) scale(0.97);
    }

    9.0909090909% {
      transform: translateX(38vw) translateY(-44vh) scale(0.43);
    }

    13.6363636364% {
      transform: translateX(-35vw) translateY(40vh) scale(0.78);
    }

    18.1818181818% {
      transform: translateX(-7vw) translateY(-35vh) scale(0.68);
    }

    22.7272727273% {
      transform: translateX(-43vw) translateY(-29vh) scale(0.48);
    }

    27.2727272727% {
      transform: translateX(-18vw) translateY(27vh) scale(0.7);
    }

    31.8181818182% {
      transform: translateX(42vw) translateY(-45vh) scale(0.77);
    }

    36.3636363636% {
      transform: translateX(1vw) translateY(-13vh) scale(0.84);
    }

    40.9090909091% {
      transform: translateX(35vw) translateY(-26vh) scale(0.84);
    }

    45.4545454545% {
      transform: translateX(-3vw) translateY(48vh) scale(0.29);
    }

    50% {
      transform: translateX(9vw) translateY(-4vh) scale(0.99);
    }

    54.5454545455% {
      transform: translateX(-36vw) translateY(-36vh) scale(0.5);
    }

    59.0909090909% {
      transform: translateX(42vw) translateY(-44vh) scale(0.39);
    }

    63.6363636364% {
      transform: translateX(26vw) translateY(20vh) scale(0.76);
    }

    68.1818181818% {
      transform: translateX(30vw) translateY(48vh) scale(0.9);
    }

    72.7272727273% {
      transform: translateX(-9vw) translateY(35vh) scale(0.97);
    }

    77.2727272727% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.53);
    }

    81.8181818182% {
      transform: translateX(-49vw) translateY(-29vh) scale(0.26);
    }

    86.3636363636% {
      transform: translateX(-24vw) translateY(36vh) scale(0.36);
    }

    90.9090909091% {
      transform: translateX(-33vw) translateY(20vh) scale(0.52);
    }

    95.4545454545% {
      transform: translateX(14vw) translateY(10vh) scale(0.8);
    }

    100% {
      transform: translateX(14vw) translateY(-30vh) scale(0.54);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 15s, 5044ms;
    animation-delay: 0ms, 1556ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(27vw) translateY(-37vh) scale(0.9);
    }

    4.1666666667% {
      transform: translateX(12vw) translateY(-22vh) scale(0.44);
    }

    8.3333333333% {
      transform: translateX(-13vw) translateY(-28vh) scale(0.53);
    }

    12.5% {
      transform: translateX(-43vw) translateY(18vh) scale(0.39);
    }

    16.6666666667% {
      transform: translateX(34vw) translateY(31vh) scale(0.97);
    }

    20.8333333333% {
      transform: translateX(47vw) translateY(-33vh) scale(0.97);
    }

    25% {
      transform: translateX(35vw) translateY(-47vh) scale(0.42);
    }

    29.1666666667% {
      transform: translateX(-47vw) translateY(39vh) scale(0.4);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-45vh) scale(0.65);
    }

    37.5% {
      transform: translateX(43vw) translateY(-23vh) scale(0.6);
    }

    41.6666666667% {
      transform: translateX(38vw) translateY(15vh) scale(0.63);
    }

    45.8333333333% {
      transform: translateX(49vw) translateY(34vh) scale(0.82);
    }

    50% {
      transform: translateX(-30vw) translateY(-9vh) scale(0.91);
    }

    54.1666666667% {
      transform: translateX(18vw) translateY(-31vh) scale(0.73);
    }

    58.3333333333% {
      transform: translateX(32vw) translateY(-11vh) scale(0.97);
    }

    62.5% {
      transform: translateX(-12vw) translateY(31vh) scale(0.77);
    }

    66.6666666667% {
      transform: translateX(26vw) translateY(12vh) scale(0.84);
    }

    70.8333333333% {
      transform: translateX(2vw) translateY(-46vh) scale(0.38);
    }

    75% {
      transform: translateX(46vw) translateY(35vh) scale(0.79);
    }

    79.1666666667% {
      transform: translateX(50vw) translateY(26vh) scale(0.46);
    }

    83.3333333333% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.48);
    }

    87.5% {
      transform: translateX(6vw) translateY(-18vh) scale(0.78);
    }

    91.6666666667% {
      transform: translateX(33vw) translateY(-31vh) scale(0.75);
    }

    95.8333333333% {
      transform: translateX(27vw) translateY(-13vh) scale(0.52);
    }

    100% {
      transform: translateX(4vw) translateY(11vh) scale(0.86);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 5550ms;
    animation-delay: 0ms, 4125ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-26vw) translateY(-38vh) scale(0.49);
    }

    5.5555555556% {
      transform: translateX(21vw) translateY(7vh) scale(0.8);
    }

    11.1111111111% {
      transform: translateX(-43vw) translateY(-2vh) scale(0.75);
    }

    16.6666666667% {
      transform: translateX(29vw) translateY(-17vh) scale(1);
    }

    22.2222222222% {
      transform: translateX(-43vw) translateY(13vh) scale(0.88);
    }

    27.7777777778% {
      transform: translateX(33vw) translateY(-48vh) scale(0.86);
    }

    33.3333333333% {
      transform: translateX(17vw) translateY(-23vh) scale(0.95);
    }

    38.8888888889% {
      transform: translateX(-7vw) translateY(39vh) scale(0.4);
    }

    44.4444444444% {
      transform: translateX(-44vw) translateY(-35vh) scale(0.39);
    }

    50% {
      transform: translateX(-23vw) translateY(-10vh) scale(0.54);
    }

    55.5555555556% {
      transform: translateX(8vw) translateY(39vh) scale(0.63);
    }

    61.1111111111% {
      transform: translateX(-3vw) translateY(28vh) scale(0.46);
    }

    66.6666666667% {
      transform: translateX(19vw) translateY(-35vh) scale(0.55);
    }

    72.2222222222% {
      transform: translateX(-32vw) translateY(1vh) scale(0.87);
    }

    77.7777777778% {
      transform: translateX(-18vw) translateY(11vh) scale(0.62);
    }

    83.3333333333% {
      transform: translateX(-24vw) translateY(-31vh) scale(0.42);
    }

    88.8888888889% {
      transform: translateX(5vw) translateY(34vh) scale(0.5);
    }

    94.4444444444% {
      transform: translateX(-11vw) translateY(32vh) scale(0.36);
    }

    100% {
      transform: translateX(5vw) translateY(-8vh) scale(0.99);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 16s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 16s, 10297ms;
    animation-delay: 0ms, 4707ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-36vw) translateY(-2vh) scale(0.37);
    }

    4.7619047619% {
      transform: translateX(-27vw) translateY(30vh) scale(0.38);
    }

    9.5238095238% {
      transform: translateX(45vw) translateY(-23vh) scale(0.33);
    }

    14.2857142857% {
      transform: translateX(25vw) translateY(9vh) scale(0.7);
    }

    19.0476190476% {
      transform: translateX(24vw) translateY(-13vh) scale(0.48);
    }

    23.8095238095% {
      transform: translateX(43vw) translateY(-5vh) scale(0.68);
    }

    28.5714285714% {
      transform: translateX(41vw) translateY(2vh) scale(0.83);
    }

    33.3333333333% {
      transform: translateX(-46vw) translateY(-13vh) scale(0.7);
    }

    38.0952380952% {
      transform: translateX(-39vw) translateY(-25vh) scale(0.85);
    }

    42.8571428571% {
      transform: translateX(-27vw) translateY(23vh) scale(0.33);
    }

    47.619047619% {
      transform: translateX(-13vw) translateY(45vh) scale(0.3);
    }

    52.380952381% {
      transform: translateX(10vw) translateY(-28vh) scale(0.55);
    }

    57.1428571429% {
      transform: translateX(-30vw) translateY(-16vh) scale(0.26);
    }

    61.9047619048% {
      transform: translateX(40vw) translateY(30vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(45vw) translateY(37vh) scale(0.79);
    }

    71.4285714286% {
      transform: translateX(-13vw) translateY(-24vh) scale(0.62);
    }

    76.1904761905% {
      transform: translateX(33vw) translateY(-12vh) scale(0.94);
    }

    80.9523809524% {
      transform: translateX(10vw) translateY(-49vh) scale(0.3);
    }

    85.7142857143% {
      transform: translateX(-15vw) translateY(48vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(-42vw) translateY(40vh) scale(0.54);
    }

    95.2380952381% {
      transform: translateX(-22vw) translateY(0vh) scale(0.63);
    }

    100% {
      transform: translateX(32vw) translateY(14vh) scale(0.79);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 9s, 8018ms;
    animation-delay: 0ms, 4673ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(-30vw) translateY(47vh) scale(0.57);
    }

    3.5714285714% {
      transform: translateX(-19vw) translateY(19vh) scale(0.52);
    }

    7.1428571429% {
      transform: translateX(-14vw) translateY(48vh) scale(0.9);
    }

    10.7142857143% {
      transform: translateX(-44vw) translateY(13vh) scale(0.38);
    }

    14.2857142857% {
      transform: translateX(-10vw) translateY(43vh) scale(0.49);
    }

    17.8571428571% {
      transform: translateX(39vw) translateY(49vh) scale(0.79);
    }

    21.4285714286% {
      transform: translateX(50vw) translateY(-17vh) scale(0.4);
    }

    25% {
      transform: translateX(-20vw) translateY(22vh) scale(0.48);
    }

    28.5714285714% {
      transform: translateX(-27vw) translateY(32vh) scale(0.92);
    }

    32.1428571429% {
      transform: translateX(11vw) translateY(-7vh) scale(0.99);
    }

    35.7142857143% {
      transform: translateX(-27vw) translateY(46vh) scale(0.34);
    }

    39.2857142857% {
      transform: translateX(-24vw) translateY(3vh) scale(0.36);
    }

    42.8571428571% {
      transform: translateX(48vw) translateY(-30vh) scale(0.33);
    }

    46.4285714286% {
      transform: translateX(-44vw) translateY(-30vh) scale(0.5);
    }

    50% {
      transform: translateX(44vw) translateY(-2vh) scale(0.57);
    }

    53.5714285714% {
      transform: translateX(-47vw) translateY(-18vh) scale(0.39);
    }

    57.1428571429% {
      transform: translateX(45vw) translateY(24vh) scale(0.54);
    }

    60.7142857143% {
      transform: translateX(-32vw) translateY(14vh) scale(0.64);
    }

    64.2857142857% {
      transform: translateX(-24vw) translateY(37vh) scale(0.81);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(28vh) scale(0.67);
    }

    71.4285714286% {
      transform: translateX(11vw) translateY(-14vh) scale(0.71);
    }

    75% {
      transform: translateX(-28vw) translateY(-6vh) scale(0.47);
    }

    78.5714285714% {
      transform: translateX(15vw) translateY(-15vh) scale(0.79);
    }

    82.1428571429% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.51);
    }

    85.7142857143% {
      transform: translateX(43vw) translateY(47vh) scale(0.52);
    }

    89.2857142857% {
      transform: translateX(35vw) translateY(-38vh) scale(0.62);
    }

    92.8571428571% {
      transform: translateX(22vw) translateY(-44vh) scale(1);
    }

    96.4285714286% {
      transform: translateX(5vw) translateY(31vh) scale(0.33);
    }

    100% {
      transform: translateX(3vw) translateY(39vh) scale(0.63);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 10s, 9679ms;
    animation-delay: 0ms, 8051ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(22vw) translateY(-45vh) scale(0.86);
    }

    4.1666666667% {
      transform: translateX(20vw) translateY(6vh) scale(0.73);
    }

    8.3333333333% {
      transform: translateX(-36vw) translateY(15vh) scale(0.72);
    }

    12.5% {
      transform: translateX(25vw) translateY(-1vh) scale(0.38);
    }

    16.6666666667% {
      transform: translateX(-19vw) translateY(-28vh) scale(0.91);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(29vh) scale(0.47);
    }

    25% {
      transform: translateX(0vw) translateY(14vh) scale(0.55);
    }

    29.1666666667% {
      transform: translateX(-41vw) translateY(-28vh) scale(0.39);
    }

    33.3333333333% {
      transform: translateX(-11vw) translateY(34vh) scale(0.37);
    }

    37.5% {
      transform: translateX(-10vw) translateY(38vh) scale(0.75);
    }

    41.6666666667% {
      transform: translateX(-3vw) translateY(7vh) scale(0.47);
    }

    45.8333333333% {
      transform: translateX(22vw) translateY(-11vh) scale(0.97);
    }

    50% {
      transform: translateX(41vw) translateY(20vh) scale(0.37);
    }

    54.1666666667% {
      transform: translateX(23vw) translateY(-14vh) scale(0.41);
    }

    58.3333333333% {
      transform: translateX(43vw) translateY(-30vh) scale(0.34);
    }

    62.5% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(-21vw) translateY(-11vh) scale(0.42);
    }

    70.8333333333% {
      transform: translateX(47vw) translateY(11vh) scale(0.29);
    }

    75% {
      transform: translateX(20vw) translateY(-27vh) scale(0.4);
    }

    79.1666666667% {
      transform: translateX(-47vw) translateY(37vh) scale(0.52);
    }

    83.3333333333% {
      transform: translateX(0vw) translateY(3vh) scale(0.44);
    }

    87.5% {
      transform: translateX(27vw) translateY(47vh) scale(0.88);
    }

    91.6666666667% {
      transform: translateX(-5vw) translateY(3vh) scale(0.82);
    }

    95.8333333333% {
      transform: translateX(16vw) translateY(10vh) scale(0.61);
    }

    100% {
      transform: translateX(5vw) translateY(32vh) scale(0.33);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 18s, 8910ms;
    animation-delay: 0ms, 3832ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(21vw) translateY(3vh) scale(0.42);
    }

    4.347826087% {
      transform: translateX(-30vw) translateY(-40vh) scale(0.96);
    }

    8.6956521739% {
      transform: translateX(-26vw) translateY(-40vh) scale(0.82);
    }

    13.0434782609% {
      transform: translateX(-19vw) translateY(-2vh) scale(0.72);
    }

    17.3913043478% {
      transform: translateX(-49vw) translateY(36vh) scale(0.31);
    }

    21.7391304348% {
      transform: translateX(4vw) translateY(-6vh) scale(0.52);
    }

    26.0869565217% {
      transform: translateX(-16vw) translateY(40vh) scale(0.96);
    }

    30.4347826087% {
      transform: translateX(15vw) translateY(15vh) scale(0.9);
    }

    34.7826086957% {
      transform: translateX(26vw) translateY(-6vh) scale(0.28);
    }

    39.1304347826% {
      transform: translateX(9vw) translateY(46vh) scale(0.35);
    }

    43.4782608696% {
      transform: translateX(-25vw) translateY(19vh) scale(0.79);
    }

    47.8260869565% {
      transform: translateX(-33vw) translateY(46vh) scale(0.74);
    }

    52.1739130435% {
      transform: translateX(-22vw) translateY(1vh) scale(0.36);
    }

    56.5217391304% {
      transform: translateX(-49vw) translateY(9vh) scale(0.52);
    }

    60.8695652174% {
      transform: translateX(-29vw) translateY(39vh) scale(0.7);
    }

    65.2173913043% {
      transform: translateX(25vw) translateY(31vh) scale(0.34);
    }

    69.5652173913% {
      transform: translateX(19vw) translateY(18vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(-25vw) translateY(-25vh) scale(0.71);
    }

    78.2608695652% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.77);
    }

    82.6086956522% {
      transform: translateX(31vw) translateY(18vh) scale(0.41);
    }

    86.9565217391% {
      transform: translateX(41vw) translateY(-27vh) scale(0.5);
    }

    91.3043478261% {
      transform: translateX(37vw) translateY(12vh) scale(0.78);
    }

    95.652173913% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.68);
    }

    100% {
      transform: translateX(31vw) translateY(30vh) scale(0.96);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 17s, 8620ms;
    animation-delay: 0ms, 742ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(30vw) translateY(29vh) scale(0.57);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(-47vh) scale(0.3);
    }

    9.5238095238% {
      transform: translateX(33vw) translateY(46vh) scale(0.27);
    }

    14.2857142857% {
      transform: translateX(46vw) translateY(7vh) scale(0.82);
    }

    19.0476190476% {
      transform: translateX(-25vw) translateY(41vh) scale(0.87);
    }

    23.8095238095% {
      transform: translateX(-46vw) translateY(22vh) scale(0.99);
    }

    28.5714285714% {
      transform: translateX(4vw) translateY(11vh) scale(0.59);
    }

    33.3333333333% {
      transform: translateX(-43vw) translateY(20vh) scale(0.97);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(-18vh) scale(0.79);
    }

    42.8571428571% {
      transform: translateX(18vw) translateY(-7vh) scale(0.4);
    }

    47.619047619% {
      transform: translateX(-10vw) translateY(-36vh) scale(1);
    }

    52.380952381% {
      transform: translateX(-4vw) translateY(28vh) scale(0.8);
    }

    57.1428571429% {
      transform: translateX(-43vw) translateY(50vh) scale(0.99);
    }

    61.9047619048% {
      transform: translateX(30vw) translateY(-40vh) scale(0.99);
    }

    66.6666666667% {
      transform: translateX(48vw) translateY(-7vh) scale(0.73);
    }

    71.4285714286% {
      transform: translateX(18vw) translateY(-19vh) scale(0.47);
    }

    76.1904761905% {
      transform: translateX(-14vw) translateY(-35vh) scale(0.71);
    }

    80.9523809524% {
      transform: translateX(34vw) translateY(17vh) scale(0.97);
    }

    85.7142857143% {
      transform: translateX(48vw) translateY(-29vh) scale(0.88);
    }

    90.4761904762% {
      transform: translateX(44vw) translateY(46vh) scale(0.99);
    }

    95.2380952381% {
      transform: translateX(22vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(-3vw) translateY(-48vh) scale(0.54);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw #FFF01F;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw #FFF01F;
    }
  }

  .projectName {
    width: 50%;
  }

  .beastDiv {}

  .beast {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .fire {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0px;
  }

  .fire4 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire4 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -130px;
    top: 0px;
  }

  .fire5 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire5 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    top: 0px;
  }

  .fire6 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire6 {
    width: 75px;
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .fire2 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire2 {
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire2 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 {
    width: 60px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }


  .flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
  }

  .flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  .flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
  }

  .flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
  }

  .flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
  }

  .flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
  }

  .flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
  }

  @keyframes flameWobble {
    50% {
      transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
  }

  @keyframes flamefly {
    0% {
      transform: translate(0) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(-20px, -100px) rotate(180deg);
      opacity: 0;
    }
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 95%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px;
    color: black;
    text-shadow: 0 0 5px #000000;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {}

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 45px;
    height: 45px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 33px;
    color: black;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  @keyframes titleFont {
    0% {
      opacity: 1;
    }

    34% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    64% {
      opacity: 0;
    }

    65% {
      opacity: 1;
    }

    84% {
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleFont2 {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    70% {
      opacity: 1
    }

    71% {
      opacity: 0
    }

    85% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .intro {
    color: black;
    font-size: 50px;
    margin-bottom: 27%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .name1 {
    position: absolute;
    animation-name: titleFont;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .name2 {
    position: absolute;
    font-family: 'Underdog', sans-serif;
    animation-name: titleFont2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .shakeWord {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .name3 {
    position: absolute;
    animation-name: titleFont3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 0.5%;
  }

  .intro2 {
    color: black;
    font-size: 23px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -25%;
  }

  .mintDiv {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 5%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid black;
    box-shadow: black -5px 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 27px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: column nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .discord:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-top: 20px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black -5px 5px;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black 5px 5px;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #fef8e2;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    box-shadow: black 0px 5px;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 174, 0);
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: black;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(37, 59, 23);
    font-size: 20px;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2 {
    background-color: #fef8e2;
    color: black;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 23px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid black;
    transition: transform .2s;
    box-shadow: black 0px 3px;
    border-radius: 10px;
    font-weight: bold;
  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background-image: linear-gradient(144deg, #a08fa1, #9895a4 50%, #9696a5);
    border: 0;
    box-shadow: black -5px 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    border-radius: 10px;
    transform: rotate(-3deg);
    border: 2px solid black;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 95%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #585858;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #2c2c2c;
    display: none;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .ser {
    color: rgb(0, 0, 0);
    font-size: 16px;
  }

  .closeNote2 {
    position: fixed;
    right: 0;
    top: 3%;
    left: 83%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote2:hover,
  .closeNote2:focus {
    color: rgb(71, 70, 70);
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    display: none;

  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.856);
    position: fixed;
    width: 80%;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #414141;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #252525;
    z-index: 100000;
    padding: 5%;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    background-color: #ffffffb0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }



  .mainImage {
    position: relative;
    bottom: 0;

  }

  .beast1 {
    width: 480px;
    bottom: 0;
  }

  .beast2 {
    width: 480px;
    bottom: 0;
    position: relative;
    animation: mainImageAnimation ease 2s infinite;

  }

  @keyframes mainImageAnimation {
    0% {
      opacity: 0;
    }

    49% {

      opacity: 0;

    }

    50% {

      opacity: 1;

    }

    100% {
      opacity: 1;
    }
  }


  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 14s, 5348ms;
    animation-delay: 0ms, 3421ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(44vh) scale(0.62);
    }

    4% {
      transform: translateX(-4vw) translateY(50vh) scale(0.77);
    }

    8% {
      transform: translateX(43vw) translateY(-35vh) scale(0.85);
    }

    12% {
      transform: translateX(6vw) translateY(-16vh) scale(0.32);
    }

    16% {
      transform: translateX(40vw) translateY(-28vh) scale(0.61);
    }

    20% {
      transform: translateX(16vw) translateY(-33vh) scale(0.62);
    }

    24% {
      transform: translateX(-13vw) translateY(12vh) scale(0.99);
    }

    28% {
      transform: translateX(-6vw) translateY(15vh) scale(0.52);
    }

    32% {
      transform: translateX(39vw) translateY(40vh) scale(0.42);
    }

    36% {
      transform: translateX(-19vw) translateY(-31vh) scale(0.72);
    }

    40% {
      transform: translateX(47vw) translateY(18vh) scale(0.74);
    }

    44% {
      transform: translateX(-24vw) translateY(1vh) scale(0.8);
    }

    48% {
      transform: translateX(36vw) translateY(-6vh) scale(0.65);
    }

    52% {
      transform: translateX(-6vw) translateY(11vh) scale(0.28);
    }

    56% {
      transform: translateX(34vw) translateY(35vh) scale(0.79);
    }

    60% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.48);
    }

    64% {
      transform: translateX(33vw) translateY(-1vh) scale(0.47);
    }

    68% {
      transform: translateX(-45vw) translateY(-41vh) scale(0.54);
    }

    72% {
      transform: translateX(-20vw) translateY(40vh) scale(0.92);
    }

    76% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.64);
    }

    80% {
      transform: translateX(-23vw) translateY(-18vh) scale(0.92);
    }

    84% {
      transform: translateX(-37vw) translateY(7vh) scale(0.98);
    }

    88% {
      transform: translateX(-31vw) translateY(-35vh) scale(0.92);
    }

    92% {
      transform: translateX(1vw) translateY(-43vh) scale(0.29);
    }

    96% {
      transform: translateX(-41vw) translateY(-20vh) scale(0.44);
    }

    100% {
      transform: translateX(-4vw) translateY(-33vh) scale(0.38);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 11s, 6289ms;
    animation-delay: 0ms, 6547ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-17vw) translateY(40vh) scale(0.32);
    }

    3.5714285714% {
      transform: translateX(9vw) translateY(34vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-21vw) translateY(-5vh) scale(0.64);
    }

    10.7142857143% {
      transform: translateX(47vw) translateY(6vh) scale(0.76);
    }

    14.2857142857% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.93);
    }

    17.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.36);
    }

    21.4285714286% {
      transform: translateX(-34vw) translateY(38vh) scale(0.46);
    }

    25% {
      transform: translateX(-23vw) translateY(-42vh) scale(0.58);
    }

    28.5714285714% {
      transform: translateX(6vw) translateY(-31vh) scale(0.93);
    }

    32.1428571429% {
      transform: translateX(-37vw) translateY(31vh) scale(0.47);
    }

    35.7142857143% {
      transform: translateX(49vw) translateY(-37vh) scale(0.58);
    }

    39.2857142857% {
      transform: translateX(13vw) translateY(-42vh) scale(0.86);
    }

    42.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.48);
    }

    46.4285714286% {
      transform: translateX(-13vw) translateY(-13vh) scale(0.85);
    }

    50% {
      transform: translateX(7vw) translateY(49vh) scale(0.39);
    }

    53.5714285714% {
      transform: translateX(44vw) translateY(-15vh) scale(0.9);
    }

    57.1428571429% {
      transform: translateX(-2vw) translateY(-20vh) scale(0.27);
    }

    60.7142857143% {
      transform: translateX(-3vw) translateY(34vh) scale(0.51);
    }

    64.2857142857% {
      transform: translateX(-40vw) translateY(-5vh) scale(0.39);
    }

    67.8571428571% {
      transform: translateX(1vw) translateY(39vh) scale(0.53);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(-12vh) scale(0.45);
    }

    75% {
      transform: translateX(-17vw) translateY(-36vh) scale(0.63);
    }

    78.5714285714% {
      transform: translateX(27vw) translateY(36vh) scale(0.92);
    }

    82.1428571429% {
      transform: translateX(18vw) translateY(-5vh) scale(0.8);
    }

    85.7142857143% {
      transform: translateX(40vw) translateY(13vh) scale(0.92);
    }

    89.2857142857% {
      transform: translateX(3vw) translateY(-4vh) scale(0.33);
    }

    92.8571428571% {
      transform: translateX(-31vw) translateY(43vh) scale(0.71);
    }

    96.4285714286% {
      transform: translateX(-42vw) translateY(-18vh) scale(0.64);
    }

    100% {
      transform: translateX(24vw) translateY(14vh) scale(0.67);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 9s, 7991ms;
    animation-delay: 0ms, 1922ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-22vw) translateY(24vh) scale(0.62);
    }

    3.5714285714% {
      transform: translateX(-44vw) translateY(37vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-47vw) translateY(46vh) scale(0.91);
    }

    10.7142857143% {
      transform: translateX(39vw) translateY(15vh) scale(0.67);
    }

    14.2857142857% {
      transform: translateX(7vw) translateY(44vh) scale(0.73);
    }

    17.8571428571% {
      transform: translateX(40vw) translateY(11vh) scale(0.56);
    }

    21.4285714286% {
      transform: translateX(23vw) translateY(-30vh) scale(0.98);
    }

    25% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.4);
    }

    28.5714285714% {
      transform: translateX(31vw) translateY(30vh) scale(0.78);
    }

    32.1428571429% {
      transform: translateX(-10vw) translateY(-22vh) scale(0.7);
    }

    35.7142857143% {
      transform: translateX(-31vw) translateY(2vh) scale(0.87);
    }

    39.2857142857% {
      transform: translateX(45vw) translateY(-3vh) scale(0.33);
    }

    42.8571428571% {
      transform: translateX(-7vw) translateY(-30vh) scale(0.88);
    }

    46.4285714286% {
      transform: translateX(8vw) translateY(22vh) scale(0.51);
    }

    50% {
      transform: translateX(31vw) translateY(25vh) scale(0.31);
    }

    53.5714285714% {
      transform: translateX(47vw) translateY(13vh) scale(0.76);
    }

    57.1428571429% {
      transform: translateX(-19vw) translateY(7vh) scale(0.36);
    }

    60.7142857143% {
      transform: translateX(11vw) translateY(6vh) scale(0.83);
    }

    64.2857142857% {
      transform: translateX(48vw) translateY(-38vh) scale(0.62);
    }

    67.8571428571% {
      transform: translateX(41vw) translateY(-18vh) scale(0.68);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(50vh) scale(0.34);
    }

    75% {
      transform: translateX(-31vw) translateY(-15vh) scale(0.51);
    }

    78.5714285714% {
      transform: translateX(-41vw) translateY(9vh) scale(0.91);
    }

    82.1428571429% {
      transform: translateX(-39vw) translateY(16vh) scale(0.45);
    }

    85.7142857143% {
      transform: translateX(-44vw) translateY(9vh) scale(0.83);
    }

    89.2857142857% {
      transform: translateX(-41vw) translateY(8vh) scale(0.66);
    }

    92.8571428571% {
      transform: translateX(-44vw) translateY(42vh) scale(0.56);
    }

    96.4285714286% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.86);
    }

    100% {
      transform: translateX(-4vw) translateY(-8vh) scale(0.42);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 17s, 5968ms;
    animation-delay: 0ms, 1513ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(45vw) translateY(13vh) scale(0.5);
    }

    5.2631578947% {
      transform: translateX(37vw) translateY(14vh) scale(0.98);
    }

    10.5263157895% {
      transform: translateX(-6vw) translateY(-16vh) scale(0.7);
    }

    15.7894736842% {
      transform: translateX(-24vw) translateY(-47vh) scale(0.29);
    }

    21.0526315789% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.63);
    }

    26.3157894737% {
      transform: translateX(-11vw) translateY(-16vh) scale(0.49);
    }

    31.5789473684% {
      transform: translateX(-11vw) translateY(7vh) scale(0.45);
    }

    36.8421052632% {
      transform: translateX(13vw) translateY(-6vh) scale(0.34);
    }

    42.1052631579% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.37);
    }

    47.3684210526% {
      transform: translateX(5vw) translateY(25vh) scale(0.53);
    }

    52.6315789474% {
      transform: translateX(27vw) translateY(14vh) scale(0.74);
    }

    57.8947368421% {
      transform: translateX(-26vw) translateY(-28vh) scale(0.64);
    }

    63.1578947368% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.46);
    }

    68.4210526316% {
      transform: translateX(-23vw) translateY(17vh) scale(0.37);
    }

    73.6842105263% {
      transform: translateX(9vw) translateY(-6vh) scale(0.69);
    }

    78.9473684211% {
      transform: translateX(-41vw) translateY(-42vh) scale(0.75);
    }

    84.2105263158% {
      transform: translateX(34vw) translateY(1vh) scale(0.86);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-26vh) scale(0.91);
    }

    94.7368421053% {
      transform: translateX(-26vw) translateY(13vh) scale(0.49);
    }

    100% {
      transform: translateX(1vw) translateY(-1vh) scale(0.76);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 13s, 9409ms;
    animation-delay: 0ms, 3495ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(34vw) translateY(18vh) scale(0.64);
    }

    4.1666666667% {
      transform: translateX(34vw) translateY(11vh) scale(0.39);
    }

    8.3333333333% {
      transform: translateX(-37vw) translateY(-43vh) scale(0.47);
    }

    12.5% {
      transform: translateX(17vw) translateY(-5vh) scale(0.49);
    }

    16.6666666667% {
      transform: translateX(30vw) translateY(7vh) scale(0.47);
    }

    20.8333333333% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.76);
    }

    25% {
      transform: translateX(-20vw) translateY(28vh) scale(0.7);
    }

    29.1666666667% {
      transform: translateX(-16vw) translateY(-5vh) scale(0.84);
    }

    33.3333333333% {
      transform: translateX(-17vw) translateY(-41vh) scale(0.42);
    }

    37.5% {
      transform: translateX(19vw) translateY(-20vh) scale(0.81);
    }

    41.6666666667% {
      transform: translateX(12vw) translateY(15vh) scale(0.75);
    }

    45.8333333333% {
      transform: translateX(30vw) translateY(-32vh) scale(0.47);
    }

    50% {
      transform: translateX(-40vw) translateY(-38vh) scale(0.59);
    }

    54.1666666667% {
      transform: translateX(4vw) translateY(44vh) scale(0.82);
    }

    58.3333333333% {
      transform: translateX(44vw) translateY(-14vh) scale(0.55);
    }

    62.5% {
      transform: translateX(-15vw) translateY(39vh) scale(0.33);
    }

    66.6666666667% {
      transform: translateX(-28vw) translateY(-30vh) scale(0.29);
    }

    70.8333333333% {
      transform: translateX(40vw) translateY(27vh) scale(0.61);
    }

    75% {
      transform: translateX(22vw) translateY(-21vh) scale(0.53);
    }

    79.1666666667% {
      transform: translateX(-49vw) translateY(21vh) scale(0.64);
    }

    83.3333333333% {
      transform: translateX(7vw) translateY(14vh) scale(0.81);
    }

    87.5% {
      transform: translateX(12vw) translateY(14vh) scale(0.8);
    }

    91.6666666667% {
      transform: translateX(-44vw) translateY(-42vh) scale(0.49);
    }

    95.8333333333% {
      transform: translateX(43vw) translateY(41vh) scale(0.6);
    }

    100% {
      transform: translateX(6vw) translateY(-29vh) scale(0.7);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 10229ms;
    animation-delay: 0ms, 7081ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(23vw) translateY(18vh) scale(0.76);
    }

    4.1666666667% {
      transform: translateX(-26vw) translateY(6vh) scale(0.41);
    }

    8.3333333333% {
      transform: translateX(-1vw) translateY(-18vh) scale(0.63);
    }

    12.5% {
      transform: translateX(22vw) translateY(43vh) scale(0.63);
    }

    16.6666666667% {
      transform: translateX(25vw) translateY(48vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(3vw) translateY(-29vh) scale(0.33);
    }

    25% {
      transform: translateX(26vw) translateY(0vh) scale(0.86);
    }

    29.1666666667% {
      transform: translateX(-33vw) translateY(5vh) scale(0.51);
    }

    33.3333333333% {
      transform: translateX(4vw) translateY(-9vh) scale(0.39);
    }

    37.5% {
      transform: translateX(26vw) translateY(12vh) scale(0.65);
    }

    41.6666666667% {
      transform: translateX(31vw) translateY(18vh) scale(0.42);
    }

    45.8333333333% {
      transform: translateX(25vw) translateY(-45vh) scale(0.9);
    }

    50% {
      transform: translateX(-11vw) translateY(49vh) scale(0.46);
    }

    54.1666666667% {
      transform: translateX(-45vw) translateY(48vh) scale(0.81);
    }

    58.3333333333% {
      transform: translateX(46vw) translateY(5vh) scale(0.57);
    }

    62.5% {
      transform: translateX(35vw) translateY(-47vh) scale(0.56);
    }

    66.6666666667% {
      transform: translateX(22vw) translateY(-44vh) scale(0.83);
    }

    70.8333333333% {
      transform: translateX(-40vw) translateY(-11vh) scale(0.73);
    }

    75% {
      transform: translateX(-19vw) translateY(13vh) scale(0.69);
    }

    79.1666666667% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.83);
    }

    83.3333333333% {
      transform: translateX(33vw) translateY(21vh) scale(0.67);
    }

    87.5% {
      transform: translateX(-36vw) translateY(27vh) scale(0.79);
    }

    91.6666666667% {
      transform: translateX(5vw) translateY(17vh) scale(0.56);
    }

    95.8333333333% {
      transform: translateX(29vw) translateY(-10vh) scale(0.51);
    }

    100% {
      transform: translateX(-1vw) translateY(-14vh) scale(0.29);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 18s, 6470ms;
    animation-delay: 0ms, 8336ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(40vw) translateY(47vh) scale(1);
    }

    3.5714285714% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.78);
    }

    7.1428571429% {
      transform: translateX(24vw) translateY(17vh) scale(0.28);
    }

    10.7142857143% {
      transform: translateX(41vw) translateY(23vh) scale(0.96);
    }

    14.2857142857% {
      transform: translateX(14vw) translateY(13vh) scale(0.95);
    }

    17.8571428571% {
      transform: translateX(-17vw) translateY(49vh) scale(0.69);
    }

    21.4285714286% {
      transform: translateX(9vw) translateY(20vh) scale(0.29);
    }

    25% {
      transform: translateX(-11vw) translateY(30vh) scale(0.96);
    }

    28.5714285714% {
      transform: translateX(32vw) translateY(-27vh) scale(0.43);
    }

    32.1428571429% {
      transform: translateX(-48vw) translateY(-7vh) scale(0.9);
    }

    35.7142857143% {
      transform: translateX(-19vw) translateY(49vh) scale(0.41);
    }

    39.2857142857% {
      transform: translateX(7vw) translateY(-36vh) scale(0.5);
    }

    42.8571428571% {
      transform: translateX(-3vw) translateY(-40vh) scale(0.78);
    }

    46.4285714286% {
      transform: translateX(47vw) translateY(6vh) scale(0.26);
    }

    50% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.3);
    }

    53.5714285714% {
      transform: translateX(-38vw) translateY(-10vh) scale(0.71);
    }

    57.1428571429% {
      transform: translateX(36vw) translateY(-4vh) scale(0.96);
    }

    60.7142857143% {
      transform: translateX(16vw) translateY(6vh) scale(0.62);
    }

    64.2857142857% {
      transform: translateX(-30vw) translateY(-39vh) scale(0.52);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(33vh) scale(0.45);
    }

    71.4285714286% {
      transform: translateX(-10vw) translateY(-28vh) scale(0.78);
    }

    75% {
      transform: translateX(48vw) translateY(16vh) scale(0.71);
    }

    78.5714285714% {
      transform: translateX(-45vw) translateY(-39vh) scale(0.63);
    }

    82.1428571429% {
      transform: translateX(22vw) translateY(-49vh) scale(0.37);
    }

    85.7142857143% {
      transform: translateX(49vw) translateY(27vh) scale(0.26);
    }

    89.2857142857% {
      transform: translateX(23vw) translateY(-44vh) scale(0.73);
    }

    92.8571428571% {
      transform: translateX(-2vw) translateY(29vh) scale(0.47);
    }

    96.4285714286% {
      transform: translateX(-2vw) translateY(-30vh) scale(0.57);
    }

    100% {
      transform: translateX(-10vw) translateY(-29vh) scale(0.96);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 15s, 6398ms;
    animation-delay: 0ms, 565ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(8vw) translateY(-41vh) scale(0.92);
    }

    4.5454545455% {
      transform: translateX(-26vw) translateY(43vh) scale(0.97);
    }

    9.0909090909% {
      transform: translateX(38vw) translateY(-44vh) scale(0.43);
    }

    13.6363636364% {
      transform: translateX(-35vw) translateY(40vh) scale(0.78);
    }

    18.1818181818% {
      transform: translateX(-7vw) translateY(-35vh) scale(0.68);
    }

    22.7272727273% {
      transform: translateX(-43vw) translateY(-29vh) scale(0.48);
    }

    27.2727272727% {
      transform: translateX(-18vw) translateY(27vh) scale(0.7);
    }

    31.8181818182% {
      transform: translateX(42vw) translateY(-45vh) scale(0.77);
    }

    36.3636363636% {
      transform: translateX(1vw) translateY(-13vh) scale(0.84);
    }

    40.9090909091% {
      transform: translateX(35vw) translateY(-26vh) scale(0.84);
    }

    45.4545454545% {
      transform: translateX(-3vw) translateY(48vh) scale(0.29);
    }

    50% {
      transform: translateX(9vw) translateY(-4vh) scale(0.99);
    }

    54.5454545455% {
      transform: translateX(-36vw) translateY(-36vh) scale(0.5);
    }

    59.0909090909% {
      transform: translateX(42vw) translateY(-44vh) scale(0.39);
    }

    63.6363636364% {
      transform: translateX(26vw) translateY(20vh) scale(0.76);
    }

    68.1818181818% {
      transform: translateX(30vw) translateY(48vh) scale(0.9);
    }

    72.7272727273% {
      transform: translateX(-9vw) translateY(35vh) scale(0.97);
    }

    77.2727272727% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.53);
    }

    81.8181818182% {
      transform: translateX(-49vw) translateY(-29vh) scale(0.26);
    }

    86.3636363636% {
      transform: translateX(-24vw) translateY(36vh) scale(0.36);
    }

    90.9090909091% {
      transform: translateX(-33vw) translateY(20vh) scale(0.52);
    }

    95.4545454545% {
      transform: translateX(14vw) translateY(10vh) scale(0.8);
    }

    100% {
      transform: translateX(14vw) translateY(-30vh) scale(0.54);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 15s, 5044ms;
    animation-delay: 0ms, 1556ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(27vw) translateY(-37vh) scale(0.9);
    }

    4.1666666667% {
      transform: translateX(12vw) translateY(-22vh) scale(0.44);
    }

    8.3333333333% {
      transform: translateX(-13vw) translateY(-28vh) scale(0.53);
    }

    12.5% {
      transform: translateX(-43vw) translateY(18vh) scale(0.39);
    }

    16.6666666667% {
      transform: translateX(34vw) translateY(31vh) scale(0.97);
    }

    20.8333333333% {
      transform: translateX(47vw) translateY(-33vh) scale(0.97);
    }

    25% {
      transform: translateX(35vw) translateY(-47vh) scale(0.42);
    }

    29.1666666667% {
      transform: translateX(-47vw) translateY(39vh) scale(0.4);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-45vh) scale(0.65);
    }

    37.5% {
      transform: translateX(43vw) translateY(-23vh) scale(0.6);
    }

    41.6666666667% {
      transform: translateX(38vw) translateY(15vh) scale(0.63);
    }

    45.8333333333% {
      transform: translateX(49vw) translateY(34vh) scale(0.82);
    }

    50% {
      transform: translateX(-30vw) translateY(-9vh) scale(0.91);
    }

    54.1666666667% {
      transform: translateX(18vw) translateY(-31vh) scale(0.73);
    }

    58.3333333333% {
      transform: translateX(32vw) translateY(-11vh) scale(0.97);
    }

    62.5% {
      transform: translateX(-12vw) translateY(31vh) scale(0.77);
    }

    66.6666666667% {
      transform: translateX(26vw) translateY(12vh) scale(0.84);
    }

    70.8333333333% {
      transform: translateX(2vw) translateY(-46vh) scale(0.38);
    }

    75% {
      transform: translateX(46vw) translateY(35vh) scale(0.79);
    }

    79.1666666667% {
      transform: translateX(50vw) translateY(26vh) scale(0.46);
    }

    83.3333333333% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.48);
    }

    87.5% {
      transform: translateX(6vw) translateY(-18vh) scale(0.78);
    }

    91.6666666667% {
      transform: translateX(33vw) translateY(-31vh) scale(0.75);
    }

    95.8333333333% {
      transform: translateX(27vw) translateY(-13vh) scale(0.52);
    }

    100% {
      transform: translateX(4vw) translateY(11vh) scale(0.86);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 5550ms;
    animation-delay: 0ms, 4125ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-26vw) translateY(-38vh) scale(0.49);
    }

    5.5555555556% {
      transform: translateX(21vw) translateY(7vh) scale(0.8);
    }

    11.1111111111% {
      transform: translateX(-43vw) translateY(-2vh) scale(0.75);
    }

    16.6666666667% {
      transform: translateX(29vw) translateY(-17vh) scale(1);
    }

    22.2222222222% {
      transform: translateX(-43vw) translateY(13vh) scale(0.88);
    }

    27.7777777778% {
      transform: translateX(33vw) translateY(-48vh) scale(0.86);
    }

    33.3333333333% {
      transform: translateX(17vw) translateY(-23vh) scale(0.95);
    }

    38.8888888889% {
      transform: translateX(-7vw) translateY(39vh) scale(0.4);
    }

    44.4444444444% {
      transform: translateX(-44vw) translateY(-35vh) scale(0.39);
    }

    50% {
      transform: translateX(-23vw) translateY(-10vh) scale(0.54);
    }

    55.5555555556% {
      transform: translateX(8vw) translateY(39vh) scale(0.63);
    }

    61.1111111111% {
      transform: translateX(-3vw) translateY(28vh) scale(0.46);
    }

    66.6666666667% {
      transform: translateX(19vw) translateY(-35vh) scale(0.55);
    }

    72.2222222222% {
      transform: translateX(-32vw) translateY(1vh) scale(0.87);
    }

    77.7777777778% {
      transform: translateX(-18vw) translateY(11vh) scale(0.62);
    }

    83.3333333333% {
      transform: translateX(-24vw) translateY(-31vh) scale(0.42);
    }

    88.8888888889% {
      transform: translateX(5vw) translateY(34vh) scale(0.5);
    }

    94.4444444444% {
      transform: translateX(-11vw) translateY(32vh) scale(0.36);
    }

    100% {
      transform: translateX(5vw) translateY(-8vh) scale(0.99);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 16s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 16s, 10297ms;
    animation-delay: 0ms, 4707ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-36vw) translateY(-2vh) scale(0.37);
    }

    4.7619047619% {
      transform: translateX(-27vw) translateY(30vh) scale(0.38);
    }

    9.5238095238% {
      transform: translateX(45vw) translateY(-23vh) scale(0.33);
    }

    14.2857142857% {
      transform: translateX(25vw) translateY(9vh) scale(0.7);
    }

    19.0476190476% {
      transform: translateX(24vw) translateY(-13vh) scale(0.48);
    }

    23.8095238095% {
      transform: translateX(43vw) translateY(-5vh) scale(0.68);
    }

    28.5714285714% {
      transform: translateX(41vw) translateY(2vh) scale(0.83);
    }

    33.3333333333% {
      transform: translateX(-46vw) translateY(-13vh) scale(0.7);
    }

    38.0952380952% {
      transform: translateX(-39vw) translateY(-25vh) scale(0.85);
    }

    42.8571428571% {
      transform: translateX(-27vw) translateY(23vh) scale(0.33);
    }

    47.619047619% {
      transform: translateX(-13vw) translateY(45vh) scale(0.3);
    }

    52.380952381% {
      transform: translateX(10vw) translateY(-28vh) scale(0.55);
    }

    57.1428571429% {
      transform: translateX(-30vw) translateY(-16vh) scale(0.26);
    }

    61.9047619048% {
      transform: translateX(40vw) translateY(30vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(45vw) translateY(37vh) scale(0.79);
    }

    71.4285714286% {
      transform: translateX(-13vw) translateY(-24vh) scale(0.62);
    }

    76.1904761905% {
      transform: translateX(33vw) translateY(-12vh) scale(0.94);
    }

    80.9523809524% {
      transform: translateX(10vw) translateY(-49vh) scale(0.3);
    }

    85.7142857143% {
      transform: translateX(-15vw) translateY(48vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(-42vw) translateY(40vh) scale(0.54);
    }

    95.2380952381% {
      transform: translateX(-22vw) translateY(0vh) scale(0.63);
    }

    100% {
      transform: translateX(32vw) translateY(14vh) scale(0.79);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 9s, 8018ms;
    animation-delay: 0ms, 4673ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(-30vw) translateY(47vh) scale(0.57);
    }

    3.5714285714% {
      transform: translateX(-19vw) translateY(19vh) scale(0.52);
    }

    7.1428571429% {
      transform: translateX(-14vw) translateY(48vh) scale(0.9);
    }

    10.7142857143% {
      transform: translateX(-44vw) translateY(13vh) scale(0.38);
    }

    14.2857142857% {
      transform: translateX(-10vw) translateY(43vh) scale(0.49);
    }

    17.8571428571% {
      transform: translateX(39vw) translateY(49vh) scale(0.79);
    }

    21.4285714286% {
      transform: translateX(50vw) translateY(-17vh) scale(0.4);
    }

    25% {
      transform: translateX(-20vw) translateY(22vh) scale(0.48);
    }

    28.5714285714% {
      transform: translateX(-27vw) translateY(32vh) scale(0.92);
    }

    32.1428571429% {
      transform: translateX(11vw) translateY(-7vh) scale(0.99);
    }

    35.7142857143% {
      transform: translateX(-27vw) translateY(46vh) scale(0.34);
    }

    39.2857142857% {
      transform: translateX(-24vw) translateY(3vh) scale(0.36);
    }

    42.8571428571% {
      transform: translateX(48vw) translateY(-30vh) scale(0.33);
    }

    46.4285714286% {
      transform: translateX(-44vw) translateY(-30vh) scale(0.5);
    }

    50% {
      transform: translateX(44vw) translateY(-2vh) scale(0.57);
    }

    53.5714285714% {
      transform: translateX(-47vw) translateY(-18vh) scale(0.39);
    }

    57.1428571429% {
      transform: translateX(45vw) translateY(24vh) scale(0.54);
    }

    60.7142857143% {
      transform: translateX(-32vw) translateY(14vh) scale(0.64);
    }

    64.2857142857% {
      transform: translateX(-24vw) translateY(37vh) scale(0.81);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(28vh) scale(0.67);
    }

    71.4285714286% {
      transform: translateX(11vw) translateY(-14vh) scale(0.71);
    }

    75% {
      transform: translateX(-28vw) translateY(-6vh) scale(0.47);
    }

    78.5714285714% {
      transform: translateX(15vw) translateY(-15vh) scale(0.79);
    }

    82.1428571429% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.51);
    }

    85.7142857143% {
      transform: translateX(43vw) translateY(47vh) scale(0.52);
    }

    89.2857142857% {
      transform: translateX(35vw) translateY(-38vh) scale(0.62);
    }

    92.8571428571% {
      transform: translateX(22vw) translateY(-44vh) scale(1);
    }

    96.4285714286% {
      transform: translateX(5vw) translateY(31vh) scale(0.33);
    }

    100% {
      transform: translateX(3vw) translateY(39vh) scale(0.63);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 10s, 9679ms;
    animation-delay: 0ms, 8051ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(22vw) translateY(-45vh) scale(0.86);
    }

    4.1666666667% {
      transform: translateX(20vw) translateY(6vh) scale(0.73);
    }

    8.3333333333% {
      transform: translateX(-36vw) translateY(15vh) scale(0.72);
    }

    12.5% {
      transform: translateX(25vw) translateY(-1vh) scale(0.38);
    }

    16.6666666667% {
      transform: translateX(-19vw) translateY(-28vh) scale(0.91);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(29vh) scale(0.47);
    }

    25% {
      transform: translateX(0vw) translateY(14vh) scale(0.55);
    }

    29.1666666667% {
      transform: translateX(-41vw) translateY(-28vh) scale(0.39);
    }

    33.3333333333% {
      transform: translateX(-11vw) translateY(34vh) scale(0.37);
    }

    37.5% {
      transform: translateX(-10vw) translateY(38vh) scale(0.75);
    }

    41.6666666667% {
      transform: translateX(-3vw) translateY(7vh) scale(0.47);
    }

    45.8333333333% {
      transform: translateX(22vw) translateY(-11vh) scale(0.97);
    }

    50% {
      transform: translateX(41vw) translateY(20vh) scale(0.37);
    }

    54.1666666667% {
      transform: translateX(23vw) translateY(-14vh) scale(0.41);
    }

    58.3333333333% {
      transform: translateX(43vw) translateY(-30vh) scale(0.34);
    }

    62.5% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(-21vw) translateY(-11vh) scale(0.42);
    }

    70.8333333333% {
      transform: translateX(47vw) translateY(11vh) scale(0.29);
    }

    75% {
      transform: translateX(20vw) translateY(-27vh) scale(0.4);
    }

    79.1666666667% {
      transform: translateX(-47vw) translateY(37vh) scale(0.52);
    }

    83.3333333333% {
      transform: translateX(0vw) translateY(3vh) scale(0.44);
    }

    87.5% {
      transform: translateX(27vw) translateY(47vh) scale(0.88);
    }

    91.6666666667% {
      transform: translateX(-5vw) translateY(3vh) scale(0.82);
    }

    95.8333333333% {
      transform: translateX(16vw) translateY(10vh) scale(0.61);
    }

    100% {
      transform: translateX(5vw) translateY(32vh) scale(0.33);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 18s, 8910ms;
    animation-delay: 0ms, 3832ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(21vw) translateY(3vh) scale(0.42);
    }

    4.347826087% {
      transform: translateX(-30vw) translateY(-40vh) scale(0.96);
    }

    8.6956521739% {
      transform: translateX(-26vw) translateY(-40vh) scale(0.82);
    }

    13.0434782609% {
      transform: translateX(-19vw) translateY(-2vh) scale(0.72);
    }

    17.3913043478% {
      transform: translateX(-49vw) translateY(36vh) scale(0.31);
    }

    21.7391304348% {
      transform: translateX(4vw) translateY(-6vh) scale(0.52);
    }

    26.0869565217% {
      transform: translateX(-16vw) translateY(40vh) scale(0.96);
    }

    30.4347826087% {
      transform: translateX(15vw) translateY(15vh) scale(0.9);
    }

    34.7826086957% {
      transform: translateX(26vw) translateY(-6vh) scale(0.28);
    }

    39.1304347826% {
      transform: translateX(9vw) translateY(46vh) scale(0.35);
    }

    43.4782608696% {
      transform: translateX(-25vw) translateY(19vh) scale(0.79);
    }

    47.8260869565% {
      transform: translateX(-33vw) translateY(46vh) scale(0.74);
    }

    52.1739130435% {
      transform: translateX(-22vw) translateY(1vh) scale(0.36);
    }

    56.5217391304% {
      transform: translateX(-49vw) translateY(9vh) scale(0.52);
    }

    60.8695652174% {
      transform: translateX(-29vw) translateY(39vh) scale(0.7);
    }

    65.2173913043% {
      transform: translateX(25vw) translateY(31vh) scale(0.34);
    }

    69.5652173913% {
      transform: translateX(19vw) translateY(18vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(-25vw) translateY(-25vh) scale(0.71);
    }

    78.2608695652% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.77);
    }

    82.6086956522% {
      transform: translateX(31vw) translateY(18vh) scale(0.41);
    }

    86.9565217391% {
      transform: translateX(41vw) translateY(-27vh) scale(0.5);
    }

    91.3043478261% {
      transform: translateX(37vw) translateY(12vh) scale(0.78);
    }

    95.652173913% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.68);
    }

    100% {
      transform: translateX(31vw) translateY(30vh) scale(0.96);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 17s, 8620ms;
    animation-delay: 0ms, 742ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(30vw) translateY(29vh) scale(0.57);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(-47vh) scale(0.3);
    }

    9.5238095238% {
      transform: translateX(33vw) translateY(46vh) scale(0.27);
    }

    14.2857142857% {
      transform: translateX(46vw) translateY(7vh) scale(0.82);
    }

    19.0476190476% {
      transform: translateX(-25vw) translateY(41vh) scale(0.87);
    }

    23.8095238095% {
      transform: translateX(-46vw) translateY(22vh) scale(0.99);
    }

    28.5714285714% {
      transform: translateX(4vw) translateY(11vh) scale(0.59);
    }

    33.3333333333% {
      transform: translateX(-43vw) translateY(20vh) scale(0.97);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(-18vh) scale(0.79);
    }

    42.8571428571% {
      transform: translateX(18vw) translateY(-7vh) scale(0.4);
    }

    47.619047619% {
      transform: translateX(-10vw) translateY(-36vh) scale(1);
    }

    52.380952381% {
      transform: translateX(-4vw) translateY(28vh) scale(0.8);
    }

    57.1428571429% {
      transform: translateX(-43vw) translateY(50vh) scale(0.99);
    }

    61.9047619048% {
      transform: translateX(30vw) translateY(-40vh) scale(0.99);
    }

    66.6666666667% {
      transform: translateX(48vw) translateY(-7vh) scale(0.73);
    }

    71.4285714286% {
      transform: translateX(18vw) translateY(-19vh) scale(0.47);
    }

    76.1904761905% {
      transform: translateX(-14vw) translateY(-35vh) scale(0.71);
    }

    80.9523809524% {
      transform: translateX(34vw) translateY(17vh) scale(0.97);
    }

    85.7142857143% {
      transform: translateX(48vw) translateY(-29vh) scale(0.88);
    }

    90.4761904762% {
      transform: translateX(44vw) translateY(46vh) scale(0.99);
    }

    95.2380952381% {
      transform: translateX(22vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(-3vw) translateY(-48vh) scale(0.54);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw #FFF01F;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw #FFF01F;
    }
  }

  .projectName {
    width: 50%;
  }

  .beastDiv {}

  .beast {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .fire {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0px;
  }

  .fire4 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire4 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -130px;
    top: 0px;
  }

  .fire5 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire5 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    top: 0px;
  }

  .fire6 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire6 {
    width: 75px;
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .fire2 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire2 {
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire2 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 {
    width: 60px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }


  .flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
  }

  .flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  .flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
  }

  .flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
  }

  .flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
  }

  .flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
  }

  .flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
  }

  @keyframes flameWobble {
    50% {
      transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
  }

  @keyframes flamefly {
    0% {
      transform: translate(0) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(-20px, -100px) rotate(180deg);
      opacity: 0;
    }
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 20px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 60px;
    color: black;
    text-shadow: 0 0 5px #000000;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {}

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 35px;
    color: black;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  @keyframes titleFont {
    0% {
      opacity: 1;
    }

    34% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    64% {
      opacity: 0;
    }

    65% {
      opacity: 1;
    }

    84% {
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleFont2 {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    70% {
      opacity: 1
    }

    71% {
      opacity: 0
    }

    85% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .intro {
    color: black;
    font-size: 50px;
    margin-bottom: 23%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .name1 {
    position: absolute;
    animation-name: titleFont;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .name2 {
    position: absolute;
    font-family: 'Underdog', sans-serif;
    animation-name: titleFont2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .shakeWord {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .name3 {
    position: absolute;
    animation-name: titleFont3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 0.5%;
  }

  .intro2 {
    color: black;
    font-size: 23px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -25%;
  }

  .mintDiv {
    width: 90%;
    margin-top: 5%;
    margin-bottom: 5%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid black;
    box-shadow: black -5px 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 27px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: column nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .discord:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-top: 20px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black -5px 5px;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black 5px 5px;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #fef8e2;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    box-shadow: black 0px 5px;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 174, 0);
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: black;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(37, 59, 23);
    font-size: 20px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2 {
    background-color: #fef8e2;
    color: black;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid black;
    transition: transform .2s;
    box-shadow: black 0px 3px;
    border-radius: 10px;
    font-weight: bold;
  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background-image: linear-gradient(144deg, #a08fa1, #9895a4 50%, #9696a5);
    border: 0;
    box-shadow: black -5px 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    border-radius: 10px;
    transform: rotate(-3deg);
    border: 2px solid black;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #585858;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #2c2c2c;
    display: none;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .ser {
    color: rgb(0, 0, 0);
    font-size: 20px;
  }

  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }
  .closeNote2 {
    position: fixed;
    right: 0;
    top: 5%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote2:hover,
  .closeNote2:focus {
    color: rgb(71, 70, 70);
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    display: none;

  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.856);
    position: fixed;
    width: 80%;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #414141;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #252525;
    z-index: 100000;
    padding: 5%;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    background-color: #ffffffb0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }



  .mainImage {
    position: relative;
    bottom: 0;

  }

  .beast1 {
    width: 480px;
    bottom: 0;
  }

  .beast2 {
    width: 480px;
    bottom: 0;
    position: relative;
    animation: mainImageAnimation ease 2s infinite;

  }

  @keyframes mainImageAnimation {
    0% {
      opacity: 0;
    }

    49% {

      opacity: 0;

    }

    50% {

      opacity: 1;

    }

    100% {
      opacity: 1;
    }
  }


  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 14s, 5348ms;
    animation-delay: 0ms, 3421ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(44vh) scale(0.62);
    }

    4% {
      transform: translateX(-4vw) translateY(50vh) scale(0.77);
    }

    8% {
      transform: translateX(43vw) translateY(-35vh) scale(0.85);
    }

    12% {
      transform: translateX(6vw) translateY(-16vh) scale(0.32);
    }

    16% {
      transform: translateX(40vw) translateY(-28vh) scale(0.61);
    }

    20% {
      transform: translateX(16vw) translateY(-33vh) scale(0.62);
    }

    24% {
      transform: translateX(-13vw) translateY(12vh) scale(0.99);
    }

    28% {
      transform: translateX(-6vw) translateY(15vh) scale(0.52);
    }

    32% {
      transform: translateX(39vw) translateY(40vh) scale(0.42);
    }

    36% {
      transform: translateX(-19vw) translateY(-31vh) scale(0.72);
    }

    40% {
      transform: translateX(47vw) translateY(18vh) scale(0.74);
    }

    44% {
      transform: translateX(-24vw) translateY(1vh) scale(0.8);
    }

    48% {
      transform: translateX(36vw) translateY(-6vh) scale(0.65);
    }

    52% {
      transform: translateX(-6vw) translateY(11vh) scale(0.28);
    }

    56% {
      transform: translateX(34vw) translateY(35vh) scale(0.79);
    }

    60% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.48);
    }

    64% {
      transform: translateX(33vw) translateY(-1vh) scale(0.47);
    }

    68% {
      transform: translateX(-45vw) translateY(-41vh) scale(0.54);
    }

    72% {
      transform: translateX(-20vw) translateY(40vh) scale(0.92);
    }

    76% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.64);
    }

    80% {
      transform: translateX(-23vw) translateY(-18vh) scale(0.92);
    }

    84% {
      transform: translateX(-37vw) translateY(7vh) scale(0.98);
    }

    88% {
      transform: translateX(-31vw) translateY(-35vh) scale(0.92);
    }

    92% {
      transform: translateX(1vw) translateY(-43vh) scale(0.29);
    }

    96% {
      transform: translateX(-41vw) translateY(-20vh) scale(0.44);
    }

    100% {
      transform: translateX(-4vw) translateY(-33vh) scale(0.38);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 11s, 6289ms;
    animation-delay: 0ms, 6547ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-17vw) translateY(40vh) scale(0.32);
    }

    3.5714285714% {
      transform: translateX(9vw) translateY(34vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-21vw) translateY(-5vh) scale(0.64);
    }

    10.7142857143% {
      transform: translateX(47vw) translateY(6vh) scale(0.76);
    }

    14.2857142857% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.93);
    }

    17.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.36);
    }

    21.4285714286% {
      transform: translateX(-34vw) translateY(38vh) scale(0.46);
    }

    25% {
      transform: translateX(-23vw) translateY(-42vh) scale(0.58);
    }

    28.5714285714% {
      transform: translateX(6vw) translateY(-31vh) scale(0.93);
    }

    32.1428571429% {
      transform: translateX(-37vw) translateY(31vh) scale(0.47);
    }

    35.7142857143% {
      transform: translateX(49vw) translateY(-37vh) scale(0.58);
    }

    39.2857142857% {
      transform: translateX(13vw) translateY(-42vh) scale(0.86);
    }

    42.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.48);
    }

    46.4285714286% {
      transform: translateX(-13vw) translateY(-13vh) scale(0.85);
    }

    50% {
      transform: translateX(7vw) translateY(49vh) scale(0.39);
    }

    53.5714285714% {
      transform: translateX(44vw) translateY(-15vh) scale(0.9);
    }

    57.1428571429% {
      transform: translateX(-2vw) translateY(-20vh) scale(0.27);
    }

    60.7142857143% {
      transform: translateX(-3vw) translateY(34vh) scale(0.51);
    }

    64.2857142857% {
      transform: translateX(-40vw) translateY(-5vh) scale(0.39);
    }

    67.8571428571% {
      transform: translateX(1vw) translateY(39vh) scale(0.53);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(-12vh) scale(0.45);
    }

    75% {
      transform: translateX(-17vw) translateY(-36vh) scale(0.63);
    }

    78.5714285714% {
      transform: translateX(27vw) translateY(36vh) scale(0.92);
    }

    82.1428571429% {
      transform: translateX(18vw) translateY(-5vh) scale(0.8);
    }

    85.7142857143% {
      transform: translateX(40vw) translateY(13vh) scale(0.92);
    }

    89.2857142857% {
      transform: translateX(3vw) translateY(-4vh) scale(0.33);
    }

    92.8571428571% {
      transform: translateX(-31vw) translateY(43vh) scale(0.71);
    }

    96.4285714286% {
      transform: translateX(-42vw) translateY(-18vh) scale(0.64);
    }

    100% {
      transform: translateX(24vw) translateY(14vh) scale(0.67);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 9s, 7991ms;
    animation-delay: 0ms, 1922ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-22vw) translateY(24vh) scale(0.62);
    }

    3.5714285714% {
      transform: translateX(-44vw) translateY(37vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-47vw) translateY(46vh) scale(0.91);
    }

    10.7142857143% {
      transform: translateX(39vw) translateY(15vh) scale(0.67);
    }

    14.2857142857% {
      transform: translateX(7vw) translateY(44vh) scale(0.73);
    }

    17.8571428571% {
      transform: translateX(40vw) translateY(11vh) scale(0.56);
    }

    21.4285714286% {
      transform: translateX(23vw) translateY(-30vh) scale(0.98);
    }

    25% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.4);
    }

    28.5714285714% {
      transform: translateX(31vw) translateY(30vh) scale(0.78);
    }

    32.1428571429% {
      transform: translateX(-10vw) translateY(-22vh) scale(0.7);
    }

    35.7142857143% {
      transform: translateX(-31vw) translateY(2vh) scale(0.87);
    }

    39.2857142857% {
      transform: translateX(45vw) translateY(-3vh) scale(0.33);
    }

    42.8571428571% {
      transform: translateX(-7vw) translateY(-30vh) scale(0.88);
    }

    46.4285714286% {
      transform: translateX(8vw) translateY(22vh) scale(0.51);
    }

    50% {
      transform: translateX(31vw) translateY(25vh) scale(0.31);
    }

    53.5714285714% {
      transform: translateX(47vw) translateY(13vh) scale(0.76);
    }

    57.1428571429% {
      transform: translateX(-19vw) translateY(7vh) scale(0.36);
    }

    60.7142857143% {
      transform: translateX(11vw) translateY(6vh) scale(0.83);
    }

    64.2857142857% {
      transform: translateX(48vw) translateY(-38vh) scale(0.62);
    }

    67.8571428571% {
      transform: translateX(41vw) translateY(-18vh) scale(0.68);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(50vh) scale(0.34);
    }

    75% {
      transform: translateX(-31vw) translateY(-15vh) scale(0.51);
    }

    78.5714285714% {
      transform: translateX(-41vw) translateY(9vh) scale(0.91);
    }

    82.1428571429% {
      transform: translateX(-39vw) translateY(16vh) scale(0.45);
    }

    85.7142857143% {
      transform: translateX(-44vw) translateY(9vh) scale(0.83);
    }

    89.2857142857% {
      transform: translateX(-41vw) translateY(8vh) scale(0.66);
    }

    92.8571428571% {
      transform: translateX(-44vw) translateY(42vh) scale(0.56);
    }

    96.4285714286% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.86);
    }

    100% {
      transform: translateX(-4vw) translateY(-8vh) scale(0.42);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 17s, 5968ms;
    animation-delay: 0ms, 1513ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(45vw) translateY(13vh) scale(0.5);
    }

    5.2631578947% {
      transform: translateX(37vw) translateY(14vh) scale(0.98);
    }

    10.5263157895% {
      transform: translateX(-6vw) translateY(-16vh) scale(0.7);
    }

    15.7894736842% {
      transform: translateX(-24vw) translateY(-47vh) scale(0.29);
    }

    21.0526315789% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.63);
    }

    26.3157894737% {
      transform: translateX(-11vw) translateY(-16vh) scale(0.49);
    }

    31.5789473684% {
      transform: translateX(-11vw) translateY(7vh) scale(0.45);
    }

    36.8421052632% {
      transform: translateX(13vw) translateY(-6vh) scale(0.34);
    }

    42.1052631579% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.37);
    }

    47.3684210526% {
      transform: translateX(5vw) translateY(25vh) scale(0.53);
    }

    52.6315789474% {
      transform: translateX(27vw) translateY(14vh) scale(0.74);
    }

    57.8947368421% {
      transform: translateX(-26vw) translateY(-28vh) scale(0.64);
    }

    63.1578947368% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.46);
    }

    68.4210526316% {
      transform: translateX(-23vw) translateY(17vh) scale(0.37);
    }

    73.6842105263% {
      transform: translateX(9vw) translateY(-6vh) scale(0.69);
    }

    78.9473684211% {
      transform: translateX(-41vw) translateY(-42vh) scale(0.75);
    }

    84.2105263158% {
      transform: translateX(34vw) translateY(1vh) scale(0.86);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-26vh) scale(0.91);
    }

    94.7368421053% {
      transform: translateX(-26vw) translateY(13vh) scale(0.49);
    }

    100% {
      transform: translateX(1vw) translateY(-1vh) scale(0.76);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 13s, 9409ms;
    animation-delay: 0ms, 3495ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(34vw) translateY(18vh) scale(0.64);
    }

    4.1666666667% {
      transform: translateX(34vw) translateY(11vh) scale(0.39);
    }

    8.3333333333% {
      transform: translateX(-37vw) translateY(-43vh) scale(0.47);
    }

    12.5% {
      transform: translateX(17vw) translateY(-5vh) scale(0.49);
    }

    16.6666666667% {
      transform: translateX(30vw) translateY(7vh) scale(0.47);
    }

    20.8333333333% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.76);
    }

    25% {
      transform: translateX(-20vw) translateY(28vh) scale(0.7);
    }

    29.1666666667% {
      transform: translateX(-16vw) translateY(-5vh) scale(0.84);
    }

    33.3333333333% {
      transform: translateX(-17vw) translateY(-41vh) scale(0.42);
    }

    37.5% {
      transform: translateX(19vw) translateY(-20vh) scale(0.81);
    }

    41.6666666667% {
      transform: translateX(12vw) translateY(15vh) scale(0.75);
    }

    45.8333333333% {
      transform: translateX(30vw) translateY(-32vh) scale(0.47);
    }

    50% {
      transform: translateX(-40vw) translateY(-38vh) scale(0.59);
    }

    54.1666666667% {
      transform: translateX(4vw) translateY(44vh) scale(0.82);
    }

    58.3333333333% {
      transform: translateX(44vw) translateY(-14vh) scale(0.55);
    }

    62.5% {
      transform: translateX(-15vw) translateY(39vh) scale(0.33);
    }

    66.6666666667% {
      transform: translateX(-28vw) translateY(-30vh) scale(0.29);
    }

    70.8333333333% {
      transform: translateX(40vw) translateY(27vh) scale(0.61);
    }

    75% {
      transform: translateX(22vw) translateY(-21vh) scale(0.53);
    }

    79.1666666667% {
      transform: translateX(-49vw) translateY(21vh) scale(0.64);
    }

    83.3333333333% {
      transform: translateX(7vw) translateY(14vh) scale(0.81);
    }

    87.5% {
      transform: translateX(12vw) translateY(14vh) scale(0.8);
    }

    91.6666666667% {
      transform: translateX(-44vw) translateY(-42vh) scale(0.49);
    }

    95.8333333333% {
      transform: translateX(43vw) translateY(41vh) scale(0.6);
    }

    100% {
      transform: translateX(6vw) translateY(-29vh) scale(0.7);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 10229ms;
    animation-delay: 0ms, 7081ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(23vw) translateY(18vh) scale(0.76);
    }

    4.1666666667% {
      transform: translateX(-26vw) translateY(6vh) scale(0.41);
    }

    8.3333333333% {
      transform: translateX(-1vw) translateY(-18vh) scale(0.63);
    }

    12.5% {
      transform: translateX(22vw) translateY(43vh) scale(0.63);
    }

    16.6666666667% {
      transform: translateX(25vw) translateY(48vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(3vw) translateY(-29vh) scale(0.33);
    }

    25% {
      transform: translateX(26vw) translateY(0vh) scale(0.86);
    }

    29.1666666667% {
      transform: translateX(-33vw) translateY(5vh) scale(0.51);
    }

    33.3333333333% {
      transform: translateX(4vw) translateY(-9vh) scale(0.39);
    }

    37.5% {
      transform: translateX(26vw) translateY(12vh) scale(0.65);
    }

    41.6666666667% {
      transform: translateX(31vw) translateY(18vh) scale(0.42);
    }

    45.8333333333% {
      transform: translateX(25vw) translateY(-45vh) scale(0.9);
    }

    50% {
      transform: translateX(-11vw) translateY(49vh) scale(0.46);
    }

    54.1666666667% {
      transform: translateX(-45vw) translateY(48vh) scale(0.81);
    }

    58.3333333333% {
      transform: translateX(46vw) translateY(5vh) scale(0.57);
    }

    62.5% {
      transform: translateX(35vw) translateY(-47vh) scale(0.56);
    }

    66.6666666667% {
      transform: translateX(22vw) translateY(-44vh) scale(0.83);
    }

    70.8333333333% {
      transform: translateX(-40vw) translateY(-11vh) scale(0.73);
    }

    75% {
      transform: translateX(-19vw) translateY(13vh) scale(0.69);
    }

    79.1666666667% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.83);
    }

    83.3333333333% {
      transform: translateX(33vw) translateY(21vh) scale(0.67);
    }

    87.5% {
      transform: translateX(-36vw) translateY(27vh) scale(0.79);
    }

    91.6666666667% {
      transform: translateX(5vw) translateY(17vh) scale(0.56);
    }

    95.8333333333% {
      transform: translateX(29vw) translateY(-10vh) scale(0.51);
    }

    100% {
      transform: translateX(-1vw) translateY(-14vh) scale(0.29);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 18s, 6470ms;
    animation-delay: 0ms, 8336ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(40vw) translateY(47vh) scale(1);
    }

    3.5714285714% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.78);
    }

    7.1428571429% {
      transform: translateX(24vw) translateY(17vh) scale(0.28);
    }

    10.7142857143% {
      transform: translateX(41vw) translateY(23vh) scale(0.96);
    }

    14.2857142857% {
      transform: translateX(14vw) translateY(13vh) scale(0.95);
    }

    17.8571428571% {
      transform: translateX(-17vw) translateY(49vh) scale(0.69);
    }

    21.4285714286% {
      transform: translateX(9vw) translateY(20vh) scale(0.29);
    }

    25% {
      transform: translateX(-11vw) translateY(30vh) scale(0.96);
    }

    28.5714285714% {
      transform: translateX(32vw) translateY(-27vh) scale(0.43);
    }

    32.1428571429% {
      transform: translateX(-48vw) translateY(-7vh) scale(0.9);
    }

    35.7142857143% {
      transform: translateX(-19vw) translateY(49vh) scale(0.41);
    }

    39.2857142857% {
      transform: translateX(7vw) translateY(-36vh) scale(0.5);
    }

    42.8571428571% {
      transform: translateX(-3vw) translateY(-40vh) scale(0.78);
    }

    46.4285714286% {
      transform: translateX(47vw) translateY(6vh) scale(0.26);
    }

    50% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.3);
    }

    53.5714285714% {
      transform: translateX(-38vw) translateY(-10vh) scale(0.71);
    }

    57.1428571429% {
      transform: translateX(36vw) translateY(-4vh) scale(0.96);
    }

    60.7142857143% {
      transform: translateX(16vw) translateY(6vh) scale(0.62);
    }

    64.2857142857% {
      transform: translateX(-30vw) translateY(-39vh) scale(0.52);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(33vh) scale(0.45);
    }

    71.4285714286% {
      transform: translateX(-10vw) translateY(-28vh) scale(0.78);
    }

    75% {
      transform: translateX(48vw) translateY(16vh) scale(0.71);
    }

    78.5714285714% {
      transform: translateX(-45vw) translateY(-39vh) scale(0.63);
    }

    82.1428571429% {
      transform: translateX(22vw) translateY(-49vh) scale(0.37);
    }

    85.7142857143% {
      transform: translateX(49vw) translateY(27vh) scale(0.26);
    }

    89.2857142857% {
      transform: translateX(23vw) translateY(-44vh) scale(0.73);
    }

    92.8571428571% {
      transform: translateX(-2vw) translateY(29vh) scale(0.47);
    }

    96.4285714286% {
      transform: translateX(-2vw) translateY(-30vh) scale(0.57);
    }

    100% {
      transform: translateX(-10vw) translateY(-29vh) scale(0.96);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 15s, 6398ms;
    animation-delay: 0ms, 565ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(8vw) translateY(-41vh) scale(0.92);
    }

    4.5454545455% {
      transform: translateX(-26vw) translateY(43vh) scale(0.97);
    }

    9.0909090909% {
      transform: translateX(38vw) translateY(-44vh) scale(0.43);
    }

    13.6363636364% {
      transform: translateX(-35vw) translateY(40vh) scale(0.78);
    }

    18.1818181818% {
      transform: translateX(-7vw) translateY(-35vh) scale(0.68);
    }

    22.7272727273% {
      transform: translateX(-43vw) translateY(-29vh) scale(0.48);
    }

    27.2727272727% {
      transform: translateX(-18vw) translateY(27vh) scale(0.7);
    }

    31.8181818182% {
      transform: translateX(42vw) translateY(-45vh) scale(0.77);
    }

    36.3636363636% {
      transform: translateX(1vw) translateY(-13vh) scale(0.84);
    }

    40.9090909091% {
      transform: translateX(35vw) translateY(-26vh) scale(0.84);
    }

    45.4545454545% {
      transform: translateX(-3vw) translateY(48vh) scale(0.29);
    }

    50% {
      transform: translateX(9vw) translateY(-4vh) scale(0.99);
    }

    54.5454545455% {
      transform: translateX(-36vw) translateY(-36vh) scale(0.5);
    }

    59.0909090909% {
      transform: translateX(42vw) translateY(-44vh) scale(0.39);
    }

    63.6363636364% {
      transform: translateX(26vw) translateY(20vh) scale(0.76);
    }

    68.1818181818% {
      transform: translateX(30vw) translateY(48vh) scale(0.9);
    }

    72.7272727273% {
      transform: translateX(-9vw) translateY(35vh) scale(0.97);
    }

    77.2727272727% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.53);
    }

    81.8181818182% {
      transform: translateX(-49vw) translateY(-29vh) scale(0.26);
    }

    86.3636363636% {
      transform: translateX(-24vw) translateY(36vh) scale(0.36);
    }

    90.9090909091% {
      transform: translateX(-33vw) translateY(20vh) scale(0.52);
    }

    95.4545454545% {
      transform: translateX(14vw) translateY(10vh) scale(0.8);
    }

    100% {
      transform: translateX(14vw) translateY(-30vh) scale(0.54);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 15s, 5044ms;
    animation-delay: 0ms, 1556ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(27vw) translateY(-37vh) scale(0.9);
    }

    4.1666666667% {
      transform: translateX(12vw) translateY(-22vh) scale(0.44);
    }

    8.3333333333% {
      transform: translateX(-13vw) translateY(-28vh) scale(0.53);
    }

    12.5% {
      transform: translateX(-43vw) translateY(18vh) scale(0.39);
    }

    16.6666666667% {
      transform: translateX(34vw) translateY(31vh) scale(0.97);
    }

    20.8333333333% {
      transform: translateX(47vw) translateY(-33vh) scale(0.97);
    }

    25% {
      transform: translateX(35vw) translateY(-47vh) scale(0.42);
    }

    29.1666666667% {
      transform: translateX(-47vw) translateY(39vh) scale(0.4);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-45vh) scale(0.65);
    }

    37.5% {
      transform: translateX(43vw) translateY(-23vh) scale(0.6);
    }

    41.6666666667% {
      transform: translateX(38vw) translateY(15vh) scale(0.63);
    }

    45.8333333333% {
      transform: translateX(49vw) translateY(34vh) scale(0.82);
    }

    50% {
      transform: translateX(-30vw) translateY(-9vh) scale(0.91);
    }

    54.1666666667% {
      transform: translateX(18vw) translateY(-31vh) scale(0.73);
    }

    58.3333333333% {
      transform: translateX(32vw) translateY(-11vh) scale(0.97);
    }

    62.5% {
      transform: translateX(-12vw) translateY(31vh) scale(0.77);
    }

    66.6666666667% {
      transform: translateX(26vw) translateY(12vh) scale(0.84);
    }

    70.8333333333% {
      transform: translateX(2vw) translateY(-46vh) scale(0.38);
    }

    75% {
      transform: translateX(46vw) translateY(35vh) scale(0.79);
    }

    79.1666666667% {
      transform: translateX(50vw) translateY(26vh) scale(0.46);
    }

    83.3333333333% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.48);
    }

    87.5% {
      transform: translateX(6vw) translateY(-18vh) scale(0.78);
    }

    91.6666666667% {
      transform: translateX(33vw) translateY(-31vh) scale(0.75);
    }

    95.8333333333% {
      transform: translateX(27vw) translateY(-13vh) scale(0.52);
    }

    100% {
      transform: translateX(4vw) translateY(11vh) scale(0.86);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 5550ms;
    animation-delay: 0ms, 4125ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-26vw) translateY(-38vh) scale(0.49);
    }

    5.5555555556% {
      transform: translateX(21vw) translateY(7vh) scale(0.8);
    }

    11.1111111111% {
      transform: translateX(-43vw) translateY(-2vh) scale(0.75);
    }

    16.6666666667% {
      transform: translateX(29vw) translateY(-17vh) scale(1);
    }

    22.2222222222% {
      transform: translateX(-43vw) translateY(13vh) scale(0.88);
    }

    27.7777777778% {
      transform: translateX(33vw) translateY(-48vh) scale(0.86);
    }

    33.3333333333% {
      transform: translateX(17vw) translateY(-23vh) scale(0.95);
    }

    38.8888888889% {
      transform: translateX(-7vw) translateY(39vh) scale(0.4);
    }

    44.4444444444% {
      transform: translateX(-44vw) translateY(-35vh) scale(0.39);
    }

    50% {
      transform: translateX(-23vw) translateY(-10vh) scale(0.54);
    }

    55.5555555556% {
      transform: translateX(8vw) translateY(39vh) scale(0.63);
    }

    61.1111111111% {
      transform: translateX(-3vw) translateY(28vh) scale(0.46);
    }

    66.6666666667% {
      transform: translateX(19vw) translateY(-35vh) scale(0.55);
    }

    72.2222222222% {
      transform: translateX(-32vw) translateY(1vh) scale(0.87);
    }

    77.7777777778% {
      transform: translateX(-18vw) translateY(11vh) scale(0.62);
    }

    83.3333333333% {
      transform: translateX(-24vw) translateY(-31vh) scale(0.42);
    }

    88.8888888889% {
      transform: translateX(5vw) translateY(34vh) scale(0.5);
    }

    94.4444444444% {
      transform: translateX(-11vw) translateY(32vh) scale(0.36);
    }

    100% {
      transform: translateX(5vw) translateY(-8vh) scale(0.99);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 16s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 16s, 10297ms;
    animation-delay: 0ms, 4707ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-36vw) translateY(-2vh) scale(0.37);
    }

    4.7619047619% {
      transform: translateX(-27vw) translateY(30vh) scale(0.38);
    }

    9.5238095238% {
      transform: translateX(45vw) translateY(-23vh) scale(0.33);
    }

    14.2857142857% {
      transform: translateX(25vw) translateY(9vh) scale(0.7);
    }

    19.0476190476% {
      transform: translateX(24vw) translateY(-13vh) scale(0.48);
    }

    23.8095238095% {
      transform: translateX(43vw) translateY(-5vh) scale(0.68);
    }

    28.5714285714% {
      transform: translateX(41vw) translateY(2vh) scale(0.83);
    }

    33.3333333333% {
      transform: translateX(-46vw) translateY(-13vh) scale(0.7);
    }

    38.0952380952% {
      transform: translateX(-39vw) translateY(-25vh) scale(0.85);
    }

    42.8571428571% {
      transform: translateX(-27vw) translateY(23vh) scale(0.33);
    }

    47.619047619% {
      transform: translateX(-13vw) translateY(45vh) scale(0.3);
    }

    52.380952381% {
      transform: translateX(10vw) translateY(-28vh) scale(0.55);
    }

    57.1428571429% {
      transform: translateX(-30vw) translateY(-16vh) scale(0.26);
    }

    61.9047619048% {
      transform: translateX(40vw) translateY(30vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(45vw) translateY(37vh) scale(0.79);
    }

    71.4285714286% {
      transform: translateX(-13vw) translateY(-24vh) scale(0.62);
    }

    76.1904761905% {
      transform: translateX(33vw) translateY(-12vh) scale(0.94);
    }

    80.9523809524% {
      transform: translateX(10vw) translateY(-49vh) scale(0.3);
    }

    85.7142857143% {
      transform: translateX(-15vw) translateY(48vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(-42vw) translateY(40vh) scale(0.54);
    }

    95.2380952381% {
      transform: translateX(-22vw) translateY(0vh) scale(0.63);
    }

    100% {
      transform: translateX(32vw) translateY(14vh) scale(0.79);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 9s, 8018ms;
    animation-delay: 0ms, 4673ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(-30vw) translateY(47vh) scale(0.57);
    }

    3.5714285714% {
      transform: translateX(-19vw) translateY(19vh) scale(0.52);
    }

    7.1428571429% {
      transform: translateX(-14vw) translateY(48vh) scale(0.9);
    }

    10.7142857143% {
      transform: translateX(-44vw) translateY(13vh) scale(0.38);
    }

    14.2857142857% {
      transform: translateX(-10vw) translateY(43vh) scale(0.49);
    }

    17.8571428571% {
      transform: translateX(39vw) translateY(49vh) scale(0.79);
    }

    21.4285714286% {
      transform: translateX(50vw) translateY(-17vh) scale(0.4);
    }

    25% {
      transform: translateX(-20vw) translateY(22vh) scale(0.48);
    }

    28.5714285714% {
      transform: translateX(-27vw) translateY(32vh) scale(0.92);
    }

    32.1428571429% {
      transform: translateX(11vw) translateY(-7vh) scale(0.99);
    }

    35.7142857143% {
      transform: translateX(-27vw) translateY(46vh) scale(0.34);
    }

    39.2857142857% {
      transform: translateX(-24vw) translateY(3vh) scale(0.36);
    }

    42.8571428571% {
      transform: translateX(48vw) translateY(-30vh) scale(0.33);
    }

    46.4285714286% {
      transform: translateX(-44vw) translateY(-30vh) scale(0.5);
    }

    50% {
      transform: translateX(44vw) translateY(-2vh) scale(0.57);
    }

    53.5714285714% {
      transform: translateX(-47vw) translateY(-18vh) scale(0.39);
    }

    57.1428571429% {
      transform: translateX(45vw) translateY(24vh) scale(0.54);
    }

    60.7142857143% {
      transform: translateX(-32vw) translateY(14vh) scale(0.64);
    }

    64.2857142857% {
      transform: translateX(-24vw) translateY(37vh) scale(0.81);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(28vh) scale(0.67);
    }

    71.4285714286% {
      transform: translateX(11vw) translateY(-14vh) scale(0.71);
    }

    75% {
      transform: translateX(-28vw) translateY(-6vh) scale(0.47);
    }

    78.5714285714% {
      transform: translateX(15vw) translateY(-15vh) scale(0.79);
    }

    82.1428571429% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.51);
    }

    85.7142857143% {
      transform: translateX(43vw) translateY(47vh) scale(0.52);
    }

    89.2857142857% {
      transform: translateX(35vw) translateY(-38vh) scale(0.62);
    }

    92.8571428571% {
      transform: translateX(22vw) translateY(-44vh) scale(1);
    }

    96.4285714286% {
      transform: translateX(5vw) translateY(31vh) scale(0.33);
    }

    100% {
      transform: translateX(3vw) translateY(39vh) scale(0.63);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 10s, 9679ms;
    animation-delay: 0ms, 8051ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(22vw) translateY(-45vh) scale(0.86);
    }

    4.1666666667% {
      transform: translateX(20vw) translateY(6vh) scale(0.73);
    }

    8.3333333333% {
      transform: translateX(-36vw) translateY(15vh) scale(0.72);
    }

    12.5% {
      transform: translateX(25vw) translateY(-1vh) scale(0.38);
    }

    16.6666666667% {
      transform: translateX(-19vw) translateY(-28vh) scale(0.91);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(29vh) scale(0.47);
    }

    25% {
      transform: translateX(0vw) translateY(14vh) scale(0.55);
    }

    29.1666666667% {
      transform: translateX(-41vw) translateY(-28vh) scale(0.39);
    }

    33.3333333333% {
      transform: translateX(-11vw) translateY(34vh) scale(0.37);
    }

    37.5% {
      transform: translateX(-10vw) translateY(38vh) scale(0.75);
    }

    41.6666666667% {
      transform: translateX(-3vw) translateY(7vh) scale(0.47);
    }

    45.8333333333% {
      transform: translateX(22vw) translateY(-11vh) scale(0.97);
    }

    50% {
      transform: translateX(41vw) translateY(20vh) scale(0.37);
    }

    54.1666666667% {
      transform: translateX(23vw) translateY(-14vh) scale(0.41);
    }

    58.3333333333% {
      transform: translateX(43vw) translateY(-30vh) scale(0.34);
    }

    62.5% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(-21vw) translateY(-11vh) scale(0.42);
    }

    70.8333333333% {
      transform: translateX(47vw) translateY(11vh) scale(0.29);
    }

    75% {
      transform: translateX(20vw) translateY(-27vh) scale(0.4);
    }

    79.1666666667% {
      transform: translateX(-47vw) translateY(37vh) scale(0.52);
    }

    83.3333333333% {
      transform: translateX(0vw) translateY(3vh) scale(0.44);
    }

    87.5% {
      transform: translateX(27vw) translateY(47vh) scale(0.88);
    }

    91.6666666667% {
      transform: translateX(-5vw) translateY(3vh) scale(0.82);
    }

    95.8333333333% {
      transform: translateX(16vw) translateY(10vh) scale(0.61);
    }

    100% {
      transform: translateX(5vw) translateY(32vh) scale(0.33);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 18s, 8910ms;
    animation-delay: 0ms, 3832ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(21vw) translateY(3vh) scale(0.42);
    }

    4.347826087% {
      transform: translateX(-30vw) translateY(-40vh) scale(0.96);
    }

    8.6956521739% {
      transform: translateX(-26vw) translateY(-40vh) scale(0.82);
    }

    13.0434782609% {
      transform: translateX(-19vw) translateY(-2vh) scale(0.72);
    }

    17.3913043478% {
      transform: translateX(-49vw) translateY(36vh) scale(0.31);
    }

    21.7391304348% {
      transform: translateX(4vw) translateY(-6vh) scale(0.52);
    }

    26.0869565217% {
      transform: translateX(-16vw) translateY(40vh) scale(0.96);
    }

    30.4347826087% {
      transform: translateX(15vw) translateY(15vh) scale(0.9);
    }

    34.7826086957% {
      transform: translateX(26vw) translateY(-6vh) scale(0.28);
    }

    39.1304347826% {
      transform: translateX(9vw) translateY(46vh) scale(0.35);
    }

    43.4782608696% {
      transform: translateX(-25vw) translateY(19vh) scale(0.79);
    }

    47.8260869565% {
      transform: translateX(-33vw) translateY(46vh) scale(0.74);
    }

    52.1739130435% {
      transform: translateX(-22vw) translateY(1vh) scale(0.36);
    }

    56.5217391304% {
      transform: translateX(-49vw) translateY(9vh) scale(0.52);
    }

    60.8695652174% {
      transform: translateX(-29vw) translateY(39vh) scale(0.7);
    }

    65.2173913043% {
      transform: translateX(25vw) translateY(31vh) scale(0.34);
    }

    69.5652173913% {
      transform: translateX(19vw) translateY(18vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(-25vw) translateY(-25vh) scale(0.71);
    }

    78.2608695652% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.77);
    }

    82.6086956522% {
      transform: translateX(31vw) translateY(18vh) scale(0.41);
    }

    86.9565217391% {
      transform: translateX(41vw) translateY(-27vh) scale(0.5);
    }

    91.3043478261% {
      transform: translateX(37vw) translateY(12vh) scale(0.78);
    }

    95.652173913% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.68);
    }

    100% {
      transform: translateX(31vw) translateY(30vh) scale(0.96);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 17s, 8620ms;
    animation-delay: 0ms, 742ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(30vw) translateY(29vh) scale(0.57);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(-47vh) scale(0.3);
    }

    9.5238095238% {
      transform: translateX(33vw) translateY(46vh) scale(0.27);
    }

    14.2857142857% {
      transform: translateX(46vw) translateY(7vh) scale(0.82);
    }

    19.0476190476% {
      transform: translateX(-25vw) translateY(41vh) scale(0.87);
    }

    23.8095238095% {
      transform: translateX(-46vw) translateY(22vh) scale(0.99);
    }

    28.5714285714% {
      transform: translateX(4vw) translateY(11vh) scale(0.59);
    }

    33.3333333333% {
      transform: translateX(-43vw) translateY(20vh) scale(0.97);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(-18vh) scale(0.79);
    }

    42.8571428571% {
      transform: translateX(18vw) translateY(-7vh) scale(0.4);
    }

    47.619047619% {
      transform: translateX(-10vw) translateY(-36vh) scale(1);
    }

    52.380952381% {
      transform: translateX(-4vw) translateY(28vh) scale(0.8);
    }

    57.1428571429% {
      transform: translateX(-43vw) translateY(50vh) scale(0.99);
    }

    61.9047619048% {
      transform: translateX(30vw) translateY(-40vh) scale(0.99);
    }

    66.6666666667% {
      transform: translateX(48vw) translateY(-7vh) scale(0.73);
    }

    71.4285714286% {
      transform: translateX(18vw) translateY(-19vh) scale(0.47);
    }

    76.1904761905% {
      transform: translateX(-14vw) translateY(-35vh) scale(0.71);
    }

    80.9523809524% {
      transform: translateX(34vw) translateY(17vh) scale(0.97);
    }

    85.7142857143% {
      transform: translateX(48vw) translateY(-29vh) scale(0.88);
    }

    90.4761904762% {
      transform: translateX(44vw) translateY(46vh) scale(0.99);
    }

    95.2380952381% {
      transform: translateX(22vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(-3vw) translateY(-48vh) scale(0.54);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw #FFF01F;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw #FFF01F;
    }
  }

  .projectName {
    width: 50%;
  }

  .beastDiv {}

  .beast {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }

  .fire {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0px;
  }

  .fire4 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire4 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -130px;
    top: 0px;
  }

  .fire5 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire5 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    top: 0px;
  }

  .fire6 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire6 {
    width: 75px;
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .fire2 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire2 {
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire2 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 {
    width: 60px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }


  .flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
  }

  .flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  .flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
  }

  .flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
  }

  .flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
  }

  .flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
  }

  .flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
  }

  @keyframes flameWobble {
    50% {
      transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
  }

  @keyframes flamefly {
    0% {
      transform: translate(0) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(-20px, -100px) rotate(180deg);
      opacity: 0;
    }
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 22px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 80px;
    color: black;
    text-shadow: 0 0 5px #000000;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {}

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 35px;
    color: black;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  @keyframes titleFont {
    0% {
      opacity: 1;
    }

    34% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    64% {
      opacity: 0;
    }

    65% {
      opacity: 1;
    }

    84% {
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleFont2 {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    70% {
      opacity: 1
    }

    71% {
      opacity: 0
    }

    85% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .intro {
    color: black;
    font-size: 50px;
    margin-bottom: 15%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .name1 {
    position: absolute;
    animation-name: titleFont;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .name2 {
    position: absolute;
    font-family: 'Underdog', sans-serif;
    animation-name: titleFont2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .shakeWord {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .name3 {
    position: absolute;
    animation-name: titleFont3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 0.5%;
  }

  .intro2 {
    color: black;
    font-size: 25px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -15%;
  }

  .mintDiv {
    width: 60%;
    margin-top: 5%;
    margin-bottom: 5%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid black;
    box-shadow: black -5px 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 27px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .discord:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black -5px 5px;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black 5px 5px;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #fef8e2;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    box-shadow: black 0px 5px;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 174, 0);
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: black;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(37, 59, 23);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2 {
    background-color: #fef8e2;
    color: black;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid black;
    transition: transform .2s;
    box-shadow: black 0px 3px;
    border-radius: 10px;
    font-weight: bold;
  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background-image: linear-gradient(144deg, #a08fa1, #9895a4 50%, #9696a5);
    border: 0;
    box-shadow: black -5px 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    border-radius: 10px;
    transform: rotate(-3deg);
    border: 2px solid black;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #585858;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #2c2c2c;
    display: none;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .ser {
    color: rgb(0, 0, 0);
    font-size: 22px;
  }

  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }
  .closeNote2 {
    position: fixed;
    right: 0;
    top: 5%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote2:hover,
  .closeNote2:focus {
    color: rgb(71, 70, 70);
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    display: none;

  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.856);
    position: fixed;
    width: 80%;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #414141;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #252525;
    z-index: 100000;
    padding: 5%;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    background-color: #ffffffb0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }



  .mainImage {
    position: relative;
    bottom: 0;

  }

  .beast1 {
    width: 480px;
    bottom: 0;
  }

  .beast2 {
    width: 480px;
    bottom: 0;
    position: relative;
    animation: mainImageAnimation ease 2s infinite;

  }

  @keyframes mainImageAnimation {
    0% {
      opacity: 0;
    }

    49% {

      opacity: 0;

    }

    50% {

      opacity: 1;

    }

    100% {
      opacity: 1;
    }
  }


  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 14s, 5348ms;
    animation-delay: 0ms, 3421ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(44vh) scale(0.62);
    }

    4% {
      transform: translateX(-4vw) translateY(50vh) scale(0.77);
    }

    8% {
      transform: translateX(43vw) translateY(-35vh) scale(0.85);
    }

    12% {
      transform: translateX(6vw) translateY(-16vh) scale(0.32);
    }

    16% {
      transform: translateX(40vw) translateY(-28vh) scale(0.61);
    }

    20% {
      transform: translateX(16vw) translateY(-33vh) scale(0.62);
    }

    24% {
      transform: translateX(-13vw) translateY(12vh) scale(0.99);
    }

    28% {
      transform: translateX(-6vw) translateY(15vh) scale(0.52);
    }

    32% {
      transform: translateX(39vw) translateY(40vh) scale(0.42);
    }

    36% {
      transform: translateX(-19vw) translateY(-31vh) scale(0.72);
    }

    40% {
      transform: translateX(47vw) translateY(18vh) scale(0.74);
    }

    44% {
      transform: translateX(-24vw) translateY(1vh) scale(0.8);
    }

    48% {
      transform: translateX(36vw) translateY(-6vh) scale(0.65);
    }

    52% {
      transform: translateX(-6vw) translateY(11vh) scale(0.28);
    }

    56% {
      transform: translateX(34vw) translateY(35vh) scale(0.79);
    }

    60% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.48);
    }

    64% {
      transform: translateX(33vw) translateY(-1vh) scale(0.47);
    }

    68% {
      transform: translateX(-45vw) translateY(-41vh) scale(0.54);
    }

    72% {
      transform: translateX(-20vw) translateY(40vh) scale(0.92);
    }

    76% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.64);
    }

    80% {
      transform: translateX(-23vw) translateY(-18vh) scale(0.92);
    }

    84% {
      transform: translateX(-37vw) translateY(7vh) scale(0.98);
    }

    88% {
      transform: translateX(-31vw) translateY(-35vh) scale(0.92);
    }

    92% {
      transform: translateX(1vw) translateY(-43vh) scale(0.29);
    }

    96% {
      transform: translateX(-41vw) translateY(-20vh) scale(0.44);
    }

    100% {
      transform: translateX(-4vw) translateY(-33vh) scale(0.38);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 11s, 6289ms;
    animation-delay: 0ms, 6547ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-17vw) translateY(40vh) scale(0.32);
    }

    3.5714285714% {
      transform: translateX(9vw) translateY(34vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-21vw) translateY(-5vh) scale(0.64);
    }

    10.7142857143% {
      transform: translateX(47vw) translateY(6vh) scale(0.76);
    }

    14.2857142857% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.93);
    }

    17.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.36);
    }

    21.4285714286% {
      transform: translateX(-34vw) translateY(38vh) scale(0.46);
    }

    25% {
      transform: translateX(-23vw) translateY(-42vh) scale(0.58);
    }

    28.5714285714% {
      transform: translateX(6vw) translateY(-31vh) scale(0.93);
    }

    32.1428571429% {
      transform: translateX(-37vw) translateY(31vh) scale(0.47);
    }

    35.7142857143% {
      transform: translateX(49vw) translateY(-37vh) scale(0.58);
    }

    39.2857142857% {
      transform: translateX(13vw) translateY(-42vh) scale(0.86);
    }

    42.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.48);
    }

    46.4285714286% {
      transform: translateX(-13vw) translateY(-13vh) scale(0.85);
    }

    50% {
      transform: translateX(7vw) translateY(49vh) scale(0.39);
    }

    53.5714285714% {
      transform: translateX(44vw) translateY(-15vh) scale(0.9);
    }

    57.1428571429% {
      transform: translateX(-2vw) translateY(-20vh) scale(0.27);
    }

    60.7142857143% {
      transform: translateX(-3vw) translateY(34vh) scale(0.51);
    }

    64.2857142857% {
      transform: translateX(-40vw) translateY(-5vh) scale(0.39);
    }

    67.8571428571% {
      transform: translateX(1vw) translateY(39vh) scale(0.53);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(-12vh) scale(0.45);
    }

    75% {
      transform: translateX(-17vw) translateY(-36vh) scale(0.63);
    }

    78.5714285714% {
      transform: translateX(27vw) translateY(36vh) scale(0.92);
    }

    82.1428571429% {
      transform: translateX(18vw) translateY(-5vh) scale(0.8);
    }

    85.7142857143% {
      transform: translateX(40vw) translateY(13vh) scale(0.92);
    }

    89.2857142857% {
      transform: translateX(3vw) translateY(-4vh) scale(0.33);
    }

    92.8571428571% {
      transform: translateX(-31vw) translateY(43vh) scale(0.71);
    }

    96.4285714286% {
      transform: translateX(-42vw) translateY(-18vh) scale(0.64);
    }

    100% {
      transform: translateX(24vw) translateY(14vh) scale(0.67);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 9s, 7991ms;
    animation-delay: 0ms, 1922ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-22vw) translateY(24vh) scale(0.62);
    }

    3.5714285714% {
      transform: translateX(-44vw) translateY(37vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-47vw) translateY(46vh) scale(0.91);
    }

    10.7142857143% {
      transform: translateX(39vw) translateY(15vh) scale(0.67);
    }

    14.2857142857% {
      transform: translateX(7vw) translateY(44vh) scale(0.73);
    }

    17.8571428571% {
      transform: translateX(40vw) translateY(11vh) scale(0.56);
    }

    21.4285714286% {
      transform: translateX(23vw) translateY(-30vh) scale(0.98);
    }

    25% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.4);
    }

    28.5714285714% {
      transform: translateX(31vw) translateY(30vh) scale(0.78);
    }

    32.1428571429% {
      transform: translateX(-10vw) translateY(-22vh) scale(0.7);
    }

    35.7142857143% {
      transform: translateX(-31vw) translateY(2vh) scale(0.87);
    }

    39.2857142857% {
      transform: translateX(45vw) translateY(-3vh) scale(0.33);
    }

    42.8571428571% {
      transform: translateX(-7vw) translateY(-30vh) scale(0.88);
    }

    46.4285714286% {
      transform: translateX(8vw) translateY(22vh) scale(0.51);
    }

    50% {
      transform: translateX(31vw) translateY(25vh) scale(0.31);
    }

    53.5714285714% {
      transform: translateX(47vw) translateY(13vh) scale(0.76);
    }

    57.1428571429% {
      transform: translateX(-19vw) translateY(7vh) scale(0.36);
    }

    60.7142857143% {
      transform: translateX(11vw) translateY(6vh) scale(0.83);
    }

    64.2857142857% {
      transform: translateX(48vw) translateY(-38vh) scale(0.62);
    }

    67.8571428571% {
      transform: translateX(41vw) translateY(-18vh) scale(0.68);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(50vh) scale(0.34);
    }

    75% {
      transform: translateX(-31vw) translateY(-15vh) scale(0.51);
    }

    78.5714285714% {
      transform: translateX(-41vw) translateY(9vh) scale(0.91);
    }

    82.1428571429% {
      transform: translateX(-39vw) translateY(16vh) scale(0.45);
    }

    85.7142857143% {
      transform: translateX(-44vw) translateY(9vh) scale(0.83);
    }

    89.2857142857% {
      transform: translateX(-41vw) translateY(8vh) scale(0.66);
    }

    92.8571428571% {
      transform: translateX(-44vw) translateY(42vh) scale(0.56);
    }

    96.4285714286% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.86);
    }

    100% {
      transform: translateX(-4vw) translateY(-8vh) scale(0.42);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 17s, 5968ms;
    animation-delay: 0ms, 1513ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(45vw) translateY(13vh) scale(0.5);
    }

    5.2631578947% {
      transform: translateX(37vw) translateY(14vh) scale(0.98);
    }

    10.5263157895% {
      transform: translateX(-6vw) translateY(-16vh) scale(0.7);
    }

    15.7894736842% {
      transform: translateX(-24vw) translateY(-47vh) scale(0.29);
    }

    21.0526315789% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.63);
    }

    26.3157894737% {
      transform: translateX(-11vw) translateY(-16vh) scale(0.49);
    }

    31.5789473684% {
      transform: translateX(-11vw) translateY(7vh) scale(0.45);
    }

    36.8421052632% {
      transform: translateX(13vw) translateY(-6vh) scale(0.34);
    }

    42.1052631579% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.37);
    }

    47.3684210526% {
      transform: translateX(5vw) translateY(25vh) scale(0.53);
    }

    52.6315789474% {
      transform: translateX(27vw) translateY(14vh) scale(0.74);
    }

    57.8947368421% {
      transform: translateX(-26vw) translateY(-28vh) scale(0.64);
    }

    63.1578947368% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.46);
    }

    68.4210526316% {
      transform: translateX(-23vw) translateY(17vh) scale(0.37);
    }

    73.6842105263% {
      transform: translateX(9vw) translateY(-6vh) scale(0.69);
    }

    78.9473684211% {
      transform: translateX(-41vw) translateY(-42vh) scale(0.75);
    }

    84.2105263158% {
      transform: translateX(34vw) translateY(1vh) scale(0.86);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-26vh) scale(0.91);
    }

    94.7368421053% {
      transform: translateX(-26vw) translateY(13vh) scale(0.49);
    }

    100% {
      transform: translateX(1vw) translateY(-1vh) scale(0.76);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 13s, 9409ms;
    animation-delay: 0ms, 3495ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(34vw) translateY(18vh) scale(0.64);
    }

    4.1666666667% {
      transform: translateX(34vw) translateY(11vh) scale(0.39);
    }

    8.3333333333% {
      transform: translateX(-37vw) translateY(-43vh) scale(0.47);
    }

    12.5% {
      transform: translateX(17vw) translateY(-5vh) scale(0.49);
    }

    16.6666666667% {
      transform: translateX(30vw) translateY(7vh) scale(0.47);
    }

    20.8333333333% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.76);
    }

    25% {
      transform: translateX(-20vw) translateY(28vh) scale(0.7);
    }

    29.1666666667% {
      transform: translateX(-16vw) translateY(-5vh) scale(0.84);
    }

    33.3333333333% {
      transform: translateX(-17vw) translateY(-41vh) scale(0.42);
    }

    37.5% {
      transform: translateX(19vw) translateY(-20vh) scale(0.81);
    }

    41.6666666667% {
      transform: translateX(12vw) translateY(15vh) scale(0.75);
    }

    45.8333333333% {
      transform: translateX(30vw) translateY(-32vh) scale(0.47);
    }

    50% {
      transform: translateX(-40vw) translateY(-38vh) scale(0.59);
    }

    54.1666666667% {
      transform: translateX(4vw) translateY(44vh) scale(0.82);
    }

    58.3333333333% {
      transform: translateX(44vw) translateY(-14vh) scale(0.55);
    }

    62.5% {
      transform: translateX(-15vw) translateY(39vh) scale(0.33);
    }

    66.6666666667% {
      transform: translateX(-28vw) translateY(-30vh) scale(0.29);
    }

    70.8333333333% {
      transform: translateX(40vw) translateY(27vh) scale(0.61);
    }

    75% {
      transform: translateX(22vw) translateY(-21vh) scale(0.53);
    }

    79.1666666667% {
      transform: translateX(-49vw) translateY(21vh) scale(0.64);
    }

    83.3333333333% {
      transform: translateX(7vw) translateY(14vh) scale(0.81);
    }

    87.5% {
      transform: translateX(12vw) translateY(14vh) scale(0.8);
    }

    91.6666666667% {
      transform: translateX(-44vw) translateY(-42vh) scale(0.49);
    }

    95.8333333333% {
      transform: translateX(43vw) translateY(41vh) scale(0.6);
    }

    100% {
      transform: translateX(6vw) translateY(-29vh) scale(0.7);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 10229ms;
    animation-delay: 0ms, 7081ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(23vw) translateY(18vh) scale(0.76);
    }

    4.1666666667% {
      transform: translateX(-26vw) translateY(6vh) scale(0.41);
    }

    8.3333333333% {
      transform: translateX(-1vw) translateY(-18vh) scale(0.63);
    }

    12.5% {
      transform: translateX(22vw) translateY(43vh) scale(0.63);
    }

    16.6666666667% {
      transform: translateX(25vw) translateY(48vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(3vw) translateY(-29vh) scale(0.33);
    }

    25% {
      transform: translateX(26vw) translateY(0vh) scale(0.86);
    }

    29.1666666667% {
      transform: translateX(-33vw) translateY(5vh) scale(0.51);
    }

    33.3333333333% {
      transform: translateX(4vw) translateY(-9vh) scale(0.39);
    }

    37.5% {
      transform: translateX(26vw) translateY(12vh) scale(0.65);
    }

    41.6666666667% {
      transform: translateX(31vw) translateY(18vh) scale(0.42);
    }

    45.8333333333% {
      transform: translateX(25vw) translateY(-45vh) scale(0.9);
    }

    50% {
      transform: translateX(-11vw) translateY(49vh) scale(0.46);
    }

    54.1666666667% {
      transform: translateX(-45vw) translateY(48vh) scale(0.81);
    }

    58.3333333333% {
      transform: translateX(46vw) translateY(5vh) scale(0.57);
    }

    62.5% {
      transform: translateX(35vw) translateY(-47vh) scale(0.56);
    }

    66.6666666667% {
      transform: translateX(22vw) translateY(-44vh) scale(0.83);
    }

    70.8333333333% {
      transform: translateX(-40vw) translateY(-11vh) scale(0.73);
    }

    75% {
      transform: translateX(-19vw) translateY(13vh) scale(0.69);
    }

    79.1666666667% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.83);
    }

    83.3333333333% {
      transform: translateX(33vw) translateY(21vh) scale(0.67);
    }

    87.5% {
      transform: translateX(-36vw) translateY(27vh) scale(0.79);
    }

    91.6666666667% {
      transform: translateX(5vw) translateY(17vh) scale(0.56);
    }

    95.8333333333% {
      transform: translateX(29vw) translateY(-10vh) scale(0.51);
    }

    100% {
      transform: translateX(-1vw) translateY(-14vh) scale(0.29);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 18s, 6470ms;
    animation-delay: 0ms, 8336ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(40vw) translateY(47vh) scale(1);
    }

    3.5714285714% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.78);
    }

    7.1428571429% {
      transform: translateX(24vw) translateY(17vh) scale(0.28);
    }

    10.7142857143% {
      transform: translateX(41vw) translateY(23vh) scale(0.96);
    }

    14.2857142857% {
      transform: translateX(14vw) translateY(13vh) scale(0.95);
    }

    17.8571428571% {
      transform: translateX(-17vw) translateY(49vh) scale(0.69);
    }

    21.4285714286% {
      transform: translateX(9vw) translateY(20vh) scale(0.29);
    }

    25% {
      transform: translateX(-11vw) translateY(30vh) scale(0.96);
    }

    28.5714285714% {
      transform: translateX(32vw) translateY(-27vh) scale(0.43);
    }

    32.1428571429% {
      transform: translateX(-48vw) translateY(-7vh) scale(0.9);
    }

    35.7142857143% {
      transform: translateX(-19vw) translateY(49vh) scale(0.41);
    }

    39.2857142857% {
      transform: translateX(7vw) translateY(-36vh) scale(0.5);
    }

    42.8571428571% {
      transform: translateX(-3vw) translateY(-40vh) scale(0.78);
    }

    46.4285714286% {
      transform: translateX(47vw) translateY(6vh) scale(0.26);
    }

    50% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.3);
    }

    53.5714285714% {
      transform: translateX(-38vw) translateY(-10vh) scale(0.71);
    }

    57.1428571429% {
      transform: translateX(36vw) translateY(-4vh) scale(0.96);
    }

    60.7142857143% {
      transform: translateX(16vw) translateY(6vh) scale(0.62);
    }

    64.2857142857% {
      transform: translateX(-30vw) translateY(-39vh) scale(0.52);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(33vh) scale(0.45);
    }

    71.4285714286% {
      transform: translateX(-10vw) translateY(-28vh) scale(0.78);
    }

    75% {
      transform: translateX(48vw) translateY(16vh) scale(0.71);
    }

    78.5714285714% {
      transform: translateX(-45vw) translateY(-39vh) scale(0.63);
    }

    82.1428571429% {
      transform: translateX(22vw) translateY(-49vh) scale(0.37);
    }

    85.7142857143% {
      transform: translateX(49vw) translateY(27vh) scale(0.26);
    }

    89.2857142857% {
      transform: translateX(23vw) translateY(-44vh) scale(0.73);
    }

    92.8571428571% {
      transform: translateX(-2vw) translateY(29vh) scale(0.47);
    }

    96.4285714286% {
      transform: translateX(-2vw) translateY(-30vh) scale(0.57);
    }

    100% {
      transform: translateX(-10vw) translateY(-29vh) scale(0.96);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 15s, 6398ms;
    animation-delay: 0ms, 565ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(8vw) translateY(-41vh) scale(0.92);
    }

    4.5454545455% {
      transform: translateX(-26vw) translateY(43vh) scale(0.97);
    }

    9.0909090909% {
      transform: translateX(38vw) translateY(-44vh) scale(0.43);
    }

    13.6363636364% {
      transform: translateX(-35vw) translateY(40vh) scale(0.78);
    }

    18.1818181818% {
      transform: translateX(-7vw) translateY(-35vh) scale(0.68);
    }

    22.7272727273% {
      transform: translateX(-43vw) translateY(-29vh) scale(0.48);
    }

    27.2727272727% {
      transform: translateX(-18vw) translateY(27vh) scale(0.7);
    }

    31.8181818182% {
      transform: translateX(42vw) translateY(-45vh) scale(0.77);
    }

    36.3636363636% {
      transform: translateX(1vw) translateY(-13vh) scale(0.84);
    }

    40.9090909091% {
      transform: translateX(35vw) translateY(-26vh) scale(0.84);
    }

    45.4545454545% {
      transform: translateX(-3vw) translateY(48vh) scale(0.29);
    }

    50% {
      transform: translateX(9vw) translateY(-4vh) scale(0.99);
    }

    54.5454545455% {
      transform: translateX(-36vw) translateY(-36vh) scale(0.5);
    }

    59.0909090909% {
      transform: translateX(42vw) translateY(-44vh) scale(0.39);
    }

    63.6363636364% {
      transform: translateX(26vw) translateY(20vh) scale(0.76);
    }

    68.1818181818% {
      transform: translateX(30vw) translateY(48vh) scale(0.9);
    }

    72.7272727273% {
      transform: translateX(-9vw) translateY(35vh) scale(0.97);
    }

    77.2727272727% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.53);
    }

    81.8181818182% {
      transform: translateX(-49vw) translateY(-29vh) scale(0.26);
    }

    86.3636363636% {
      transform: translateX(-24vw) translateY(36vh) scale(0.36);
    }

    90.9090909091% {
      transform: translateX(-33vw) translateY(20vh) scale(0.52);
    }

    95.4545454545% {
      transform: translateX(14vw) translateY(10vh) scale(0.8);
    }

    100% {
      transform: translateX(14vw) translateY(-30vh) scale(0.54);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 15s, 5044ms;
    animation-delay: 0ms, 1556ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(27vw) translateY(-37vh) scale(0.9);
    }

    4.1666666667% {
      transform: translateX(12vw) translateY(-22vh) scale(0.44);
    }

    8.3333333333% {
      transform: translateX(-13vw) translateY(-28vh) scale(0.53);
    }

    12.5% {
      transform: translateX(-43vw) translateY(18vh) scale(0.39);
    }

    16.6666666667% {
      transform: translateX(34vw) translateY(31vh) scale(0.97);
    }

    20.8333333333% {
      transform: translateX(47vw) translateY(-33vh) scale(0.97);
    }

    25% {
      transform: translateX(35vw) translateY(-47vh) scale(0.42);
    }

    29.1666666667% {
      transform: translateX(-47vw) translateY(39vh) scale(0.4);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-45vh) scale(0.65);
    }

    37.5% {
      transform: translateX(43vw) translateY(-23vh) scale(0.6);
    }

    41.6666666667% {
      transform: translateX(38vw) translateY(15vh) scale(0.63);
    }

    45.8333333333% {
      transform: translateX(49vw) translateY(34vh) scale(0.82);
    }

    50% {
      transform: translateX(-30vw) translateY(-9vh) scale(0.91);
    }

    54.1666666667% {
      transform: translateX(18vw) translateY(-31vh) scale(0.73);
    }

    58.3333333333% {
      transform: translateX(32vw) translateY(-11vh) scale(0.97);
    }

    62.5% {
      transform: translateX(-12vw) translateY(31vh) scale(0.77);
    }

    66.6666666667% {
      transform: translateX(26vw) translateY(12vh) scale(0.84);
    }

    70.8333333333% {
      transform: translateX(2vw) translateY(-46vh) scale(0.38);
    }

    75% {
      transform: translateX(46vw) translateY(35vh) scale(0.79);
    }

    79.1666666667% {
      transform: translateX(50vw) translateY(26vh) scale(0.46);
    }

    83.3333333333% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.48);
    }

    87.5% {
      transform: translateX(6vw) translateY(-18vh) scale(0.78);
    }

    91.6666666667% {
      transform: translateX(33vw) translateY(-31vh) scale(0.75);
    }

    95.8333333333% {
      transform: translateX(27vw) translateY(-13vh) scale(0.52);
    }

    100% {
      transform: translateX(4vw) translateY(11vh) scale(0.86);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 5550ms;
    animation-delay: 0ms, 4125ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-26vw) translateY(-38vh) scale(0.49);
    }

    5.5555555556% {
      transform: translateX(21vw) translateY(7vh) scale(0.8);
    }

    11.1111111111% {
      transform: translateX(-43vw) translateY(-2vh) scale(0.75);
    }

    16.6666666667% {
      transform: translateX(29vw) translateY(-17vh) scale(1);
    }

    22.2222222222% {
      transform: translateX(-43vw) translateY(13vh) scale(0.88);
    }

    27.7777777778% {
      transform: translateX(33vw) translateY(-48vh) scale(0.86);
    }

    33.3333333333% {
      transform: translateX(17vw) translateY(-23vh) scale(0.95);
    }

    38.8888888889% {
      transform: translateX(-7vw) translateY(39vh) scale(0.4);
    }

    44.4444444444% {
      transform: translateX(-44vw) translateY(-35vh) scale(0.39);
    }

    50% {
      transform: translateX(-23vw) translateY(-10vh) scale(0.54);
    }

    55.5555555556% {
      transform: translateX(8vw) translateY(39vh) scale(0.63);
    }

    61.1111111111% {
      transform: translateX(-3vw) translateY(28vh) scale(0.46);
    }

    66.6666666667% {
      transform: translateX(19vw) translateY(-35vh) scale(0.55);
    }

    72.2222222222% {
      transform: translateX(-32vw) translateY(1vh) scale(0.87);
    }

    77.7777777778% {
      transform: translateX(-18vw) translateY(11vh) scale(0.62);
    }

    83.3333333333% {
      transform: translateX(-24vw) translateY(-31vh) scale(0.42);
    }

    88.8888888889% {
      transform: translateX(5vw) translateY(34vh) scale(0.5);
    }

    94.4444444444% {
      transform: translateX(-11vw) translateY(32vh) scale(0.36);
    }

    100% {
      transform: translateX(5vw) translateY(-8vh) scale(0.99);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 16s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 16s, 10297ms;
    animation-delay: 0ms, 4707ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-36vw) translateY(-2vh) scale(0.37);
    }

    4.7619047619% {
      transform: translateX(-27vw) translateY(30vh) scale(0.38);
    }

    9.5238095238% {
      transform: translateX(45vw) translateY(-23vh) scale(0.33);
    }

    14.2857142857% {
      transform: translateX(25vw) translateY(9vh) scale(0.7);
    }

    19.0476190476% {
      transform: translateX(24vw) translateY(-13vh) scale(0.48);
    }

    23.8095238095% {
      transform: translateX(43vw) translateY(-5vh) scale(0.68);
    }

    28.5714285714% {
      transform: translateX(41vw) translateY(2vh) scale(0.83);
    }

    33.3333333333% {
      transform: translateX(-46vw) translateY(-13vh) scale(0.7);
    }

    38.0952380952% {
      transform: translateX(-39vw) translateY(-25vh) scale(0.85);
    }

    42.8571428571% {
      transform: translateX(-27vw) translateY(23vh) scale(0.33);
    }

    47.619047619% {
      transform: translateX(-13vw) translateY(45vh) scale(0.3);
    }

    52.380952381% {
      transform: translateX(10vw) translateY(-28vh) scale(0.55);
    }

    57.1428571429% {
      transform: translateX(-30vw) translateY(-16vh) scale(0.26);
    }

    61.9047619048% {
      transform: translateX(40vw) translateY(30vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(45vw) translateY(37vh) scale(0.79);
    }

    71.4285714286% {
      transform: translateX(-13vw) translateY(-24vh) scale(0.62);
    }

    76.1904761905% {
      transform: translateX(33vw) translateY(-12vh) scale(0.94);
    }

    80.9523809524% {
      transform: translateX(10vw) translateY(-49vh) scale(0.3);
    }

    85.7142857143% {
      transform: translateX(-15vw) translateY(48vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(-42vw) translateY(40vh) scale(0.54);
    }

    95.2380952381% {
      transform: translateX(-22vw) translateY(0vh) scale(0.63);
    }

    100% {
      transform: translateX(32vw) translateY(14vh) scale(0.79);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 9s, 8018ms;
    animation-delay: 0ms, 4673ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(-30vw) translateY(47vh) scale(0.57);
    }

    3.5714285714% {
      transform: translateX(-19vw) translateY(19vh) scale(0.52);
    }

    7.1428571429% {
      transform: translateX(-14vw) translateY(48vh) scale(0.9);
    }

    10.7142857143% {
      transform: translateX(-44vw) translateY(13vh) scale(0.38);
    }

    14.2857142857% {
      transform: translateX(-10vw) translateY(43vh) scale(0.49);
    }

    17.8571428571% {
      transform: translateX(39vw) translateY(49vh) scale(0.79);
    }

    21.4285714286% {
      transform: translateX(50vw) translateY(-17vh) scale(0.4);
    }

    25% {
      transform: translateX(-20vw) translateY(22vh) scale(0.48);
    }

    28.5714285714% {
      transform: translateX(-27vw) translateY(32vh) scale(0.92);
    }

    32.1428571429% {
      transform: translateX(11vw) translateY(-7vh) scale(0.99);
    }

    35.7142857143% {
      transform: translateX(-27vw) translateY(46vh) scale(0.34);
    }

    39.2857142857% {
      transform: translateX(-24vw) translateY(3vh) scale(0.36);
    }

    42.8571428571% {
      transform: translateX(48vw) translateY(-30vh) scale(0.33);
    }

    46.4285714286% {
      transform: translateX(-44vw) translateY(-30vh) scale(0.5);
    }

    50% {
      transform: translateX(44vw) translateY(-2vh) scale(0.57);
    }

    53.5714285714% {
      transform: translateX(-47vw) translateY(-18vh) scale(0.39);
    }

    57.1428571429% {
      transform: translateX(45vw) translateY(24vh) scale(0.54);
    }

    60.7142857143% {
      transform: translateX(-32vw) translateY(14vh) scale(0.64);
    }

    64.2857142857% {
      transform: translateX(-24vw) translateY(37vh) scale(0.81);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(28vh) scale(0.67);
    }

    71.4285714286% {
      transform: translateX(11vw) translateY(-14vh) scale(0.71);
    }

    75% {
      transform: translateX(-28vw) translateY(-6vh) scale(0.47);
    }

    78.5714285714% {
      transform: translateX(15vw) translateY(-15vh) scale(0.79);
    }

    82.1428571429% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.51);
    }

    85.7142857143% {
      transform: translateX(43vw) translateY(47vh) scale(0.52);
    }

    89.2857142857% {
      transform: translateX(35vw) translateY(-38vh) scale(0.62);
    }

    92.8571428571% {
      transform: translateX(22vw) translateY(-44vh) scale(1);
    }

    96.4285714286% {
      transform: translateX(5vw) translateY(31vh) scale(0.33);
    }

    100% {
      transform: translateX(3vw) translateY(39vh) scale(0.63);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 10s, 9679ms;
    animation-delay: 0ms, 8051ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(22vw) translateY(-45vh) scale(0.86);
    }

    4.1666666667% {
      transform: translateX(20vw) translateY(6vh) scale(0.73);
    }

    8.3333333333% {
      transform: translateX(-36vw) translateY(15vh) scale(0.72);
    }

    12.5% {
      transform: translateX(25vw) translateY(-1vh) scale(0.38);
    }

    16.6666666667% {
      transform: translateX(-19vw) translateY(-28vh) scale(0.91);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(29vh) scale(0.47);
    }

    25% {
      transform: translateX(0vw) translateY(14vh) scale(0.55);
    }

    29.1666666667% {
      transform: translateX(-41vw) translateY(-28vh) scale(0.39);
    }

    33.3333333333% {
      transform: translateX(-11vw) translateY(34vh) scale(0.37);
    }

    37.5% {
      transform: translateX(-10vw) translateY(38vh) scale(0.75);
    }

    41.6666666667% {
      transform: translateX(-3vw) translateY(7vh) scale(0.47);
    }

    45.8333333333% {
      transform: translateX(22vw) translateY(-11vh) scale(0.97);
    }

    50% {
      transform: translateX(41vw) translateY(20vh) scale(0.37);
    }

    54.1666666667% {
      transform: translateX(23vw) translateY(-14vh) scale(0.41);
    }

    58.3333333333% {
      transform: translateX(43vw) translateY(-30vh) scale(0.34);
    }

    62.5% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(-21vw) translateY(-11vh) scale(0.42);
    }

    70.8333333333% {
      transform: translateX(47vw) translateY(11vh) scale(0.29);
    }

    75% {
      transform: translateX(20vw) translateY(-27vh) scale(0.4);
    }

    79.1666666667% {
      transform: translateX(-47vw) translateY(37vh) scale(0.52);
    }

    83.3333333333% {
      transform: translateX(0vw) translateY(3vh) scale(0.44);
    }

    87.5% {
      transform: translateX(27vw) translateY(47vh) scale(0.88);
    }

    91.6666666667% {
      transform: translateX(-5vw) translateY(3vh) scale(0.82);
    }

    95.8333333333% {
      transform: translateX(16vw) translateY(10vh) scale(0.61);
    }

    100% {
      transform: translateX(5vw) translateY(32vh) scale(0.33);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 18s, 8910ms;
    animation-delay: 0ms, 3832ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(21vw) translateY(3vh) scale(0.42);
    }

    4.347826087% {
      transform: translateX(-30vw) translateY(-40vh) scale(0.96);
    }

    8.6956521739% {
      transform: translateX(-26vw) translateY(-40vh) scale(0.82);
    }

    13.0434782609% {
      transform: translateX(-19vw) translateY(-2vh) scale(0.72);
    }

    17.3913043478% {
      transform: translateX(-49vw) translateY(36vh) scale(0.31);
    }

    21.7391304348% {
      transform: translateX(4vw) translateY(-6vh) scale(0.52);
    }

    26.0869565217% {
      transform: translateX(-16vw) translateY(40vh) scale(0.96);
    }

    30.4347826087% {
      transform: translateX(15vw) translateY(15vh) scale(0.9);
    }

    34.7826086957% {
      transform: translateX(26vw) translateY(-6vh) scale(0.28);
    }

    39.1304347826% {
      transform: translateX(9vw) translateY(46vh) scale(0.35);
    }

    43.4782608696% {
      transform: translateX(-25vw) translateY(19vh) scale(0.79);
    }

    47.8260869565% {
      transform: translateX(-33vw) translateY(46vh) scale(0.74);
    }

    52.1739130435% {
      transform: translateX(-22vw) translateY(1vh) scale(0.36);
    }

    56.5217391304% {
      transform: translateX(-49vw) translateY(9vh) scale(0.52);
    }

    60.8695652174% {
      transform: translateX(-29vw) translateY(39vh) scale(0.7);
    }

    65.2173913043% {
      transform: translateX(25vw) translateY(31vh) scale(0.34);
    }

    69.5652173913% {
      transform: translateX(19vw) translateY(18vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(-25vw) translateY(-25vh) scale(0.71);
    }

    78.2608695652% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.77);
    }

    82.6086956522% {
      transform: translateX(31vw) translateY(18vh) scale(0.41);
    }

    86.9565217391% {
      transform: translateX(41vw) translateY(-27vh) scale(0.5);
    }

    91.3043478261% {
      transform: translateX(37vw) translateY(12vh) scale(0.78);
    }

    95.652173913% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.68);
    }

    100% {
      transform: translateX(31vw) translateY(30vh) scale(0.96);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 17s, 8620ms;
    animation-delay: 0ms, 742ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(30vw) translateY(29vh) scale(0.57);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(-47vh) scale(0.3);
    }

    9.5238095238% {
      transform: translateX(33vw) translateY(46vh) scale(0.27);
    }

    14.2857142857% {
      transform: translateX(46vw) translateY(7vh) scale(0.82);
    }

    19.0476190476% {
      transform: translateX(-25vw) translateY(41vh) scale(0.87);
    }

    23.8095238095% {
      transform: translateX(-46vw) translateY(22vh) scale(0.99);
    }

    28.5714285714% {
      transform: translateX(4vw) translateY(11vh) scale(0.59);
    }

    33.3333333333% {
      transform: translateX(-43vw) translateY(20vh) scale(0.97);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(-18vh) scale(0.79);
    }

    42.8571428571% {
      transform: translateX(18vw) translateY(-7vh) scale(0.4);
    }

    47.619047619% {
      transform: translateX(-10vw) translateY(-36vh) scale(1);
    }

    52.380952381% {
      transform: translateX(-4vw) translateY(28vh) scale(0.8);
    }

    57.1428571429% {
      transform: translateX(-43vw) translateY(50vh) scale(0.99);
    }

    61.9047619048% {
      transform: translateX(30vw) translateY(-40vh) scale(0.99);
    }

    66.6666666667% {
      transform: translateX(48vw) translateY(-7vh) scale(0.73);
    }

    71.4285714286% {
      transform: translateX(18vw) translateY(-19vh) scale(0.47);
    }

    76.1904761905% {
      transform: translateX(-14vw) translateY(-35vh) scale(0.71);
    }

    80.9523809524% {
      transform: translateX(34vw) translateY(17vh) scale(0.97);
    }

    85.7142857143% {
      transform: translateX(48vw) translateY(-29vh) scale(0.88);
    }

    90.4761904762% {
      transform: translateX(44vw) translateY(46vh) scale(0.99);
    }

    95.2380952381% {
      transform: translateX(22vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(-3vw) translateY(-48vh) scale(0.54);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw #FFF01F;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw #FFF01F;
    }
  }

  .projectName {
    width: 50%;
  }

  .beastDiv {}

  .beast {
    width: 500px;
  }

  .fire {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0px;
  }

  .fire4 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire4 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -130px;
    top: 0px;
  }

  .fire5 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire5 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    top: 0px;
  }

  .fire6 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire6 {
    width: 75px;
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .fire2 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire2 {
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire2 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 {
    width: 60px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }


  .flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
  }

  .flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  .flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
  }

  .flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
  }

  .flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
  }

  .flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
  }

  .flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
  }

  @keyframes flameWobble {
    50% {
      transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
  }

  @keyframes flamefly {
    0% {
      transform: translate(0) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(-20px, -100px) rotate(180deg);
      opacity: 0;
    }
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 22px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 80px;
    color: black;
    text-shadow: 0 0 5px #000000;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {}

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 35px;
    color: black;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  @keyframes titleFont {
    0% {
      opacity: 1;
    }

    34% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    64% {
      opacity: 0;
    }

    65% {
      opacity: 1;
    }

    84% {
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleFont2 {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    70% {
      opacity: 1
    }

    71% {
      opacity: 0
    }

    85% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .intro {
    color: black;
    font-size: 50px;
    position: relative;
    margin-bottom: 25%;
  }

  .name1 {
    position: absolute;
    animation-name: titleFont;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .name2 {
    position: absolute;
    font-family: 'Underdog', sans-serif;
    animation-name: titleFont2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .shakeWord {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .name3 {
    position: absolute;
    animation-name: titleFont3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 0.5%;
  }

  .intro2 {
    color: black;
    font-size: 25px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -25%;
  }

  .mintDiv {
    width: 45%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid black;
    box-shadow: black -5px 5px;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 27px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .discord:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black -5px 5px;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black 5px 5px;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #fef8e2;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    box-shadow: black 0px 5px;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 174, 0);
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: black;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(37, 59, 23);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2 {
    background-color: #fef8e2;
    color: black;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid black;
    transition: transform .2s;
    box-shadow: black 0px 3px;
    border-radius: 10px;
    font-weight: bold;
  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background-image: linear-gradient(144deg, #a08fa1, #9895a4 50%, #9696a5);
    border: 0;
    box-shadow: black -5px 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    border-radius: 10px;
    transform: rotate(-3deg);
    border: 2px solid black;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #585858;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #2c2c2c;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }
  .ser {
    color: rgb(0, 0, 0);
    font-size: 25px;
  }

  .closeNote2 {
    position: fixed;
    right: 0;
    top: 5%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote2:hover,
  .closeNote2:focus {
    color: rgb(71, 70, 70);
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    display: none;

  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.856);
    position: fixed;
    width: 80%;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #414141;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #252525;
    z-index: 100000;
    padding: 5%;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    background-color: #ffffffb0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .mainImage {
    position: relative;
    bottom: 0;
  }

  .beast1 {
    width: 480px;
    bottom: 0;
  }

  .beast2 {
    width: 480px;
    bottom: 0;
    position: relative;
    animation: mainImageAnimation ease 2s infinite;

  }

  @keyframes mainImageAnimation {
    0% {
      opacity: 0;
    }

    49% {

      opacity: 0;

    }

    50% {

      opacity: 1;

    }

    100% {
      opacity: 1;
    }
  }


  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 14s, 5348ms;
    animation-delay: 0ms, 3421ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(44vh) scale(0.62);
    }

    4% {
      transform: translateX(-4vw) translateY(50vh) scale(0.77);
    }

    8% {
      transform: translateX(43vw) translateY(-35vh) scale(0.85);
    }

    12% {
      transform: translateX(6vw) translateY(-16vh) scale(0.32);
    }

    16% {
      transform: translateX(40vw) translateY(-28vh) scale(0.61);
    }

    20% {
      transform: translateX(16vw) translateY(-33vh) scale(0.62);
    }

    24% {
      transform: translateX(-13vw) translateY(12vh) scale(0.99);
    }

    28% {
      transform: translateX(-6vw) translateY(15vh) scale(0.52);
    }

    32% {
      transform: translateX(39vw) translateY(40vh) scale(0.42);
    }

    36% {
      transform: translateX(-19vw) translateY(-31vh) scale(0.72);
    }

    40% {
      transform: translateX(47vw) translateY(18vh) scale(0.74);
    }

    44% {
      transform: translateX(-24vw) translateY(1vh) scale(0.8);
    }

    48% {
      transform: translateX(36vw) translateY(-6vh) scale(0.65);
    }

    52% {
      transform: translateX(-6vw) translateY(11vh) scale(0.28);
    }

    56% {
      transform: translateX(34vw) translateY(35vh) scale(0.79);
    }

    60% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.48);
    }

    64% {
      transform: translateX(33vw) translateY(-1vh) scale(0.47);
    }

    68% {
      transform: translateX(-45vw) translateY(-41vh) scale(0.54);
    }

    72% {
      transform: translateX(-20vw) translateY(40vh) scale(0.92);
    }

    76% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.64);
    }

    80% {
      transform: translateX(-23vw) translateY(-18vh) scale(0.92);
    }

    84% {
      transform: translateX(-37vw) translateY(7vh) scale(0.98);
    }

    88% {
      transform: translateX(-31vw) translateY(-35vh) scale(0.92);
    }

    92% {
      transform: translateX(1vw) translateY(-43vh) scale(0.29);
    }

    96% {
      transform: translateX(-41vw) translateY(-20vh) scale(0.44);
    }

    100% {
      transform: translateX(-4vw) translateY(-33vh) scale(0.38);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 11s, 6289ms;
    animation-delay: 0ms, 6547ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-17vw) translateY(40vh) scale(0.32);
    }

    3.5714285714% {
      transform: translateX(9vw) translateY(34vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-21vw) translateY(-5vh) scale(0.64);
    }

    10.7142857143% {
      transform: translateX(47vw) translateY(6vh) scale(0.76);
    }

    14.2857142857% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.93);
    }

    17.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.36);
    }

    21.4285714286% {
      transform: translateX(-34vw) translateY(38vh) scale(0.46);
    }

    25% {
      transform: translateX(-23vw) translateY(-42vh) scale(0.58);
    }

    28.5714285714% {
      transform: translateX(6vw) translateY(-31vh) scale(0.93);
    }

    32.1428571429% {
      transform: translateX(-37vw) translateY(31vh) scale(0.47);
    }

    35.7142857143% {
      transform: translateX(49vw) translateY(-37vh) scale(0.58);
    }

    39.2857142857% {
      transform: translateX(13vw) translateY(-42vh) scale(0.86);
    }

    42.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.48);
    }

    46.4285714286% {
      transform: translateX(-13vw) translateY(-13vh) scale(0.85);
    }

    50% {
      transform: translateX(7vw) translateY(49vh) scale(0.39);
    }

    53.5714285714% {
      transform: translateX(44vw) translateY(-15vh) scale(0.9);
    }

    57.1428571429% {
      transform: translateX(-2vw) translateY(-20vh) scale(0.27);
    }

    60.7142857143% {
      transform: translateX(-3vw) translateY(34vh) scale(0.51);
    }

    64.2857142857% {
      transform: translateX(-40vw) translateY(-5vh) scale(0.39);
    }

    67.8571428571% {
      transform: translateX(1vw) translateY(39vh) scale(0.53);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(-12vh) scale(0.45);
    }

    75% {
      transform: translateX(-17vw) translateY(-36vh) scale(0.63);
    }

    78.5714285714% {
      transform: translateX(27vw) translateY(36vh) scale(0.92);
    }

    82.1428571429% {
      transform: translateX(18vw) translateY(-5vh) scale(0.8);
    }

    85.7142857143% {
      transform: translateX(40vw) translateY(13vh) scale(0.92);
    }

    89.2857142857% {
      transform: translateX(3vw) translateY(-4vh) scale(0.33);
    }

    92.8571428571% {
      transform: translateX(-31vw) translateY(43vh) scale(0.71);
    }

    96.4285714286% {
      transform: translateX(-42vw) translateY(-18vh) scale(0.64);
    }

    100% {
      transform: translateX(24vw) translateY(14vh) scale(0.67);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 9s, 7991ms;
    animation-delay: 0ms, 1922ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-22vw) translateY(24vh) scale(0.62);
    }

    3.5714285714% {
      transform: translateX(-44vw) translateY(37vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-47vw) translateY(46vh) scale(0.91);
    }

    10.7142857143% {
      transform: translateX(39vw) translateY(15vh) scale(0.67);
    }

    14.2857142857% {
      transform: translateX(7vw) translateY(44vh) scale(0.73);
    }

    17.8571428571% {
      transform: translateX(40vw) translateY(11vh) scale(0.56);
    }

    21.4285714286% {
      transform: translateX(23vw) translateY(-30vh) scale(0.98);
    }

    25% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.4);
    }

    28.5714285714% {
      transform: translateX(31vw) translateY(30vh) scale(0.78);
    }

    32.1428571429% {
      transform: translateX(-10vw) translateY(-22vh) scale(0.7);
    }

    35.7142857143% {
      transform: translateX(-31vw) translateY(2vh) scale(0.87);
    }

    39.2857142857% {
      transform: translateX(45vw) translateY(-3vh) scale(0.33);
    }

    42.8571428571% {
      transform: translateX(-7vw) translateY(-30vh) scale(0.88);
    }

    46.4285714286% {
      transform: translateX(8vw) translateY(22vh) scale(0.51);
    }

    50% {
      transform: translateX(31vw) translateY(25vh) scale(0.31);
    }

    53.5714285714% {
      transform: translateX(47vw) translateY(13vh) scale(0.76);
    }

    57.1428571429% {
      transform: translateX(-19vw) translateY(7vh) scale(0.36);
    }

    60.7142857143% {
      transform: translateX(11vw) translateY(6vh) scale(0.83);
    }

    64.2857142857% {
      transform: translateX(48vw) translateY(-38vh) scale(0.62);
    }

    67.8571428571% {
      transform: translateX(41vw) translateY(-18vh) scale(0.68);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(50vh) scale(0.34);
    }

    75% {
      transform: translateX(-31vw) translateY(-15vh) scale(0.51);
    }

    78.5714285714% {
      transform: translateX(-41vw) translateY(9vh) scale(0.91);
    }

    82.1428571429% {
      transform: translateX(-39vw) translateY(16vh) scale(0.45);
    }

    85.7142857143% {
      transform: translateX(-44vw) translateY(9vh) scale(0.83);
    }

    89.2857142857% {
      transform: translateX(-41vw) translateY(8vh) scale(0.66);
    }

    92.8571428571% {
      transform: translateX(-44vw) translateY(42vh) scale(0.56);
    }

    96.4285714286% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.86);
    }

    100% {
      transform: translateX(-4vw) translateY(-8vh) scale(0.42);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 17s, 5968ms;
    animation-delay: 0ms, 1513ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(45vw) translateY(13vh) scale(0.5);
    }

    5.2631578947% {
      transform: translateX(37vw) translateY(14vh) scale(0.98);
    }

    10.5263157895% {
      transform: translateX(-6vw) translateY(-16vh) scale(0.7);
    }

    15.7894736842% {
      transform: translateX(-24vw) translateY(-47vh) scale(0.29);
    }

    21.0526315789% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.63);
    }

    26.3157894737% {
      transform: translateX(-11vw) translateY(-16vh) scale(0.49);
    }

    31.5789473684% {
      transform: translateX(-11vw) translateY(7vh) scale(0.45);
    }

    36.8421052632% {
      transform: translateX(13vw) translateY(-6vh) scale(0.34);
    }

    42.1052631579% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.37);
    }

    47.3684210526% {
      transform: translateX(5vw) translateY(25vh) scale(0.53);
    }

    52.6315789474% {
      transform: translateX(27vw) translateY(14vh) scale(0.74);
    }

    57.8947368421% {
      transform: translateX(-26vw) translateY(-28vh) scale(0.64);
    }

    63.1578947368% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.46);
    }

    68.4210526316% {
      transform: translateX(-23vw) translateY(17vh) scale(0.37);
    }

    73.6842105263% {
      transform: translateX(9vw) translateY(-6vh) scale(0.69);
    }

    78.9473684211% {
      transform: translateX(-41vw) translateY(-42vh) scale(0.75);
    }

    84.2105263158% {
      transform: translateX(34vw) translateY(1vh) scale(0.86);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-26vh) scale(0.91);
    }

    94.7368421053% {
      transform: translateX(-26vw) translateY(13vh) scale(0.49);
    }

    100% {
      transform: translateX(1vw) translateY(-1vh) scale(0.76);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 13s, 9409ms;
    animation-delay: 0ms, 3495ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(34vw) translateY(18vh) scale(0.64);
    }

    4.1666666667% {
      transform: translateX(34vw) translateY(11vh) scale(0.39);
    }

    8.3333333333% {
      transform: translateX(-37vw) translateY(-43vh) scale(0.47);
    }

    12.5% {
      transform: translateX(17vw) translateY(-5vh) scale(0.49);
    }

    16.6666666667% {
      transform: translateX(30vw) translateY(7vh) scale(0.47);
    }

    20.8333333333% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.76);
    }

    25% {
      transform: translateX(-20vw) translateY(28vh) scale(0.7);
    }

    29.1666666667% {
      transform: translateX(-16vw) translateY(-5vh) scale(0.84);
    }

    33.3333333333% {
      transform: translateX(-17vw) translateY(-41vh) scale(0.42);
    }

    37.5% {
      transform: translateX(19vw) translateY(-20vh) scale(0.81);
    }

    41.6666666667% {
      transform: translateX(12vw) translateY(15vh) scale(0.75);
    }

    45.8333333333% {
      transform: translateX(30vw) translateY(-32vh) scale(0.47);
    }

    50% {
      transform: translateX(-40vw) translateY(-38vh) scale(0.59);
    }

    54.1666666667% {
      transform: translateX(4vw) translateY(44vh) scale(0.82);
    }

    58.3333333333% {
      transform: translateX(44vw) translateY(-14vh) scale(0.55);
    }

    62.5% {
      transform: translateX(-15vw) translateY(39vh) scale(0.33);
    }

    66.6666666667% {
      transform: translateX(-28vw) translateY(-30vh) scale(0.29);
    }

    70.8333333333% {
      transform: translateX(40vw) translateY(27vh) scale(0.61);
    }

    75% {
      transform: translateX(22vw) translateY(-21vh) scale(0.53);
    }

    79.1666666667% {
      transform: translateX(-49vw) translateY(21vh) scale(0.64);
    }

    83.3333333333% {
      transform: translateX(7vw) translateY(14vh) scale(0.81);
    }

    87.5% {
      transform: translateX(12vw) translateY(14vh) scale(0.8);
    }

    91.6666666667% {
      transform: translateX(-44vw) translateY(-42vh) scale(0.49);
    }

    95.8333333333% {
      transform: translateX(43vw) translateY(41vh) scale(0.6);
    }

    100% {
      transform: translateX(6vw) translateY(-29vh) scale(0.7);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 10229ms;
    animation-delay: 0ms, 7081ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(23vw) translateY(18vh) scale(0.76);
    }

    4.1666666667% {
      transform: translateX(-26vw) translateY(6vh) scale(0.41);
    }

    8.3333333333% {
      transform: translateX(-1vw) translateY(-18vh) scale(0.63);
    }

    12.5% {
      transform: translateX(22vw) translateY(43vh) scale(0.63);
    }

    16.6666666667% {
      transform: translateX(25vw) translateY(48vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(3vw) translateY(-29vh) scale(0.33);
    }

    25% {
      transform: translateX(26vw) translateY(0vh) scale(0.86);
    }

    29.1666666667% {
      transform: translateX(-33vw) translateY(5vh) scale(0.51);
    }

    33.3333333333% {
      transform: translateX(4vw) translateY(-9vh) scale(0.39);
    }

    37.5% {
      transform: translateX(26vw) translateY(12vh) scale(0.65);
    }

    41.6666666667% {
      transform: translateX(31vw) translateY(18vh) scale(0.42);
    }

    45.8333333333% {
      transform: translateX(25vw) translateY(-45vh) scale(0.9);
    }

    50% {
      transform: translateX(-11vw) translateY(49vh) scale(0.46);
    }

    54.1666666667% {
      transform: translateX(-45vw) translateY(48vh) scale(0.81);
    }

    58.3333333333% {
      transform: translateX(46vw) translateY(5vh) scale(0.57);
    }

    62.5% {
      transform: translateX(35vw) translateY(-47vh) scale(0.56);
    }

    66.6666666667% {
      transform: translateX(22vw) translateY(-44vh) scale(0.83);
    }

    70.8333333333% {
      transform: translateX(-40vw) translateY(-11vh) scale(0.73);
    }

    75% {
      transform: translateX(-19vw) translateY(13vh) scale(0.69);
    }

    79.1666666667% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.83);
    }

    83.3333333333% {
      transform: translateX(33vw) translateY(21vh) scale(0.67);
    }

    87.5% {
      transform: translateX(-36vw) translateY(27vh) scale(0.79);
    }

    91.6666666667% {
      transform: translateX(5vw) translateY(17vh) scale(0.56);
    }

    95.8333333333% {
      transform: translateX(29vw) translateY(-10vh) scale(0.51);
    }

    100% {
      transform: translateX(-1vw) translateY(-14vh) scale(0.29);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 18s, 6470ms;
    animation-delay: 0ms, 8336ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(40vw) translateY(47vh) scale(1);
    }

    3.5714285714% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.78);
    }

    7.1428571429% {
      transform: translateX(24vw) translateY(17vh) scale(0.28);
    }

    10.7142857143% {
      transform: translateX(41vw) translateY(23vh) scale(0.96);
    }

    14.2857142857% {
      transform: translateX(14vw) translateY(13vh) scale(0.95);
    }

    17.8571428571% {
      transform: translateX(-17vw) translateY(49vh) scale(0.69);
    }

    21.4285714286% {
      transform: translateX(9vw) translateY(20vh) scale(0.29);
    }

    25% {
      transform: translateX(-11vw) translateY(30vh) scale(0.96);
    }

    28.5714285714% {
      transform: translateX(32vw) translateY(-27vh) scale(0.43);
    }

    32.1428571429% {
      transform: translateX(-48vw) translateY(-7vh) scale(0.9);
    }

    35.7142857143% {
      transform: translateX(-19vw) translateY(49vh) scale(0.41);
    }

    39.2857142857% {
      transform: translateX(7vw) translateY(-36vh) scale(0.5);
    }

    42.8571428571% {
      transform: translateX(-3vw) translateY(-40vh) scale(0.78);
    }

    46.4285714286% {
      transform: translateX(47vw) translateY(6vh) scale(0.26);
    }

    50% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.3);
    }

    53.5714285714% {
      transform: translateX(-38vw) translateY(-10vh) scale(0.71);
    }

    57.1428571429% {
      transform: translateX(36vw) translateY(-4vh) scale(0.96);
    }

    60.7142857143% {
      transform: translateX(16vw) translateY(6vh) scale(0.62);
    }

    64.2857142857% {
      transform: translateX(-30vw) translateY(-39vh) scale(0.52);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(33vh) scale(0.45);
    }

    71.4285714286% {
      transform: translateX(-10vw) translateY(-28vh) scale(0.78);
    }

    75% {
      transform: translateX(48vw) translateY(16vh) scale(0.71);
    }

    78.5714285714% {
      transform: translateX(-45vw) translateY(-39vh) scale(0.63);
    }

    82.1428571429% {
      transform: translateX(22vw) translateY(-49vh) scale(0.37);
    }

    85.7142857143% {
      transform: translateX(49vw) translateY(27vh) scale(0.26);
    }

    89.2857142857% {
      transform: translateX(23vw) translateY(-44vh) scale(0.73);
    }

    92.8571428571% {
      transform: translateX(-2vw) translateY(29vh) scale(0.47);
    }

    96.4285714286% {
      transform: translateX(-2vw) translateY(-30vh) scale(0.57);
    }

    100% {
      transform: translateX(-10vw) translateY(-29vh) scale(0.96);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 15s, 6398ms;
    animation-delay: 0ms, 565ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(8vw) translateY(-41vh) scale(0.92);
    }

    4.5454545455% {
      transform: translateX(-26vw) translateY(43vh) scale(0.97);
    }

    9.0909090909% {
      transform: translateX(38vw) translateY(-44vh) scale(0.43);
    }

    13.6363636364% {
      transform: translateX(-35vw) translateY(40vh) scale(0.78);
    }

    18.1818181818% {
      transform: translateX(-7vw) translateY(-35vh) scale(0.68);
    }

    22.7272727273% {
      transform: translateX(-43vw) translateY(-29vh) scale(0.48);
    }

    27.2727272727% {
      transform: translateX(-18vw) translateY(27vh) scale(0.7);
    }

    31.8181818182% {
      transform: translateX(42vw) translateY(-45vh) scale(0.77);
    }

    36.3636363636% {
      transform: translateX(1vw) translateY(-13vh) scale(0.84);
    }

    40.9090909091% {
      transform: translateX(35vw) translateY(-26vh) scale(0.84);
    }

    45.4545454545% {
      transform: translateX(-3vw) translateY(48vh) scale(0.29);
    }

    50% {
      transform: translateX(9vw) translateY(-4vh) scale(0.99);
    }

    54.5454545455% {
      transform: translateX(-36vw) translateY(-36vh) scale(0.5);
    }

    59.0909090909% {
      transform: translateX(42vw) translateY(-44vh) scale(0.39);
    }

    63.6363636364% {
      transform: translateX(26vw) translateY(20vh) scale(0.76);
    }

    68.1818181818% {
      transform: translateX(30vw) translateY(48vh) scale(0.9);
    }

    72.7272727273% {
      transform: translateX(-9vw) translateY(35vh) scale(0.97);
    }

    77.2727272727% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.53);
    }

    81.8181818182% {
      transform: translateX(-49vw) translateY(-29vh) scale(0.26);
    }

    86.3636363636% {
      transform: translateX(-24vw) translateY(36vh) scale(0.36);
    }

    90.9090909091% {
      transform: translateX(-33vw) translateY(20vh) scale(0.52);
    }

    95.4545454545% {
      transform: translateX(14vw) translateY(10vh) scale(0.8);
    }

    100% {
      transform: translateX(14vw) translateY(-30vh) scale(0.54);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 15s, 5044ms;
    animation-delay: 0ms, 1556ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(27vw) translateY(-37vh) scale(0.9);
    }

    4.1666666667% {
      transform: translateX(12vw) translateY(-22vh) scale(0.44);
    }

    8.3333333333% {
      transform: translateX(-13vw) translateY(-28vh) scale(0.53);
    }

    12.5% {
      transform: translateX(-43vw) translateY(18vh) scale(0.39);
    }

    16.6666666667% {
      transform: translateX(34vw) translateY(31vh) scale(0.97);
    }

    20.8333333333% {
      transform: translateX(47vw) translateY(-33vh) scale(0.97);
    }

    25% {
      transform: translateX(35vw) translateY(-47vh) scale(0.42);
    }

    29.1666666667% {
      transform: translateX(-47vw) translateY(39vh) scale(0.4);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-45vh) scale(0.65);
    }

    37.5% {
      transform: translateX(43vw) translateY(-23vh) scale(0.6);
    }

    41.6666666667% {
      transform: translateX(38vw) translateY(15vh) scale(0.63);
    }

    45.8333333333% {
      transform: translateX(49vw) translateY(34vh) scale(0.82);
    }

    50% {
      transform: translateX(-30vw) translateY(-9vh) scale(0.91);
    }

    54.1666666667% {
      transform: translateX(18vw) translateY(-31vh) scale(0.73);
    }

    58.3333333333% {
      transform: translateX(32vw) translateY(-11vh) scale(0.97);
    }

    62.5% {
      transform: translateX(-12vw) translateY(31vh) scale(0.77);
    }

    66.6666666667% {
      transform: translateX(26vw) translateY(12vh) scale(0.84);
    }

    70.8333333333% {
      transform: translateX(2vw) translateY(-46vh) scale(0.38);
    }

    75% {
      transform: translateX(46vw) translateY(35vh) scale(0.79);
    }

    79.1666666667% {
      transform: translateX(50vw) translateY(26vh) scale(0.46);
    }

    83.3333333333% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.48);
    }

    87.5% {
      transform: translateX(6vw) translateY(-18vh) scale(0.78);
    }

    91.6666666667% {
      transform: translateX(33vw) translateY(-31vh) scale(0.75);
    }

    95.8333333333% {
      transform: translateX(27vw) translateY(-13vh) scale(0.52);
    }

    100% {
      transform: translateX(4vw) translateY(11vh) scale(0.86);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 5550ms;
    animation-delay: 0ms, 4125ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-26vw) translateY(-38vh) scale(0.49);
    }

    5.5555555556% {
      transform: translateX(21vw) translateY(7vh) scale(0.8);
    }

    11.1111111111% {
      transform: translateX(-43vw) translateY(-2vh) scale(0.75);
    }

    16.6666666667% {
      transform: translateX(29vw) translateY(-17vh) scale(1);
    }

    22.2222222222% {
      transform: translateX(-43vw) translateY(13vh) scale(0.88);
    }

    27.7777777778% {
      transform: translateX(33vw) translateY(-48vh) scale(0.86);
    }

    33.3333333333% {
      transform: translateX(17vw) translateY(-23vh) scale(0.95);
    }

    38.8888888889% {
      transform: translateX(-7vw) translateY(39vh) scale(0.4);
    }

    44.4444444444% {
      transform: translateX(-44vw) translateY(-35vh) scale(0.39);
    }

    50% {
      transform: translateX(-23vw) translateY(-10vh) scale(0.54);
    }

    55.5555555556% {
      transform: translateX(8vw) translateY(39vh) scale(0.63);
    }

    61.1111111111% {
      transform: translateX(-3vw) translateY(28vh) scale(0.46);
    }

    66.6666666667% {
      transform: translateX(19vw) translateY(-35vh) scale(0.55);
    }

    72.2222222222% {
      transform: translateX(-32vw) translateY(1vh) scale(0.87);
    }

    77.7777777778% {
      transform: translateX(-18vw) translateY(11vh) scale(0.62);
    }

    83.3333333333% {
      transform: translateX(-24vw) translateY(-31vh) scale(0.42);
    }

    88.8888888889% {
      transform: translateX(5vw) translateY(34vh) scale(0.5);
    }

    94.4444444444% {
      transform: translateX(-11vw) translateY(32vh) scale(0.36);
    }

    100% {
      transform: translateX(5vw) translateY(-8vh) scale(0.99);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 16s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 16s, 10297ms;
    animation-delay: 0ms, 4707ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-36vw) translateY(-2vh) scale(0.37);
    }

    4.7619047619% {
      transform: translateX(-27vw) translateY(30vh) scale(0.38);
    }

    9.5238095238% {
      transform: translateX(45vw) translateY(-23vh) scale(0.33);
    }

    14.2857142857% {
      transform: translateX(25vw) translateY(9vh) scale(0.7);
    }

    19.0476190476% {
      transform: translateX(24vw) translateY(-13vh) scale(0.48);
    }

    23.8095238095% {
      transform: translateX(43vw) translateY(-5vh) scale(0.68);
    }

    28.5714285714% {
      transform: translateX(41vw) translateY(2vh) scale(0.83);
    }

    33.3333333333% {
      transform: translateX(-46vw) translateY(-13vh) scale(0.7);
    }

    38.0952380952% {
      transform: translateX(-39vw) translateY(-25vh) scale(0.85);
    }

    42.8571428571% {
      transform: translateX(-27vw) translateY(23vh) scale(0.33);
    }

    47.619047619% {
      transform: translateX(-13vw) translateY(45vh) scale(0.3);
    }

    52.380952381% {
      transform: translateX(10vw) translateY(-28vh) scale(0.55);
    }

    57.1428571429% {
      transform: translateX(-30vw) translateY(-16vh) scale(0.26);
    }

    61.9047619048% {
      transform: translateX(40vw) translateY(30vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(45vw) translateY(37vh) scale(0.79);
    }

    71.4285714286% {
      transform: translateX(-13vw) translateY(-24vh) scale(0.62);
    }

    76.1904761905% {
      transform: translateX(33vw) translateY(-12vh) scale(0.94);
    }

    80.9523809524% {
      transform: translateX(10vw) translateY(-49vh) scale(0.3);
    }

    85.7142857143% {
      transform: translateX(-15vw) translateY(48vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(-42vw) translateY(40vh) scale(0.54);
    }

    95.2380952381% {
      transform: translateX(-22vw) translateY(0vh) scale(0.63);
    }

    100% {
      transform: translateX(32vw) translateY(14vh) scale(0.79);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 9s, 8018ms;
    animation-delay: 0ms, 4673ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(-30vw) translateY(47vh) scale(0.57);
    }

    3.5714285714% {
      transform: translateX(-19vw) translateY(19vh) scale(0.52);
    }

    7.1428571429% {
      transform: translateX(-14vw) translateY(48vh) scale(0.9);
    }

    10.7142857143% {
      transform: translateX(-44vw) translateY(13vh) scale(0.38);
    }

    14.2857142857% {
      transform: translateX(-10vw) translateY(43vh) scale(0.49);
    }

    17.8571428571% {
      transform: translateX(39vw) translateY(49vh) scale(0.79);
    }

    21.4285714286% {
      transform: translateX(50vw) translateY(-17vh) scale(0.4);
    }

    25% {
      transform: translateX(-20vw) translateY(22vh) scale(0.48);
    }

    28.5714285714% {
      transform: translateX(-27vw) translateY(32vh) scale(0.92);
    }

    32.1428571429% {
      transform: translateX(11vw) translateY(-7vh) scale(0.99);
    }

    35.7142857143% {
      transform: translateX(-27vw) translateY(46vh) scale(0.34);
    }

    39.2857142857% {
      transform: translateX(-24vw) translateY(3vh) scale(0.36);
    }

    42.8571428571% {
      transform: translateX(48vw) translateY(-30vh) scale(0.33);
    }

    46.4285714286% {
      transform: translateX(-44vw) translateY(-30vh) scale(0.5);
    }

    50% {
      transform: translateX(44vw) translateY(-2vh) scale(0.57);
    }

    53.5714285714% {
      transform: translateX(-47vw) translateY(-18vh) scale(0.39);
    }

    57.1428571429% {
      transform: translateX(45vw) translateY(24vh) scale(0.54);
    }

    60.7142857143% {
      transform: translateX(-32vw) translateY(14vh) scale(0.64);
    }

    64.2857142857% {
      transform: translateX(-24vw) translateY(37vh) scale(0.81);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(28vh) scale(0.67);
    }

    71.4285714286% {
      transform: translateX(11vw) translateY(-14vh) scale(0.71);
    }

    75% {
      transform: translateX(-28vw) translateY(-6vh) scale(0.47);
    }

    78.5714285714% {
      transform: translateX(15vw) translateY(-15vh) scale(0.79);
    }

    82.1428571429% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.51);
    }

    85.7142857143% {
      transform: translateX(43vw) translateY(47vh) scale(0.52);
    }

    89.2857142857% {
      transform: translateX(35vw) translateY(-38vh) scale(0.62);
    }

    92.8571428571% {
      transform: translateX(22vw) translateY(-44vh) scale(1);
    }

    96.4285714286% {
      transform: translateX(5vw) translateY(31vh) scale(0.33);
    }

    100% {
      transform: translateX(3vw) translateY(39vh) scale(0.63);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 10s, 9679ms;
    animation-delay: 0ms, 8051ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(22vw) translateY(-45vh) scale(0.86);
    }

    4.1666666667% {
      transform: translateX(20vw) translateY(6vh) scale(0.73);
    }

    8.3333333333% {
      transform: translateX(-36vw) translateY(15vh) scale(0.72);
    }

    12.5% {
      transform: translateX(25vw) translateY(-1vh) scale(0.38);
    }

    16.6666666667% {
      transform: translateX(-19vw) translateY(-28vh) scale(0.91);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(29vh) scale(0.47);
    }

    25% {
      transform: translateX(0vw) translateY(14vh) scale(0.55);
    }

    29.1666666667% {
      transform: translateX(-41vw) translateY(-28vh) scale(0.39);
    }

    33.3333333333% {
      transform: translateX(-11vw) translateY(34vh) scale(0.37);
    }

    37.5% {
      transform: translateX(-10vw) translateY(38vh) scale(0.75);
    }

    41.6666666667% {
      transform: translateX(-3vw) translateY(7vh) scale(0.47);
    }

    45.8333333333% {
      transform: translateX(22vw) translateY(-11vh) scale(0.97);
    }

    50% {
      transform: translateX(41vw) translateY(20vh) scale(0.37);
    }

    54.1666666667% {
      transform: translateX(23vw) translateY(-14vh) scale(0.41);
    }

    58.3333333333% {
      transform: translateX(43vw) translateY(-30vh) scale(0.34);
    }

    62.5% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(-21vw) translateY(-11vh) scale(0.42);
    }

    70.8333333333% {
      transform: translateX(47vw) translateY(11vh) scale(0.29);
    }

    75% {
      transform: translateX(20vw) translateY(-27vh) scale(0.4);
    }

    79.1666666667% {
      transform: translateX(-47vw) translateY(37vh) scale(0.52);
    }

    83.3333333333% {
      transform: translateX(0vw) translateY(3vh) scale(0.44);
    }

    87.5% {
      transform: translateX(27vw) translateY(47vh) scale(0.88);
    }

    91.6666666667% {
      transform: translateX(-5vw) translateY(3vh) scale(0.82);
    }

    95.8333333333% {
      transform: translateX(16vw) translateY(10vh) scale(0.61);
    }

    100% {
      transform: translateX(5vw) translateY(32vh) scale(0.33);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 18s, 8910ms;
    animation-delay: 0ms, 3832ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(21vw) translateY(3vh) scale(0.42);
    }

    4.347826087% {
      transform: translateX(-30vw) translateY(-40vh) scale(0.96);
    }

    8.6956521739% {
      transform: translateX(-26vw) translateY(-40vh) scale(0.82);
    }

    13.0434782609% {
      transform: translateX(-19vw) translateY(-2vh) scale(0.72);
    }

    17.3913043478% {
      transform: translateX(-49vw) translateY(36vh) scale(0.31);
    }

    21.7391304348% {
      transform: translateX(4vw) translateY(-6vh) scale(0.52);
    }

    26.0869565217% {
      transform: translateX(-16vw) translateY(40vh) scale(0.96);
    }

    30.4347826087% {
      transform: translateX(15vw) translateY(15vh) scale(0.9);
    }

    34.7826086957% {
      transform: translateX(26vw) translateY(-6vh) scale(0.28);
    }

    39.1304347826% {
      transform: translateX(9vw) translateY(46vh) scale(0.35);
    }

    43.4782608696% {
      transform: translateX(-25vw) translateY(19vh) scale(0.79);
    }

    47.8260869565% {
      transform: translateX(-33vw) translateY(46vh) scale(0.74);
    }

    52.1739130435% {
      transform: translateX(-22vw) translateY(1vh) scale(0.36);
    }

    56.5217391304% {
      transform: translateX(-49vw) translateY(9vh) scale(0.52);
    }

    60.8695652174% {
      transform: translateX(-29vw) translateY(39vh) scale(0.7);
    }

    65.2173913043% {
      transform: translateX(25vw) translateY(31vh) scale(0.34);
    }

    69.5652173913% {
      transform: translateX(19vw) translateY(18vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(-25vw) translateY(-25vh) scale(0.71);
    }

    78.2608695652% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.77);
    }

    82.6086956522% {
      transform: translateX(31vw) translateY(18vh) scale(0.41);
    }

    86.9565217391% {
      transform: translateX(41vw) translateY(-27vh) scale(0.5);
    }

    91.3043478261% {
      transform: translateX(37vw) translateY(12vh) scale(0.78);
    }

    95.652173913% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.68);
    }

    100% {
      transform: translateX(31vw) translateY(30vh) scale(0.96);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 17s, 8620ms;
    animation-delay: 0ms, 742ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(30vw) translateY(29vh) scale(0.57);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(-47vh) scale(0.3);
    }

    9.5238095238% {
      transform: translateX(33vw) translateY(46vh) scale(0.27);
    }

    14.2857142857% {
      transform: translateX(46vw) translateY(7vh) scale(0.82);
    }

    19.0476190476% {
      transform: translateX(-25vw) translateY(41vh) scale(0.87);
    }

    23.8095238095% {
      transform: translateX(-46vw) translateY(22vh) scale(0.99);
    }

    28.5714285714% {
      transform: translateX(4vw) translateY(11vh) scale(0.59);
    }

    33.3333333333% {
      transform: translateX(-43vw) translateY(20vh) scale(0.97);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(-18vh) scale(0.79);
    }

    42.8571428571% {
      transform: translateX(18vw) translateY(-7vh) scale(0.4);
    }

    47.619047619% {
      transform: translateX(-10vw) translateY(-36vh) scale(1);
    }

    52.380952381% {
      transform: translateX(-4vw) translateY(28vh) scale(0.8);
    }

    57.1428571429% {
      transform: translateX(-43vw) translateY(50vh) scale(0.99);
    }

    61.9047619048% {
      transform: translateX(30vw) translateY(-40vh) scale(0.99);
    }

    66.6666666667% {
      transform: translateX(48vw) translateY(-7vh) scale(0.73);
    }

    71.4285714286% {
      transform: translateX(18vw) translateY(-19vh) scale(0.47);
    }

    76.1904761905% {
      transform: translateX(-14vw) translateY(-35vh) scale(0.71);
    }

    80.9523809524% {
      transform: translateX(34vw) translateY(17vh) scale(0.97);
    }

    85.7142857143% {
      transform: translateX(48vw) translateY(-29vh) scale(0.88);
    }

    90.4761904762% {
      transform: translateX(44vw) translateY(46vh) scale(0.99);
    }

    95.2380952381% {
      transform: translateX(22vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(-3vw) translateY(-48vh) scale(0.54);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw #FFF01F;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw #FFF01F;
    }
  }

  .projectName {
    width: 50%;
  }

  .beastDiv {}

  .beast {
    width: 500px;
  }

  .fire {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0px;
  }

  .fire4 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire4 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -130px;
    top: 0px;
  }

  .fire5 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire5 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    top: 0px;
  }

  .fire6 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire6 {
    width: 75px;
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .fire2 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire2 {
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire2 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 {
    width: 60px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }


  .flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
  }

  .flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  .flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
  }

  .flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
  }

  .flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
  }

  .flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
  }

  .flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
  }

  @keyframes flameWobble {
    50% {
      transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
  }

  @keyframes flamefly {
    0% {
      transform: translate(0) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(-20px, -100px) rotate(180deg);
      opacity: 0;
    }
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 22px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 80px;
    color: black;
    text-shadow: 0 0 5px black;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {}

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 35px;
    color: black;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  @keyframes titleFont {
    0% {
      opacity: 1;
    }

    34% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    64% {
      opacity: 0;
    }

    65% {
      opacity: 1;
    }

    84% {
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleFont2 {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    70% {
      opacity: 1
    }

    71% {
      opacity: 0
    }

    85% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .intro {
    color: black;
    font-size: 80px;
    position: relative;
    margin-bottom: 25%;
  }

  .name1 {
    position: absolute;
    animation-name: titleFont;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .name2 {
    position: absolute;
    font-family: 'Underdog', sans-serif;
    animation-name: titleFont2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .shakeWord {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .name3 {
    position: absolute;
    animation-name: titleFont3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 0.5%;
  }

  .intro2 {
    color: black;
    font-size: 27px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -25%;
  }

  .mintDiv {
    width: 40%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid black;
    box-shadow: black -5px 5px;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 27px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .discord:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black -5px 5px;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black 5px 5px;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #fef8e2;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    box-shadow: black 0px 5px;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 174, 0);
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: black;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(37, 59, 23);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2 {
    background-color: #fef8e2;
    color: black;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid black;
    transition: transform .2s;
    box-shadow: black 0px 3px;
    border-radius: 10px;
    font-weight: bold;
  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background-image: linear-gradient(144deg, #a08fa1, #9895a4 50%, #9696a5);
    border: 0;
    box-shadow: black -5px 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    border-radius: 10px;
    transform: rotate(-3deg);
    border: 2px solid black;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #585858;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #2c2c2c;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }

  .mainImage {
    position: relative;
    bottom: 0;

  }

  .beast1 {
    width: 480px;
    bottom: 0;
  }

  .beast2 {
    width: 480px;
    bottom: 0;
    position: relative;
    animation: mainImageAnimation ease 2s infinite;

  }

  @keyframes mainImageAnimation {
    0% {
      opacity: 0;
    }

    49% {

      opacity: 0;

    }

    50% {

      opacity: 1;

    }

    100% {
      opacity: 1;
    }
  }


  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 14s, 5348ms;
    animation-delay: 0ms, 3421ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(44vh) scale(0.62);
    }

    4% {
      transform: translateX(-4vw) translateY(50vh) scale(0.77);
    }

    8% {
      transform: translateX(43vw) translateY(-35vh) scale(0.85);
    }

    12% {
      transform: translateX(6vw) translateY(-16vh) scale(0.32);
    }

    16% {
      transform: translateX(40vw) translateY(-28vh) scale(0.61);
    }

    20% {
      transform: translateX(16vw) translateY(-33vh) scale(0.62);
    }

    24% {
      transform: translateX(-13vw) translateY(12vh) scale(0.99);
    }

    28% {
      transform: translateX(-6vw) translateY(15vh) scale(0.52);
    }

    32% {
      transform: translateX(39vw) translateY(40vh) scale(0.42);
    }

    36% {
      transform: translateX(-19vw) translateY(-31vh) scale(0.72);
    }

    40% {
      transform: translateX(47vw) translateY(18vh) scale(0.74);
    }

    44% {
      transform: translateX(-24vw) translateY(1vh) scale(0.8);
    }

    48% {
      transform: translateX(36vw) translateY(-6vh) scale(0.65);
    }

    52% {
      transform: translateX(-6vw) translateY(11vh) scale(0.28);
    }

    56% {
      transform: translateX(34vw) translateY(35vh) scale(0.79);
    }

    60% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.48);
    }

    64% {
      transform: translateX(33vw) translateY(-1vh) scale(0.47);
    }

    68% {
      transform: translateX(-45vw) translateY(-41vh) scale(0.54);
    }

    72% {
      transform: translateX(-20vw) translateY(40vh) scale(0.92);
    }

    76% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.64);
    }

    80% {
      transform: translateX(-23vw) translateY(-18vh) scale(0.92);
    }

    84% {
      transform: translateX(-37vw) translateY(7vh) scale(0.98);
    }

    88% {
      transform: translateX(-31vw) translateY(-35vh) scale(0.92);
    }

    92% {
      transform: translateX(1vw) translateY(-43vh) scale(0.29);
    }

    96% {
      transform: translateX(-41vw) translateY(-20vh) scale(0.44);
    }

    100% {
      transform: translateX(-4vw) translateY(-33vh) scale(0.38);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 11s, 6289ms;
    animation-delay: 0ms, 6547ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-17vw) translateY(40vh) scale(0.32);
    }

    3.5714285714% {
      transform: translateX(9vw) translateY(34vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-21vw) translateY(-5vh) scale(0.64);
    }

    10.7142857143% {
      transform: translateX(47vw) translateY(6vh) scale(0.76);
    }

    14.2857142857% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.93);
    }

    17.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.36);
    }

    21.4285714286% {
      transform: translateX(-34vw) translateY(38vh) scale(0.46);
    }

    25% {
      transform: translateX(-23vw) translateY(-42vh) scale(0.58);
    }

    28.5714285714% {
      transform: translateX(6vw) translateY(-31vh) scale(0.93);
    }

    32.1428571429% {
      transform: translateX(-37vw) translateY(31vh) scale(0.47);
    }

    35.7142857143% {
      transform: translateX(49vw) translateY(-37vh) scale(0.58);
    }

    39.2857142857% {
      transform: translateX(13vw) translateY(-42vh) scale(0.86);
    }

    42.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.48);
    }

    46.4285714286% {
      transform: translateX(-13vw) translateY(-13vh) scale(0.85);
    }

    50% {
      transform: translateX(7vw) translateY(49vh) scale(0.39);
    }

    53.5714285714% {
      transform: translateX(44vw) translateY(-15vh) scale(0.9);
    }

    57.1428571429% {
      transform: translateX(-2vw) translateY(-20vh) scale(0.27);
    }

    60.7142857143% {
      transform: translateX(-3vw) translateY(34vh) scale(0.51);
    }

    64.2857142857% {
      transform: translateX(-40vw) translateY(-5vh) scale(0.39);
    }

    67.8571428571% {
      transform: translateX(1vw) translateY(39vh) scale(0.53);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(-12vh) scale(0.45);
    }

    75% {
      transform: translateX(-17vw) translateY(-36vh) scale(0.63);
    }

    78.5714285714% {
      transform: translateX(27vw) translateY(36vh) scale(0.92);
    }

    82.1428571429% {
      transform: translateX(18vw) translateY(-5vh) scale(0.8);
    }

    85.7142857143% {
      transform: translateX(40vw) translateY(13vh) scale(0.92);
    }

    89.2857142857% {
      transform: translateX(3vw) translateY(-4vh) scale(0.33);
    }

    92.8571428571% {
      transform: translateX(-31vw) translateY(43vh) scale(0.71);
    }

    96.4285714286% {
      transform: translateX(-42vw) translateY(-18vh) scale(0.64);
    }

    100% {
      transform: translateX(24vw) translateY(14vh) scale(0.67);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 9s, 7991ms;
    animation-delay: 0ms, 1922ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-22vw) translateY(24vh) scale(0.62);
    }

    3.5714285714% {
      transform: translateX(-44vw) translateY(37vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-47vw) translateY(46vh) scale(0.91);
    }

    10.7142857143% {
      transform: translateX(39vw) translateY(15vh) scale(0.67);
    }

    14.2857142857% {
      transform: translateX(7vw) translateY(44vh) scale(0.73);
    }

    17.8571428571% {
      transform: translateX(40vw) translateY(11vh) scale(0.56);
    }

    21.4285714286% {
      transform: translateX(23vw) translateY(-30vh) scale(0.98);
    }

    25% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.4);
    }

    28.5714285714% {
      transform: translateX(31vw) translateY(30vh) scale(0.78);
    }

    32.1428571429% {
      transform: translateX(-10vw) translateY(-22vh) scale(0.7);
    }

    35.7142857143% {
      transform: translateX(-31vw) translateY(2vh) scale(0.87);
    }

    39.2857142857% {
      transform: translateX(45vw) translateY(-3vh) scale(0.33);
    }

    42.8571428571% {
      transform: translateX(-7vw) translateY(-30vh) scale(0.88);
    }

    46.4285714286% {
      transform: translateX(8vw) translateY(22vh) scale(0.51);
    }

    50% {
      transform: translateX(31vw) translateY(25vh) scale(0.31);
    }

    53.5714285714% {
      transform: translateX(47vw) translateY(13vh) scale(0.76);
    }

    57.1428571429% {
      transform: translateX(-19vw) translateY(7vh) scale(0.36);
    }

    60.7142857143% {
      transform: translateX(11vw) translateY(6vh) scale(0.83);
    }

    64.2857142857% {
      transform: translateX(48vw) translateY(-38vh) scale(0.62);
    }

    67.8571428571% {
      transform: translateX(41vw) translateY(-18vh) scale(0.68);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(50vh) scale(0.34);
    }

    75% {
      transform: translateX(-31vw) translateY(-15vh) scale(0.51);
    }

    78.5714285714% {
      transform: translateX(-41vw) translateY(9vh) scale(0.91);
    }

    82.1428571429% {
      transform: translateX(-39vw) translateY(16vh) scale(0.45);
    }

    85.7142857143% {
      transform: translateX(-44vw) translateY(9vh) scale(0.83);
    }

    89.2857142857% {
      transform: translateX(-41vw) translateY(8vh) scale(0.66);
    }

    92.8571428571% {
      transform: translateX(-44vw) translateY(42vh) scale(0.56);
    }

    96.4285714286% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.86);
    }

    100% {
      transform: translateX(-4vw) translateY(-8vh) scale(0.42);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 17s, 5968ms;
    animation-delay: 0ms, 1513ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(45vw) translateY(13vh) scale(0.5);
    }

    5.2631578947% {
      transform: translateX(37vw) translateY(14vh) scale(0.98);
    }

    10.5263157895% {
      transform: translateX(-6vw) translateY(-16vh) scale(0.7);
    }

    15.7894736842% {
      transform: translateX(-24vw) translateY(-47vh) scale(0.29);
    }

    21.0526315789% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.63);
    }

    26.3157894737% {
      transform: translateX(-11vw) translateY(-16vh) scale(0.49);
    }

    31.5789473684% {
      transform: translateX(-11vw) translateY(7vh) scale(0.45);
    }

    36.8421052632% {
      transform: translateX(13vw) translateY(-6vh) scale(0.34);
    }

    42.1052631579% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.37);
    }

    47.3684210526% {
      transform: translateX(5vw) translateY(25vh) scale(0.53);
    }

    52.6315789474% {
      transform: translateX(27vw) translateY(14vh) scale(0.74);
    }

    57.8947368421% {
      transform: translateX(-26vw) translateY(-28vh) scale(0.64);
    }

    63.1578947368% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.46);
    }

    68.4210526316% {
      transform: translateX(-23vw) translateY(17vh) scale(0.37);
    }

    73.6842105263% {
      transform: translateX(9vw) translateY(-6vh) scale(0.69);
    }

    78.9473684211% {
      transform: translateX(-41vw) translateY(-42vh) scale(0.75);
    }

    84.2105263158% {
      transform: translateX(34vw) translateY(1vh) scale(0.86);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-26vh) scale(0.91);
    }

    94.7368421053% {
      transform: translateX(-26vw) translateY(13vh) scale(0.49);
    }

    100% {
      transform: translateX(1vw) translateY(-1vh) scale(0.76);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 13s, 9409ms;
    animation-delay: 0ms, 3495ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(34vw) translateY(18vh) scale(0.64);
    }

    4.1666666667% {
      transform: translateX(34vw) translateY(11vh) scale(0.39);
    }

    8.3333333333% {
      transform: translateX(-37vw) translateY(-43vh) scale(0.47);
    }

    12.5% {
      transform: translateX(17vw) translateY(-5vh) scale(0.49);
    }

    16.6666666667% {
      transform: translateX(30vw) translateY(7vh) scale(0.47);
    }

    20.8333333333% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.76);
    }

    25% {
      transform: translateX(-20vw) translateY(28vh) scale(0.7);
    }

    29.1666666667% {
      transform: translateX(-16vw) translateY(-5vh) scale(0.84);
    }

    33.3333333333% {
      transform: translateX(-17vw) translateY(-41vh) scale(0.42);
    }

    37.5% {
      transform: translateX(19vw) translateY(-20vh) scale(0.81);
    }

    41.6666666667% {
      transform: translateX(12vw) translateY(15vh) scale(0.75);
    }

    45.8333333333% {
      transform: translateX(30vw) translateY(-32vh) scale(0.47);
    }

    50% {
      transform: translateX(-40vw) translateY(-38vh) scale(0.59);
    }

    54.1666666667% {
      transform: translateX(4vw) translateY(44vh) scale(0.82);
    }

    58.3333333333% {
      transform: translateX(44vw) translateY(-14vh) scale(0.55);
    }

    62.5% {
      transform: translateX(-15vw) translateY(39vh) scale(0.33);
    }

    66.6666666667% {
      transform: translateX(-28vw) translateY(-30vh) scale(0.29);
    }

    70.8333333333% {
      transform: translateX(40vw) translateY(27vh) scale(0.61);
    }

    75% {
      transform: translateX(22vw) translateY(-21vh) scale(0.53);
    }

    79.1666666667% {
      transform: translateX(-49vw) translateY(21vh) scale(0.64);
    }

    83.3333333333% {
      transform: translateX(7vw) translateY(14vh) scale(0.81);
    }

    87.5% {
      transform: translateX(12vw) translateY(14vh) scale(0.8);
    }

    91.6666666667% {
      transform: translateX(-44vw) translateY(-42vh) scale(0.49);
    }

    95.8333333333% {
      transform: translateX(43vw) translateY(41vh) scale(0.6);
    }

    100% {
      transform: translateX(6vw) translateY(-29vh) scale(0.7);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 10229ms;
    animation-delay: 0ms, 7081ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(23vw) translateY(18vh) scale(0.76);
    }

    4.1666666667% {
      transform: translateX(-26vw) translateY(6vh) scale(0.41);
    }

    8.3333333333% {
      transform: translateX(-1vw) translateY(-18vh) scale(0.63);
    }

    12.5% {
      transform: translateX(22vw) translateY(43vh) scale(0.63);
    }

    16.6666666667% {
      transform: translateX(25vw) translateY(48vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(3vw) translateY(-29vh) scale(0.33);
    }

    25% {
      transform: translateX(26vw) translateY(0vh) scale(0.86);
    }

    29.1666666667% {
      transform: translateX(-33vw) translateY(5vh) scale(0.51);
    }

    33.3333333333% {
      transform: translateX(4vw) translateY(-9vh) scale(0.39);
    }

    37.5% {
      transform: translateX(26vw) translateY(12vh) scale(0.65);
    }

    41.6666666667% {
      transform: translateX(31vw) translateY(18vh) scale(0.42);
    }

    45.8333333333% {
      transform: translateX(25vw) translateY(-45vh) scale(0.9);
    }

    50% {
      transform: translateX(-11vw) translateY(49vh) scale(0.46);
    }

    54.1666666667% {
      transform: translateX(-45vw) translateY(48vh) scale(0.81);
    }

    58.3333333333% {
      transform: translateX(46vw) translateY(5vh) scale(0.57);
    }

    62.5% {
      transform: translateX(35vw) translateY(-47vh) scale(0.56);
    }

    66.6666666667% {
      transform: translateX(22vw) translateY(-44vh) scale(0.83);
    }

    70.8333333333% {
      transform: translateX(-40vw) translateY(-11vh) scale(0.73);
    }

    75% {
      transform: translateX(-19vw) translateY(13vh) scale(0.69);
    }

    79.1666666667% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.83);
    }

    83.3333333333% {
      transform: translateX(33vw) translateY(21vh) scale(0.67);
    }

    87.5% {
      transform: translateX(-36vw) translateY(27vh) scale(0.79);
    }

    91.6666666667% {
      transform: translateX(5vw) translateY(17vh) scale(0.56);
    }

    95.8333333333% {
      transform: translateX(29vw) translateY(-10vh) scale(0.51);
    }

    100% {
      transform: translateX(-1vw) translateY(-14vh) scale(0.29);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 18s, 6470ms;
    animation-delay: 0ms, 8336ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(40vw) translateY(47vh) scale(1);
    }

    3.5714285714% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.78);
    }

    7.1428571429% {
      transform: translateX(24vw) translateY(17vh) scale(0.28);
    }

    10.7142857143% {
      transform: translateX(41vw) translateY(23vh) scale(0.96);
    }

    14.2857142857% {
      transform: translateX(14vw) translateY(13vh) scale(0.95);
    }

    17.8571428571% {
      transform: translateX(-17vw) translateY(49vh) scale(0.69);
    }

    21.4285714286% {
      transform: translateX(9vw) translateY(20vh) scale(0.29);
    }

    25% {
      transform: translateX(-11vw) translateY(30vh) scale(0.96);
    }

    28.5714285714% {
      transform: translateX(32vw) translateY(-27vh) scale(0.43);
    }

    32.1428571429% {
      transform: translateX(-48vw) translateY(-7vh) scale(0.9);
    }

    35.7142857143% {
      transform: translateX(-19vw) translateY(49vh) scale(0.41);
    }

    39.2857142857% {
      transform: translateX(7vw) translateY(-36vh) scale(0.5);
    }

    42.8571428571% {
      transform: translateX(-3vw) translateY(-40vh) scale(0.78);
    }

    46.4285714286% {
      transform: translateX(47vw) translateY(6vh) scale(0.26);
    }

    50% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.3);
    }

    53.5714285714% {
      transform: translateX(-38vw) translateY(-10vh) scale(0.71);
    }

    57.1428571429% {
      transform: translateX(36vw) translateY(-4vh) scale(0.96);
    }

    60.7142857143% {
      transform: translateX(16vw) translateY(6vh) scale(0.62);
    }

    64.2857142857% {
      transform: translateX(-30vw) translateY(-39vh) scale(0.52);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(33vh) scale(0.45);
    }

    71.4285714286% {
      transform: translateX(-10vw) translateY(-28vh) scale(0.78);
    }

    75% {
      transform: translateX(48vw) translateY(16vh) scale(0.71);
    }

    78.5714285714% {
      transform: translateX(-45vw) translateY(-39vh) scale(0.63);
    }

    82.1428571429% {
      transform: translateX(22vw) translateY(-49vh) scale(0.37);
    }

    85.7142857143% {
      transform: translateX(49vw) translateY(27vh) scale(0.26);
    }

    89.2857142857% {
      transform: translateX(23vw) translateY(-44vh) scale(0.73);
    }

    92.8571428571% {
      transform: translateX(-2vw) translateY(29vh) scale(0.47);
    }

    96.4285714286% {
      transform: translateX(-2vw) translateY(-30vh) scale(0.57);
    }

    100% {
      transform: translateX(-10vw) translateY(-29vh) scale(0.96);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 15s, 6398ms;
    animation-delay: 0ms, 565ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(8vw) translateY(-41vh) scale(0.92);
    }

    4.5454545455% {
      transform: translateX(-26vw) translateY(43vh) scale(0.97);
    }

    9.0909090909% {
      transform: translateX(38vw) translateY(-44vh) scale(0.43);
    }

    13.6363636364% {
      transform: translateX(-35vw) translateY(40vh) scale(0.78);
    }

    18.1818181818% {
      transform: translateX(-7vw) translateY(-35vh) scale(0.68);
    }

    22.7272727273% {
      transform: translateX(-43vw) translateY(-29vh) scale(0.48);
    }

    27.2727272727% {
      transform: translateX(-18vw) translateY(27vh) scale(0.7);
    }

    31.8181818182% {
      transform: translateX(42vw) translateY(-45vh) scale(0.77);
    }

    36.3636363636% {
      transform: translateX(1vw) translateY(-13vh) scale(0.84);
    }

    40.9090909091% {
      transform: translateX(35vw) translateY(-26vh) scale(0.84);
    }

    45.4545454545% {
      transform: translateX(-3vw) translateY(48vh) scale(0.29);
    }

    50% {
      transform: translateX(9vw) translateY(-4vh) scale(0.99);
    }

    54.5454545455% {
      transform: translateX(-36vw) translateY(-36vh) scale(0.5);
    }

    59.0909090909% {
      transform: translateX(42vw) translateY(-44vh) scale(0.39);
    }

    63.6363636364% {
      transform: translateX(26vw) translateY(20vh) scale(0.76);
    }

    68.1818181818% {
      transform: translateX(30vw) translateY(48vh) scale(0.9);
    }

    72.7272727273% {
      transform: translateX(-9vw) translateY(35vh) scale(0.97);
    }

    77.2727272727% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.53);
    }

    81.8181818182% {
      transform: translateX(-49vw) translateY(-29vh) scale(0.26);
    }

    86.3636363636% {
      transform: translateX(-24vw) translateY(36vh) scale(0.36);
    }

    90.9090909091% {
      transform: translateX(-33vw) translateY(20vh) scale(0.52);
    }

    95.4545454545% {
      transform: translateX(14vw) translateY(10vh) scale(0.8);
    }

    100% {
      transform: translateX(14vw) translateY(-30vh) scale(0.54);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 15s, 5044ms;
    animation-delay: 0ms, 1556ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(27vw) translateY(-37vh) scale(0.9);
    }

    4.1666666667% {
      transform: translateX(12vw) translateY(-22vh) scale(0.44);
    }

    8.3333333333% {
      transform: translateX(-13vw) translateY(-28vh) scale(0.53);
    }

    12.5% {
      transform: translateX(-43vw) translateY(18vh) scale(0.39);
    }

    16.6666666667% {
      transform: translateX(34vw) translateY(31vh) scale(0.97);
    }

    20.8333333333% {
      transform: translateX(47vw) translateY(-33vh) scale(0.97);
    }

    25% {
      transform: translateX(35vw) translateY(-47vh) scale(0.42);
    }

    29.1666666667% {
      transform: translateX(-47vw) translateY(39vh) scale(0.4);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-45vh) scale(0.65);
    }

    37.5% {
      transform: translateX(43vw) translateY(-23vh) scale(0.6);
    }

    41.6666666667% {
      transform: translateX(38vw) translateY(15vh) scale(0.63);
    }

    45.8333333333% {
      transform: translateX(49vw) translateY(34vh) scale(0.82);
    }

    50% {
      transform: translateX(-30vw) translateY(-9vh) scale(0.91);
    }

    54.1666666667% {
      transform: translateX(18vw) translateY(-31vh) scale(0.73);
    }

    58.3333333333% {
      transform: translateX(32vw) translateY(-11vh) scale(0.97);
    }

    62.5% {
      transform: translateX(-12vw) translateY(31vh) scale(0.77);
    }

    66.6666666667% {
      transform: translateX(26vw) translateY(12vh) scale(0.84);
    }

    70.8333333333% {
      transform: translateX(2vw) translateY(-46vh) scale(0.38);
    }

    75% {
      transform: translateX(46vw) translateY(35vh) scale(0.79);
    }

    79.1666666667% {
      transform: translateX(50vw) translateY(26vh) scale(0.46);
    }

    83.3333333333% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.48);
    }

    87.5% {
      transform: translateX(6vw) translateY(-18vh) scale(0.78);
    }

    91.6666666667% {
      transform: translateX(33vw) translateY(-31vh) scale(0.75);
    }

    95.8333333333% {
      transform: translateX(27vw) translateY(-13vh) scale(0.52);
    }

    100% {
      transform: translateX(4vw) translateY(11vh) scale(0.86);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 5550ms;
    animation-delay: 0ms, 4125ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-26vw) translateY(-38vh) scale(0.49);
    }

    5.5555555556% {
      transform: translateX(21vw) translateY(7vh) scale(0.8);
    }

    11.1111111111% {
      transform: translateX(-43vw) translateY(-2vh) scale(0.75);
    }

    16.6666666667% {
      transform: translateX(29vw) translateY(-17vh) scale(1);
    }

    22.2222222222% {
      transform: translateX(-43vw) translateY(13vh) scale(0.88);
    }

    27.7777777778% {
      transform: translateX(33vw) translateY(-48vh) scale(0.86);
    }

    33.3333333333% {
      transform: translateX(17vw) translateY(-23vh) scale(0.95);
    }

    38.8888888889% {
      transform: translateX(-7vw) translateY(39vh) scale(0.4);
    }

    44.4444444444% {
      transform: translateX(-44vw) translateY(-35vh) scale(0.39);
    }

    50% {
      transform: translateX(-23vw) translateY(-10vh) scale(0.54);
    }

    55.5555555556% {
      transform: translateX(8vw) translateY(39vh) scale(0.63);
    }

    61.1111111111% {
      transform: translateX(-3vw) translateY(28vh) scale(0.46);
    }

    66.6666666667% {
      transform: translateX(19vw) translateY(-35vh) scale(0.55);
    }

    72.2222222222% {
      transform: translateX(-32vw) translateY(1vh) scale(0.87);
    }

    77.7777777778% {
      transform: translateX(-18vw) translateY(11vh) scale(0.62);
    }

    83.3333333333% {
      transform: translateX(-24vw) translateY(-31vh) scale(0.42);
    }

    88.8888888889% {
      transform: translateX(5vw) translateY(34vh) scale(0.5);
    }

    94.4444444444% {
      transform: translateX(-11vw) translateY(32vh) scale(0.36);
    }

    100% {
      transform: translateX(5vw) translateY(-8vh) scale(0.99);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 16s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 16s, 10297ms;
    animation-delay: 0ms, 4707ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-36vw) translateY(-2vh) scale(0.37);
    }

    4.7619047619% {
      transform: translateX(-27vw) translateY(30vh) scale(0.38);
    }

    9.5238095238% {
      transform: translateX(45vw) translateY(-23vh) scale(0.33);
    }

    14.2857142857% {
      transform: translateX(25vw) translateY(9vh) scale(0.7);
    }

    19.0476190476% {
      transform: translateX(24vw) translateY(-13vh) scale(0.48);
    }

    23.8095238095% {
      transform: translateX(43vw) translateY(-5vh) scale(0.68);
    }

    28.5714285714% {
      transform: translateX(41vw) translateY(2vh) scale(0.83);
    }

    33.3333333333% {
      transform: translateX(-46vw) translateY(-13vh) scale(0.7);
    }

    38.0952380952% {
      transform: translateX(-39vw) translateY(-25vh) scale(0.85);
    }

    42.8571428571% {
      transform: translateX(-27vw) translateY(23vh) scale(0.33);
    }

    47.619047619% {
      transform: translateX(-13vw) translateY(45vh) scale(0.3);
    }

    52.380952381% {
      transform: translateX(10vw) translateY(-28vh) scale(0.55);
    }

    57.1428571429% {
      transform: translateX(-30vw) translateY(-16vh) scale(0.26);
    }

    61.9047619048% {
      transform: translateX(40vw) translateY(30vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(45vw) translateY(37vh) scale(0.79);
    }

    71.4285714286% {
      transform: translateX(-13vw) translateY(-24vh) scale(0.62);
    }

    76.1904761905% {
      transform: translateX(33vw) translateY(-12vh) scale(0.94);
    }

    80.9523809524% {
      transform: translateX(10vw) translateY(-49vh) scale(0.3);
    }

    85.7142857143% {
      transform: translateX(-15vw) translateY(48vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(-42vw) translateY(40vh) scale(0.54);
    }

    95.2380952381% {
      transform: translateX(-22vw) translateY(0vh) scale(0.63);
    }

    100% {
      transform: translateX(32vw) translateY(14vh) scale(0.79);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 9s, 8018ms;
    animation-delay: 0ms, 4673ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(-30vw) translateY(47vh) scale(0.57);
    }

    3.5714285714% {
      transform: translateX(-19vw) translateY(19vh) scale(0.52);
    }

    7.1428571429% {
      transform: translateX(-14vw) translateY(48vh) scale(0.9);
    }

    10.7142857143% {
      transform: translateX(-44vw) translateY(13vh) scale(0.38);
    }

    14.2857142857% {
      transform: translateX(-10vw) translateY(43vh) scale(0.49);
    }

    17.8571428571% {
      transform: translateX(39vw) translateY(49vh) scale(0.79);
    }

    21.4285714286% {
      transform: translateX(50vw) translateY(-17vh) scale(0.4);
    }

    25% {
      transform: translateX(-20vw) translateY(22vh) scale(0.48);
    }

    28.5714285714% {
      transform: translateX(-27vw) translateY(32vh) scale(0.92);
    }

    32.1428571429% {
      transform: translateX(11vw) translateY(-7vh) scale(0.99);
    }

    35.7142857143% {
      transform: translateX(-27vw) translateY(46vh) scale(0.34);
    }

    39.2857142857% {
      transform: translateX(-24vw) translateY(3vh) scale(0.36);
    }

    42.8571428571% {
      transform: translateX(48vw) translateY(-30vh) scale(0.33);
    }

    46.4285714286% {
      transform: translateX(-44vw) translateY(-30vh) scale(0.5);
    }

    50% {
      transform: translateX(44vw) translateY(-2vh) scale(0.57);
    }

    53.5714285714% {
      transform: translateX(-47vw) translateY(-18vh) scale(0.39);
    }

    57.1428571429% {
      transform: translateX(45vw) translateY(24vh) scale(0.54);
    }

    60.7142857143% {
      transform: translateX(-32vw) translateY(14vh) scale(0.64);
    }

    64.2857142857% {
      transform: translateX(-24vw) translateY(37vh) scale(0.81);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(28vh) scale(0.67);
    }

    71.4285714286% {
      transform: translateX(11vw) translateY(-14vh) scale(0.71);
    }

    75% {
      transform: translateX(-28vw) translateY(-6vh) scale(0.47);
    }

    78.5714285714% {
      transform: translateX(15vw) translateY(-15vh) scale(0.79);
    }

    82.1428571429% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.51);
    }

    85.7142857143% {
      transform: translateX(43vw) translateY(47vh) scale(0.52);
    }

    89.2857142857% {
      transform: translateX(35vw) translateY(-38vh) scale(0.62);
    }

    92.8571428571% {
      transform: translateX(22vw) translateY(-44vh) scale(1);
    }

    96.4285714286% {
      transform: translateX(5vw) translateY(31vh) scale(0.33);
    }

    100% {
      transform: translateX(3vw) translateY(39vh) scale(0.63);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 10s, 9679ms;
    animation-delay: 0ms, 8051ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(22vw) translateY(-45vh) scale(0.86);
    }

    4.1666666667% {
      transform: translateX(20vw) translateY(6vh) scale(0.73);
    }

    8.3333333333% {
      transform: translateX(-36vw) translateY(15vh) scale(0.72);
    }

    12.5% {
      transform: translateX(25vw) translateY(-1vh) scale(0.38);
    }

    16.6666666667% {
      transform: translateX(-19vw) translateY(-28vh) scale(0.91);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(29vh) scale(0.47);
    }

    25% {
      transform: translateX(0vw) translateY(14vh) scale(0.55);
    }

    29.1666666667% {
      transform: translateX(-41vw) translateY(-28vh) scale(0.39);
    }

    33.3333333333% {
      transform: translateX(-11vw) translateY(34vh) scale(0.37);
    }

    37.5% {
      transform: translateX(-10vw) translateY(38vh) scale(0.75);
    }

    41.6666666667% {
      transform: translateX(-3vw) translateY(7vh) scale(0.47);
    }

    45.8333333333% {
      transform: translateX(22vw) translateY(-11vh) scale(0.97);
    }

    50% {
      transform: translateX(41vw) translateY(20vh) scale(0.37);
    }

    54.1666666667% {
      transform: translateX(23vw) translateY(-14vh) scale(0.41);
    }

    58.3333333333% {
      transform: translateX(43vw) translateY(-30vh) scale(0.34);
    }

    62.5% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(-21vw) translateY(-11vh) scale(0.42);
    }

    70.8333333333% {
      transform: translateX(47vw) translateY(11vh) scale(0.29);
    }

    75% {
      transform: translateX(20vw) translateY(-27vh) scale(0.4);
    }

    79.1666666667% {
      transform: translateX(-47vw) translateY(37vh) scale(0.52);
    }

    83.3333333333% {
      transform: translateX(0vw) translateY(3vh) scale(0.44);
    }

    87.5% {
      transform: translateX(27vw) translateY(47vh) scale(0.88);
    }

    91.6666666667% {
      transform: translateX(-5vw) translateY(3vh) scale(0.82);
    }

    95.8333333333% {
      transform: translateX(16vw) translateY(10vh) scale(0.61);
    }

    100% {
      transform: translateX(5vw) translateY(32vh) scale(0.33);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 18s, 8910ms;
    animation-delay: 0ms, 3832ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(21vw) translateY(3vh) scale(0.42);
    }

    4.347826087% {
      transform: translateX(-30vw) translateY(-40vh) scale(0.96);
    }

    8.6956521739% {
      transform: translateX(-26vw) translateY(-40vh) scale(0.82);
    }

    13.0434782609% {
      transform: translateX(-19vw) translateY(-2vh) scale(0.72);
    }

    17.3913043478% {
      transform: translateX(-49vw) translateY(36vh) scale(0.31);
    }

    21.7391304348% {
      transform: translateX(4vw) translateY(-6vh) scale(0.52);
    }

    26.0869565217% {
      transform: translateX(-16vw) translateY(40vh) scale(0.96);
    }

    30.4347826087% {
      transform: translateX(15vw) translateY(15vh) scale(0.9);
    }

    34.7826086957% {
      transform: translateX(26vw) translateY(-6vh) scale(0.28);
    }

    39.1304347826% {
      transform: translateX(9vw) translateY(46vh) scale(0.35);
    }

    43.4782608696% {
      transform: translateX(-25vw) translateY(19vh) scale(0.79);
    }

    47.8260869565% {
      transform: translateX(-33vw) translateY(46vh) scale(0.74);
    }

    52.1739130435% {
      transform: translateX(-22vw) translateY(1vh) scale(0.36);
    }

    56.5217391304% {
      transform: translateX(-49vw) translateY(9vh) scale(0.52);
    }

    60.8695652174% {
      transform: translateX(-29vw) translateY(39vh) scale(0.7);
    }

    65.2173913043% {
      transform: translateX(25vw) translateY(31vh) scale(0.34);
    }

    69.5652173913% {
      transform: translateX(19vw) translateY(18vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(-25vw) translateY(-25vh) scale(0.71);
    }

    78.2608695652% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.77);
    }

    82.6086956522% {
      transform: translateX(31vw) translateY(18vh) scale(0.41);
    }

    86.9565217391% {
      transform: translateX(41vw) translateY(-27vh) scale(0.5);
    }

    91.3043478261% {
      transform: translateX(37vw) translateY(12vh) scale(0.78);
    }

    95.652173913% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.68);
    }

    100% {
      transform: translateX(31vw) translateY(30vh) scale(0.96);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 17s, 8620ms;
    animation-delay: 0ms, 742ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(30vw) translateY(29vh) scale(0.57);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(-47vh) scale(0.3);
    }

    9.5238095238% {
      transform: translateX(33vw) translateY(46vh) scale(0.27);
    }

    14.2857142857% {
      transform: translateX(46vw) translateY(7vh) scale(0.82);
    }

    19.0476190476% {
      transform: translateX(-25vw) translateY(41vh) scale(0.87);
    }

    23.8095238095% {
      transform: translateX(-46vw) translateY(22vh) scale(0.99);
    }

    28.5714285714% {
      transform: translateX(4vw) translateY(11vh) scale(0.59);
    }

    33.3333333333% {
      transform: translateX(-43vw) translateY(20vh) scale(0.97);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(-18vh) scale(0.79);
    }

    42.8571428571% {
      transform: translateX(18vw) translateY(-7vh) scale(0.4);
    }

    47.619047619% {
      transform: translateX(-10vw) translateY(-36vh) scale(1);
    }

    52.380952381% {
      transform: translateX(-4vw) translateY(28vh) scale(0.8);
    }

    57.1428571429% {
      transform: translateX(-43vw) translateY(50vh) scale(0.99);
    }

    61.9047619048% {
      transform: translateX(30vw) translateY(-40vh) scale(0.99);
    }

    66.6666666667% {
      transform: translateX(48vw) translateY(-7vh) scale(0.73);
    }

    71.4285714286% {
      transform: translateX(18vw) translateY(-19vh) scale(0.47);
    }

    76.1904761905% {
      transform: translateX(-14vw) translateY(-35vh) scale(0.71);
    }

    80.9523809524% {
      transform: translateX(34vw) translateY(17vh) scale(0.97);
    }

    85.7142857143% {
      transform: translateX(48vw) translateY(-29vh) scale(0.88);
    }

    90.4761904762% {
      transform: translateX(44vw) translateY(46vh) scale(0.99);
    }

    95.2380952381% {
      transform: translateX(22vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(-3vw) translateY(-48vh) scale(0.54);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw #FFF01F;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw #FFF01F;
    }
  }

  .projectName {
    width: 50%;
  }

  .beastDiv {}

  .beast {
    width: 500px;
  }

  .fire {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0px;
  }

  .fire4 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire4 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -130px;
    top: 0px;
  }

  .fire5 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire5 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    top: 0px;
  }

  .fire6 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire6 {
    width: 75px;
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .fire2 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire2 {
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire2 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 {
    width: 60px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }


  .flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
  }

  .flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  .flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
  }

  .flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
  }

  .flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
  }

  .flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
  }

  .flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
  }

  @keyframes flameWobble {
    50% {
      transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
  }

  @keyframes flamefly {
    0% {
      transform: translate(0) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(-20px, -100px) rotate(180deg);
      opacity: 0;
    }
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 22px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 80px;
    color: black;
    text-shadow: 0 0 5px black;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {}

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 35px;
    color: black;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  @keyframes titleFont {
    0% {
      opacity: 1;
    }

    34% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    64% {
      opacity: 0;
    }

    65% {
      opacity: 1;
    }

    84% {
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleFont2 {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    70% {
      opacity: 1
    }

    71% {
      opacity: 0
    }

    85% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .intro {
    color: black;
    font-size: 80px;
  }

  .name1 {
    position: absolute;
    animation-name: titleFont;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .name2 {
    position: absolute;
    font-family: 'Underdog', sans-serif;
    animation-name: titleFont2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .shakeWord {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .name3 {
    position: absolute;
    animation-name: titleFont3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 0.5%;
  }

  .mintDiv {
    width: 40%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid black;
    box-shadow: black -5px 5px;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 27px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .discord:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black -5px 5px;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black 5px 5px;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #fef8e2;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    box-shadow: black 0px 5px;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 174, 0);
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: black;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(37, 59, 23);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2 {
    background-color: #fef8e2;
    color: black;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid black;
    transition: transform .2s;
    box-shadow: black 0px 3px;
    border-radius: 10px;
    font-weight: bold;
  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background-image: linear-gradient(144deg, #a08fa1, #9895a4 50%, #9696a5);
    border: 0;
    box-shadow: black -5px 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    border-radius: 10px;
    transform: rotate(-3deg);
    border: 2px solid black;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #585858;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #2c2c2c;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }
  .ser {
    color: rgb(0, 0, 0);
    font-size: 30px;
  }

  .closeNote2 {
    position: fixed;
    right: 0;
    top: 5%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote2:hover,
  .closeNote2:focus {
    color: rgb(71, 70, 70);
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    display: none;

  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.856);
    position: fixed;
    width: 80%;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #414141;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #252525;
    z-index: 100000;
    padding: 5%;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    background-color: #ffffffb0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }
  .mainImage {
    position: relative;
    bottom: 0;

  }

  .beast1 {
    width: 480px;
    bottom: 0;
  }

  .beast2 {
    width: 480px;
    bottom: 0;
    position: relative;
    animation: mainImageAnimation ease 2s infinite;

  }

  @keyframes mainImageAnimation {
    0% {
      opacity: 0;
    }

    49% {

      opacity: 0;

    }

    50% {

      opacity: 1;

    }

    100% {
      opacity: 1;
    }
  }


  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 14s, 5348ms;
    animation-delay: 0ms, 3421ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(44vh) scale(0.62);
    }

    4% {
      transform: translateX(-4vw) translateY(50vh) scale(0.77);
    }

    8% {
      transform: translateX(43vw) translateY(-35vh) scale(0.85);
    }

    12% {
      transform: translateX(6vw) translateY(-16vh) scale(0.32);
    }

    16% {
      transform: translateX(40vw) translateY(-28vh) scale(0.61);
    }

    20% {
      transform: translateX(16vw) translateY(-33vh) scale(0.62);
    }

    24% {
      transform: translateX(-13vw) translateY(12vh) scale(0.99);
    }

    28% {
      transform: translateX(-6vw) translateY(15vh) scale(0.52);
    }

    32% {
      transform: translateX(39vw) translateY(40vh) scale(0.42);
    }

    36% {
      transform: translateX(-19vw) translateY(-31vh) scale(0.72);
    }

    40% {
      transform: translateX(47vw) translateY(18vh) scale(0.74);
    }

    44% {
      transform: translateX(-24vw) translateY(1vh) scale(0.8);
    }

    48% {
      transform: translateX(36vw) translateY(-6vh) scale(0.65);
    }

    52% {
      transform: translateX(-6vw) translateY(11vh) scale(0.28);
    }

    56% {
      transform: translateX(34vw) translateY(35vh) scale(0.79);
    }

    60% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.48);
    }

    64% {
      transform: translateX(33vw) translateY(-1vh) scale(0.47);
    }

    68% {
      transform: translateX(-45vw) translateY(-41vh) scale(0.54);
    }

    72% {
      transform: translateX(-20vw) translateY(40vh) scale(0.92);
    }

    76% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.64);
    }

    80% {
      transform: translateX(-23vw) translateY(-18vh) scale(0.92);
    }

    84% {
      transform: translateX(-37vw) translateY(7vh) scale(0.98);
    }

    88% {
      transform: translateX(-31vw) translateY(-35vh) scale(0.92);
    }

    92% {
      transform: translateX(1vw) translateY(-43vh) scale(0.29);
    }

    96% {
      transform: translateX(-41vw) translateY(-20vh) scale(0.44);
    }

    100% {
      transform: translateX(-4vw) translateY(-33vh) scale(0.38);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 11s, 6289ms;
    animation-delay: 0ms, 6547ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-17vw) translateY(40vh) scale(0.32);
    }

    3.5714285714% {
      transform: translateX(9vw) translateY(34vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-21vw) translateY(-5vh) scale(0.64);
    }

    10.7142857143% {
      transform: translateX(47vw) translateY(6vh) scale(0.76);
    }

    14.2857142857% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.93);
    }

    17.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.36);
    }

    21.4285714286% {
      transform: translateX(-34vw) translateY(38vh) scale(0.46);
    }

    25% {
      transform: translateX(-23vw) translateY(-42vh) scale(0.58);
    }

    28.5714285714% {
      transform: translateX(6vw) translateY(-31vh) scale(0.93);
    }

    32.1428571429% {
      transform: translateX(-37vw) translateY(31vh) scale(0.47);
    }

    35.7142857143% {
      transform: translateX(49vw) translateY(-37vh) scale(0.58);
    }

    39.2857142857% {
      transform: translateX(13vw) translateY(-42vh) scale(0.86);
    }

    42.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.48);
    }

    46.4285714286% {
      transform: translateX(-13vw) translateY(-13vh) scale(0.85);
    }

    50% {
      transform: translateX(7vw) translateY(49vh) scale(0.39);
    }

    53.5714285714% {
      transform: translateX(44vw) translateY(-15vh) scale(0.9);
    }

    57.1428571429% {
      transform: translateX(-2vw) translateY(-20vh) scale(0.27);
    }

    60.7142857143% {
      transform: translateX(-3vw) translateY(34vh) scale(0.51);
    }

    64.2857142857% {
      transform: translateX(-40vw) translateY(-5vh) scale(0.39);
    }

    67.8571428571% {
      transform: translateX(1vw) translateY(39vh) scale(0.53);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(-12vh) scale(0.45);
    }

    75% {
      transform: translateX(-17vw) translateY(-36vh) scale(0.63);
    }

    78.5714285714% {
      transform: translateX(27vw) translateY(36vh) scale(0.92);
    }

    82.1428571429% {
      transform: translateX(18vw) translateY(-5vh) scale(0.8);
    }

    85.7142857143% {
      transform: translateX(40vw) translateY(13vh) scale(0.92);
    }

    89.2857142857% {
      transform: translateX(3vw) translateY(-4vh) scale(0.33);
    }

    92.8571428571% {
      transform: translateX(-31vw) translateY(43vh) scale(0.71);
    }

    96.4285714286% {
      transform: translateX(-42vw) translateY(-18vh) scale(0.64);
    }

    100% {
      transform: translateX(24vw) translateY(14vh) scale(0.67);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 9s, 7991ms;
    animation-delay: 0ms, 1922ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-22vw) translateY(24vh) scale(0.62);
    }

    3.5714285714% {
      transform: translateX(-44vw) translateY(37vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-47vw) translateY(46vh) scale(0.91);
    }

    10.7142857143% {
      transform: translateX(39vw) translateY(15vh) scale(0.67);
    }

    14.2857142857% {
      transform: translateX(7vw) translateY(44vh) scale(0.73);
    }

    17.8571428571% {
      transform: translateX(40vw) translateY(11vh) scale(0.56);
    }

    21.4285714286% {
      transform: translateX(23vw) translateY(-30vh) scale(0.98);
    }

    25% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.4);
    }

    28.5714285714% {
      transform: translateX(31vw) translateY(30vh) scale(0.78);
    }

    32.1428571429% {
      transform: translateX(-10vw) translateY(-22vh) scale(0.7);
    }

    35.7142857143% {
      transform: translateX(-31vw) translateY(2vh) scale(0.87);
    }

    39.2857142857% {
      transform: translateX(45vw) translateY(-3vh) scale(0.33);
    }

    42.8571428571% {
      transform: translateX(-7vw) translateY(-30vh) scale(0.88);
    }

    46.4285714286% {
      transform: translateX(8vw) translateY(22vh) scale(0.51);
    }

    50% {
      transform: translateX(31vw) translateY(25vh) scale(0.31);
    }

    53.5714285714% {
      transform: translateX(47vw) translateY(13vh) scale(0.76);
    }

    57.1428571429% {
      transform: translateX(-19vw) translateY(7vh) scale(0.36);
    }

    60.7142857143% {
      transform: translateX(11vw) translateY(6vh) scale(0.83);
    }

    64.2857142857% {
      transform: translateX(48vw) translateY(-38vh) scale(0.62);
    }

    67.8571428571% {
      transform: translateX(41vw) translateY(-18vh) scale(0.68);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(50vh) scale(0.34);
    }

    75% {
      transform: translateX(-31vw) translateY(-15vh) scale(0.51);
    }

    78.5714285714% {
      transform: translateX(-41vw) translateY(9vh) scale(0.91);
    }

    82.1428571429% {
      transform: translateX(-39vw) translateY(16vh) scale(0.45);
    }

    85.7142857143% {
      transform: translateX(-44vw) translateY(9vh) scale(0.83);
    }

    89.2857142857% {
      transform: translateX(-41vw) translateY(8vh) scale(0.66);
    }

    92.8571428571% {
      transform: translateX(-44vw) translateY(42vh) scale(0.56);
    }

    96.4285714286% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.86);
    }

    100% {
      transform: translateX(-4vw) translateY(-8vh) scale(0.42);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 17s, 5968ms;
    animation-delay: 0ms, 1513ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(45vw) translateY(13vh) scale(0.5);
    }

    5.2631578947% {
      transform: translateX(37vw) translateY(14vh) scale(0.98);
    }

    10.5263157895% {
      transform: translateX(-6vw) translateY(-16vh) scale(0.7);
    }

    15.7894736842% {
      transform: translateX(-24vw) translateY(-47vh) scale(0.29);
    }

    21.0526315789% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.63);
    }

    26.3157894737% {
      transform: translateX(-11vw) translateY(-16vh) scale(0.49);
    }

    31.5789473684% {
      transform: translateX(-11vw) translateY(7vh) scale(0.45);
    }

    36.8421052632% {
      transform: translateX(13vw) translateY(-6vh) scale(0.34);
    }

    42.1052631579% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.37);
    }

    47.3684210526% {
      transform: translateX(5vw) translateY(25vh) scale(0.53);
    }

    52.6315789474% {
      transform: translateX(27vw) translateY(14vh) scale(0.74);
    }

    57.8947368421% {
      transform: translateX(-26vw) translateY(-28vh) scale(0.64);
    }

    63.1578947368% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.46);
    }

    68.4210526316% {
      transform: translateX(-23vw) translateY(17vh) scale(0.37);
    }

    73.6842105263% {
      transform: translateX(9vw) translateY(-6vh) scale(0.69);
    }

    78.9473684211% {
      transform: translateX(-41vw) translateY(-42vh) scale(0.75);
    }

    84.2105263158% {
      transform: translateX(34vw) translateY(1vh) scale(0.86);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-26vh) scale(0.91);
    }

    94.7368421053% {
      transform: translateX(-26vw) translateY(13vh) scale(0.49);
    }

    100% {
      transform: translateX(1vw) translateY(-1vh) scale(0.76);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 13s, 9409ms;
    animation-delay: 0ms, 3495ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(34vw) translateY(18vh) scale(0.64);
    }

    4.1666666667% {
      transform: translateX(34vw) translateY(11vh) scale(0.39);
    }

    8.3333333333% {
      transform: translateX(-37vw) translateY(-43vh) scale(0.47);
    }

    12.5% {
      transform: translateX(17vw) translateY(-5vh) scale(0.49);
    }

    16.6666666667% {
      transform: translateX(30vw) translateY(7vh) scale(0.47);
    }

    20.8333333333% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.76);
    }

    25% {
      transform: translateX(-20vw) translateY(28vh) scale(0.7);
    }

    29.1666666667% {
      transform: translateX(-16vw) translateY(-5vh) scale(0.84);
    }

    33.3333333333% {
      transform: translateX(-17vw) translateY(-41vh) scale(0.42);
    }

    37.5% {
      transform: translateX(19vw) translateY(-20vh) scale(0.81);
    }

    41.6666666667% {
      transform: translateX(12vw) translateY(15vh) scale(0.75);
    }

    45.8333333333% {
      transform: translateX(30vw) translateY(-32vh) scale(0.47);
    }

    50% {
      transform: translateX(-40vw) translateY(-38vh) scale(0.59);
    }

    54.1666666667% {
      transform: translateX(4vw) translateY(44vh) scale(0.82);
    }

    58.3333333333% {
      transform: translateX(44vw) translateY(-14vh) scale(0.55);
    }

    62.5% {
      transform: translateX(-15vw) translateY(39vh) scale(0.33);
    }

    66.6666666667% {
      transform: translateX(-28vw) translateY(-30vh) scale(0.29);
    }

    70.8333333333% {
      transform: translateX(40vw) translateY(27vh) scale(0.61);
    }

    75% {
      transform: translateX(22vw) translateY(-21vh) scale(0.53);
    }

    79.1666666667% {
      transform: translateX(-49vw) translateY(21vh) scale(0.64);
    }

    83.3333333333% {
      transform: translateX(7vw) translateY(14vh) scale(0.81);
    }

    87.5% {
      transform: translateX(12vw) translateY(14vh) scale(0.8);
    }

    91.6666666667% {
      transform: translateX(-44vw) translateY(-42vh) scale(0.49);
    }

    95.8333333333% {
      transform: translateX(43vw) translateY(41vh) scale(0.6);
    }

    100% {
      transform: translateX(6vw) translateY(-29vh) scale(0.7);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 10229ms;
    animation-delay: 0ms, 7081ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(23vw) translateY(18vh) scale(0.76);
    }

    4.1666666667% {
      transform: translateX(-26vw) translateY(6vh) scale(0.41);
    }

    8.3333333333% {
      transform: translateX(-1vw) translateY(-18vh) scale(0.63);
    }

    12.5% {
      transform: translateX(22vw) translateY(43vh) scale(0.63);
    }

    16.6666666667% {
      transform: translateX(25vw) translateY(48vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(3vw) translateY(-29vh) scale(0.33);
    }

    25% {
      transform: translateX(26vw) translateY(0vh) scale(0.86);
    }

    29.1666666667% {
      transform: translateX(-33vw) translateY(5vh) scale(0.51);
    }

    33.3333333333% {
      transform: translateX(4vw) translateY(-9vh) scale(0.39);
    }

    37.5% {
      transform: translateX(26vw) translateY(12vh) scale(0.65);
    }

    41.6666666667% {
      transform: translateX(31vw) translateY(18vh) scale(0.42);
    }

    45.8333333333% {
      transform: translateX(25vw) translateY(-45vh) scale(0.9);
    }

    50% {
      transform: translateX(-11vw) translateY(49vh) scale(0.46);
    }

    54.1666666667% {
      transform: translateX(-45vw) translateY(48vh) scale(0.81);
    }

    58.3333333333% {
      transform: translateX(46vw) translateY(5vh) scale(0.57);
    }

    62.5% {
      transform: translateX(35vw) translateY(-47vh) scale(0.56);
    }

    66.6666666667% {
      transform: translateX(22vw) translateY(-44vh) scale(0.83);
    }

    70.8333333333% {
      transform: translateX(-40vw) translateY(-11vh) scale(0.73);
    }

    75% {
      transform: translateX(-19vw) translateY(13vh) scale(0.69);
    }

    79.1666666667% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.83);
    }

    83.3333333333% {
      transform: translateX(33vw) translateY(21vh) scale(0.67);
    }

    87.5% {
      transform: translateX(-36vw) translateY(27vh) scale(0.79);
    }

    91.6666666667% {
      transform: translateX(5vw) translateY(17vh) scale(0.56);
    }

    95.8333333333% {
      transform: translateX(29vw) translateY(-10vh) scale(0.51);
    }

    100% {
      transform: translateX(-1vw) translateY(-14vh) scale(0.29);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 18s, 6470ms;
    animation-delay: 0ms, 8336ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(40vw) translateY(47vh) scale(1);
    }

    3.5714285714% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.78);
    }

    7.1428571429% {
      transform: translateX(24vw) translateY(17vh) scale(0.28);
    }

    10.7142857143% {
      transform: translateX(41vw) translateY(23vh) scale(0.96);
    }

    14.2857142857% {
      transform: translateX(14vw) translateY(13vh) scale(0.95);
    }

    17.8571428571% {
      transform: translateX(-17vw) translateY(49vh) scale(0.69);
    }

    21.4285714286% {
      transform: translateX(9vw) translateY(20vh) scale(0.29);
    }

    25% {
      transform: translateX(-11vw) translateY(30vh) scale(0.96);
    }

    28.5714285714% {
      transform: translateX(32vw) translateY(-27vh) scale(0.43);
    }

    32.1428571429% {
      transform: translateX(-48vw) translateY(-7vh) scale(0.9);
    }

    35.7142857143% {
      transform: translateX(-19vw) translateY(49vh) scale(0.41);
    }

    39.2857142857% {
      transform: translateX(7vw) translateY(-36vh) scale(0.5);
    }

    42.8571428571% {
      transform: translateX(-3vw) translateY(-40vh) scale(0.78);
    }

    46.4285714286% {
      transform: translateX(47vw) translateY(6vh) scale(0.26);
    }

    50% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.3);
    }

    53.5714285714% {
      transform: translateX(-38vw) translateY(-10vh) scale(0.71);
    }

    57.1428571429% {
      transform: translateX(36vw) translateY(-4vh) scale(0.96);
    }

    60.7142857143% {
      transform: translateX(16vw) translateY(6vh) scale(0.62);
    }

    64.2857142857% {
      transform: translateX(-30vw) translateY(-39vh) scale(0.52);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(33vh) scale(0.45);
    }

    71.4285714286% {
      transform: translateX(-10vw) translateY(-28vh) scale(0.78);
    }

    75% {
      transform: translateX(48vw) translateY(16vh) scale(0.71);
    }

    78.5714285714% {
      transform: translateX(-45vw) translateY(-39vh) scale(0.63);
    }

    82.1428571429% {
      transform: translateX(22vw) translateY(-49vh) scale(0.37);
    }

    85.7142857143% {
      transform: translateX(49vw) translateY(27vh) scale(0.26);
    }

    89.2857142857% {
      transform: translateX(23vw) translateY(-44vh) scale(0.73);
    }

    92.8571428571% {
      transform: translateX(-2vw) translateY(29vh) scale(0.47);
    }

    96.4285714286% {
      transform: translateX(-2vw) translateY(-30vh) scale(0.57);
    }

    100% {
      transform: translateX(-10vw) translateY(-29vh) scale(0.96);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 15s, 6398ms;
    animation-delay: 0ms, 565ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(8vw) translateY(-41vh) scale(0.92);
    }

    4.5454545455% {
      transform: translateX(-26vw) translateY(43vh) scale(0.97);
    }

    9.0909090909% {
      transform: translateX(38vw) translateY(-44vh) scale(0.43);
    }

    13.6363636364% {
      transform: translateX(-35vw) translateY(40vh) scale(0.78);
    }

    18.1818181818% {
      transform: translateX(-7vw) translateY(-35vh) scale(0.68);
    }

    22.7272727273% {
      transform: translateX(-43vw) translateY(-29vh) scale(0.48);
    }

    27.2727272727% {
      transform: translateX(-18vw) translateY(27vh) scale(0.7);
    }

    31.8181818182% {
      transform: translateX(42vw) translateY(-45vh) scale(0.77);
    }

    36.3636363636% {
      transform: translateX(1vw) translateY(-13vh) scale(0.84);
    }

    40.9090909091% {
      transform: translateX(35vw) translateY(-26vh) scale(0.84);
    }

    45.4545454545% {
      transform: translateX(-3vw) translateY(48vh) scale(0.29);
    }

    50% {
      transform: translateX(9vw) translateY(-4vh) scale(0.99);
    }

    54.5454545455% {
      transform: translateX(-36vw) translateY(-36vh) scale(0.5);
    }

    59.0909090909% {
      transform: translateX(42vw) translateY(-44vh) scale(0.39);
    }

    63.6363636364% {
      transform: translateX(26vw) translateY(20vh) scale(0.76);
    }

    68.1818181818% {
      transform: translateX(30vw) translateY(48vh) scale(0.9);
    }

    72.7272727273% {
      transform: translateX(-9vw) translateY(35vh) scale(0.97);
    }

    77.2727272727% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.53);
    }

    81.8181818182% {
      transform: translateX(-49vw) translateY(-29vh) scale(0.26);
    }

    86.3636363636% {
      transform: translateX(-24vw) translateY(36vh) scale(0.36);
    }

    90.9090909091% {
      transform: translateX(-33vw) translateY(20vh) scale(0.52);
    }

    95.4545454545% {
      transform: translateX(14vw) translateY(10vh) scale(0.8);
    }

    100% {
      transform: translateX(14vw) translateY(-30vh) scale(0.54);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 15s, 5044ms;
    animation-delay: 0ms, 1556ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(27vw) translateY(-37vh) scale(0.9);
    }

    4.1666666667% {
      transform: translateX(12vw) translateY(-22vh) scale(0.44);
    }

    8.3333333333% {
      transform: translateX(-13vw) translateY(-28vh) scale(0.53);
    }

    12.5% {
      transform: translateX(-43vw) translateY(18vh) scale(0.39);
    }

    16.6666666667% {
      transform: translateX(34vw) translateY(31vh) scale(0.97);
    }

    20.8333333333% {
      transform: translateX(47vw) translateY(-33vh) scale(0.97);
    }

    25% {
      transform: translateX(35vw) translateY(-47vh) scale(0.42);
    }

    29.1666666667% {
      transform: translateX(-47vw) translateY(39vh) scale(0.4);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-45vh) scale(0.65);
    }

    37.5% {
      transform: translateX(43vw) translateY(-23vh) scale(0.6);
    }

    41.6666666667% {
      transform: translateX(38vw) translateY(15vh) scale(0.63);
    }

    45.8333333333% {
      transform: translateX(49vw) translateY(34vh) scale(0.82);
    }

    50% {
      transform: translateX(-30vw) translateY(-9vh) scale(0.91);
    }

    54.1666666667% {
      transform: translateX(18vw) translateY(-31vh) scale(0.73);
    }

    58.3333333333% {
      transform: translateX(32vw) translateY(-11vh) scale(0.97);
    }

    62.5% {
      transform: translateX(-12vw) translateY(31vh) scale(0.77);
    }

    66.6666666667% {
      transform: translateX(26vw) translateY(12vh) scale(0.84);
    }

    70.8333333333% {
      transform: translateX(2vw) translateY(-46vh) scale(0.38);
    }

    75% {
      transform: translateX(46vw) translateY(35vh) scale(0.79);
    }

    79.1666666667% {
      transform: translateX(50vw) translateY(26vh) scale(0.46);
    }

    83.3333333333% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.48);
    }

    87.5% {
      transform: translateX(6vw) translateY(-18vh) scale(0.78);
    }

    91.6666666667% {
      transform: translateX(33vw) translateY(-31vh) scale(0.75);
    }

    95.8333333333% {
      transform: translateX(27vw) translateY(-13vh) scale(0.52);
    }

    100% {
      transform: translateX(4vw) translateY(11vh) scale(0.86);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 5550ms;
    animation-delay: 0ms, 4125ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-26vw) translateY(-38vh) scale(0.49);
    }

    5.5555555556% {
      transform: translateX(21vw) translateY(7vh) scale(0.8);
    }

    11.1111111111% {
      transform: translateX(-43vw) translateY(-2vh) scale(0.75);
    }

    16.6666666667% {
      transform: translateX(29vw) translateY(-17vh) scale(1);
    }

    22.2222222222% {
      transform: translateX(-43vw) translateY(13vh) scale(0.88);
    }

    27.7777777778% {
      transform: translateX(33vw) translateY(-48vh) scale(0.86);
    }

    33.3333333333% {
      transform: translateX(17vw) translateY(-23vh) scale(0.95);
    }

    38.8888888889% {
      transform: translateX(-7vw) translateY(39vh) scale(0.4);
    }

    44.4444444444% {
      transform: translateX(-44vw) translateY(-35vh) scale(0.39);
    }

    50% {
      transform: translateX(-23vw) translateY(-10vh) scale(0.54);
    }

    55.5555555556% {
      transform: translateX(8vw) translateY(39vh) scale(0.63);
    }

    61.1111111111% {
      transform: translateX(-3vw) translateY(28vh) scale(0.46);
    }

    66.6666666667% {
      transform: translateX(19vw) translateY(-35vh) scale(0.55);
    }

    72.2222222222% {
      transform: translateX(-32vw) translateY(1vh) scale(0.87);
    }

    77.7777777778% {
      transform: translateX(-18vw) translateY(11vh) scale(0.62);
    }

    83.3333333333% {
      transform: translateX(-24vw) translateY(-31vh) scale(0.42);
    }

    88.8888888889% {
      transform: translateX(5vw) translateY(34vh) scale(0.5);
    }

    94.4444444444% {
      transform: translateX(-11vw) translateY(32vh) scale(0.36);
    }

    100% {
      transform: translateX(5vw) translateY(-8vh) scale(0.99);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 16s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 16s, 10297ms;
    animation-delay: 0ms, 4707ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-36vw) translateY(-2vh) scale(0.37);
    }

    4.7619047619% {
      transform: translateX(-27vw) translateY(30vh) scale(0.38);
    }

    9.5238095238% {
      transform: translateX(45vw) translateY(-23vh) scale(0.33);
    }

    14.2857142857% {
      transform: translateX(25vw) translateY(9vh) scale(0.7);
    }

    19.0476190476% {
      transform: translateX(24vw) translateY(-13vh) scale(0.48);
    }

    23.8095238095% {
      transform: translateX(43vw) translateY(-5vh) scale(0.68);
    }

    28.5714285714% {
      transform: translateX(41vw) translateY(2vh) scale(0.83);
    }

    33.3333333333% {
      transform: translateX(-46vw) translateY(-13vh) scale(0.7);
    }

    38.0952380952% {
      transform: translateX(-39vw) translateY(-25vh) scale(0.85);
    }

    42.8571428571% {
      transform: translateX(-27vw) translateY(23vh) scale(0.33);
    }

    47.619047619% {
      transform: translateX(-13vw) translateY(45vh) scale(0.3);
    }

    52.380952381% {
      transform: translateX(10vw) translateY(-28vh) scale(0.55);
    }

    57.1428571429% {
      transform: translateX(-30vw) translateY(-16vh) scale(0.26);
    }

    61.9047619048% {
      transform: translateX(40vw) translateY(30vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(45vw) translateY(37vh) scale(0.79);
    }

    71.4285714286% {
      transform: translateX(-13vw) translateY(-24vh) scale(0.62);
    }

    76.1904761905% {
      transform: translateX(33vw) translateY(-12vh) scale(0.94);
    }

    80.9523809524% {
      transform: translateX(10vw) translateY(-49vh) scale(0.3);
    }

    85.7142857143% {
      transform: translateX(-15vw) translateY(48vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(-42vw) translateY(40vh) scale(0.54);
    }

    95.2380952381% {
      transform: translateX(-22vw) translateY(0vh) scale(0.63);
    }

    100% {
      transform: translateX(32vw) translateY(14vh) scale(0.79);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 9s, 8018ms;
    animation-delay: 0ms, 4673ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(-30vw) translateY(47vh) scale(0.57);
    }

    3.5714285714% {
      transform: translateX(-19vw) translateY(19vh) scale(0.52);
    }

    7.1428571429% {
      transform: translateX(-14vw) translateY(48vh) scale(0.9);
    }

    10.7142857143% {
      transform: translateX(-44vw) translateY(13vh) scale(0.38);
    }

    14.2857142857% {
      transform: translateX(-10vw) translateY(43vh) scale(0.49);
    }

    17.8571428571% {
      transform: translateX(39vw) translateY(49vh) scale(0.79);
    }

    21.4285714286% {
      transform: translateX(50vw) translateY(-17vh) scale(0.4);
    }

    25% {
      transform: translateX(-20vw) translateY(22vh) scale(0.48);
    }

    28.5714285714% {
      transform: translateX(-27vw) translateY(32vh) scale(0.92);
    }

    32.1428571429% {
      transform: translateX(11vw) translateY(-7vh) scale(0.99);
    }

    35.7142857143% {
      transform: translateX(-27vw) translateY(46vh) scale(0.34);
    }

    39.2857142857% {
      transform: translateX(-24vw) translateY(3vh) scale(0.36);
    }

    42.8571428571% {
      transform: translateX(48vw) translateY(-30vh) scale(0.33);
    }

    46.4285714286% {
      transform: translateX(-44vw) translateY(-30vh) scale(0.5);
    }

    50% {
      transform: translateX(44vw) translateY(-2vh) scale(0.57);
    }

    53.5714285714% {
      transform: translateX(-47vw) translateY(-18vh) scale(0.39);
    }

    57.1428571429% {
      transform: translateX(45vw) translateY(24vh) scale(0.54);
    }

    60.7142857143% {
      transform: translateX(-32vw) translateY(14vh) scale(0.64);
    }

    64.2857142857% {
      transform: translateX(-24vw) translateY(37vh) scale(0.81);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(28vh) scale(0.67);
    }

    71.4285714286% {
      transform: translateX(11vw) translateY(-14vh) scale(0.71);
    }

    75% {
      transform: translateX(-28vw) translateY(-6vh) scale(0.47);
    }

    78.5714285714% {
      transform: translateX(15vw) translateY(-15vh) scale(0.79);
    }

    82.1428571429% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.51);
    }

    85.7142857143% {
      transform: translateX(43vw) translateY(47vh) scale(0.52);
    }

    89.2857142857% {
      transform: translateX(35vw) translateY(-38vh) scale(0.62);
    }

    92.8571428571% {
      transform: translateX(22vw) translateY(-44vh) scale(1);
    }

    96.4285714286% {
      transform: translateX(5vw) translateY(31vh) scale(0.33);
    }

    100% {
      transform: translateX(3vw) translateY(39vh) scale(0.63);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 10s, 9679ms;
    animation-delay: 0ms, 8051ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(22vw) translateY(-45vh) scale(0.86);
    }

    4.1666666667% {
      transform: translateX(20vw) translateY(6vh) scale(0.73);
    }

    8.3333333333% {
      transform: translateX(-36vw) translateY(15vh) scale(0.72);
    }

    12.5% {
      transform: translateX(25vw) translateY(-1vh) scale(0.38);
    }

    16.6666666667% {
      transform: translateX(-19vw) translateY(-28vh) scale(0.91);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(29vh) scale(0.47);
    }

    25% {
      transform: translateX(0vw) translateY(14vh) scale(0.55);
    }

    29.1666666667% {
      transform: translateX(-41vw) translateY(-28vh) scale(0.39);
    }

    33.3333333333% {
      transform: translateX(-11vw) translateY(34vh) scale(0.37);
    }

    37.5% {
      transform: translateX(-10vw) translateY(38vh) scale(0.75);
    }

    41.6666666667% {
      transform: translateX(-3vw) translateY(7vh) scale(0.47);
    }

    45.8333333333% {
      transform: translateX(22vw) translateY(-11vh) scale(0.97);
    }

    50% {
      transform: translateX(41vw) translateY(20vh) scale(0.37);
    }

    54.1666666667% {
      transform: translateX(23vw) translateY(-14vh) scale(0.41);
    }

    58.3333333333% {
      transform: translateX(43vw) translateY(-30vh) scale(0.34);
    }

    62.5% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(-21vw) translateY(-11vh) scale(0.42);
    }

    70.8333333333% {
      transform: translateX(47vw) translateY(11vh) scale(0.29);
    }

    75% {
      transform: translateX(20vw) translateY(-27vh) scale(0.4);
    }

    79.1666666667% {
      transform: translateX(-47vw) translateY(37vh) scale(0.52);
    }

    83.3333333333% {
      transform: translateX(0vw) translateY(3vh) scale(0.44);
    }

    87.5% {
      transform: translateX(27vw) translateY(47vh) scale(0.88);
    }

    91.6666666667% {
      transform: translateX(-5vw) translateY(3vh) scale(0.82);
    }

    95.8333333333% {
      transform: translateX(16vw) translateY(10vh) scale(0.61);
    }

    100% {
      transform: translateX(5vw) translateY(32vh) scale(0.33);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 18s, 8910ms;
    animation-delay: 0ms, 3832ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(21vw) translateY(3vh) scale(0.42);
    }

    4.347826087% {
      transform: translateX(-30vw) translateY(-40vh) scale(0.96);
    }

    8.6956521739% {
      transform: translateX(-26vw) translateY(-40vh) scale(0.82);
    }

    13.0434782609% {
      transform: translateX(-19vw) translateY(-2vh) scale(0.72);
    }

    17.3913043478% {
      transform: translateX(-49vw) translateY(36vh) scale(0.31);
    }

    21.7391304348% {
      transform: translateX(4vw) translateY(-6vh) scale(0.52);
    }

    26.0869565217% {
      transform: translateX(-16vw) translateY(40vh) scale(0.96);
    }

    30.4347826087% {
      transform: translateX(15vw) translateY(15vh) scale(0.9);
    }

    34.7826086957% {
      transform: translateX(26vw) translateY(-6vh) scale(0.28);
    }

    39.1304347826% {
      transform: translateX(9vw) translateY(46vh) scale(0.35);
    }

    43.4782608696% {
      transform: translateX(-25vw) translateY(19vh) scale(0.79);
    }

    47.8260869565% {
      transform: translateX(-33vw) translateY(46vh) scale(0.74);
    }

    52.1739130435% {
      transform: translateX(-22vw) translateY(1vh) scale(0.36);
    }

    56.5217391304% {
      transform: translateX(-49vw) translateY(9vh) scale(0.52);
    }

    60.8695652174% {
      transform: translateX(-29vw) translateY(39vh) scale(0.7);
    }

    65.2173913043% {
      transform: translateX(25vw) translateY(31vh) scale(0.34);
    }

    69.5652173913% {
      transform: translateX(19vw) translateY(18vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(-25vw) translateY(-25vh) scale(0.71);
    }

    78.2608695652% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.77);
    }

    82.6086956522% {
      transform: translateX(31vw) translateY(18vh) scale(0.41);
    }

    86.9565217391% {
      transform: translateX(41vw) translateY(-27vh) scale(0.5);
    }

    91.3043478261% {
      transform: translateX(37vw) translateY(12vh) scale(0.78);
    }

    95.652173913% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.68);
    }

    100% {
      transform: translateX(31vw) translateY(30vh) scale(0.96);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 17s, 8620ms;
    animation-delay: 0ms, 742ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(30vw) translateY(29vh) scale(0.57);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(-47vh) scale(0.3);
    }

    9.5238095238% {
      transform: translateX(33vw) translateY(46vh) scale(0.27);
    }

    14.2857142857% {
      transform: translateX(46vw) translateY(7vh) scale(0.82);
    }

    19.0476190476% {
      transform: translateX(-25vw) translateY(41vh) scale(0.87);
    }

    23.8095238095% {
      transform: translateX(-46vw) translateY(22vh) scale(0.99);
    }

    28.5714285714% {
      transform: translateX(4vw) translateY(11vh) scale(0.59);
    }

    33.3333333333% {
      transform: translateX(-43vw) translateY(20vh) scale(0.97);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(-18vh) scale(0.79);
    }

    42.8571428571% {
      transform: translateX(18vw) translateY(-7vh) scale(0.4);
    }

    47.619047619% {
      transform: translateX(-10vw) translateY(-36vh) scale(1);
    }

    52.380952381% {
      transform: translateX(-4vw) translateY(28vh) scale(0.8);
    }

    57.1428571429% {
      transform: translateX(-43vw) translateY(50vh) scale(0.99);
    }

    61.9047619048% {
      transform: translateX(30vw) translateY(-40vh) scale(0.99);
    }

    66.6666666667% {
      transform: translateX(48vw) translateY(-7vh) scale(0.73);
    }

    71.4285714286% {
      transform: translateX(18vw) translateY(-19vh) scale(0.47);
    }

    76.1904761905% {
      transform: translateX(-14vw) translateY(-35vh) scale(0.71);
    }

    80.9523809524% {
      transform: translateX(34vw) translateY(17vh) scale(0.97);
    }

    85.7142857143% {
      transform: translateX(48vw) translateY(-29vh) scale(0.88);
    }

    90.4761904762% {
      transform: translateX(44vw) translateY(46vh) scale(0.99);
    }

    95.2380952381% {
      transform: translateX(22vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(-3vw) translateY(-48vh) scale(0.54);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw #FFF01F;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw #FFF01F;
    }
  }

  .projectName {
    width: 50%;
  }

  .beastDiv {}

  .beast {
    width: 750px;
  }

  .fire {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0px;
  }

  .fire4 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire4 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -130px;
    top: 0px;
  }

  .fire5 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire5 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    top: 0px;
  }

  .fire6 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire6 {
    width: 75px;
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .fire2 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire2 {
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire2 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 {
    width: 60px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }


  .flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
  }

  .flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  .flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
  }

  .flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
  }

  .flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
  }

  .flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
  }

  .flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
  }

  @keyframes flameWobble {
    50% {
      transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
  }

  @keyframes flamefly {
    0% {
      transform: translate(0) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(-20px, -100px) rotate(180deg);
      opacity: 0;
    }
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 22px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 80px;
    color: black;
    text-shadow: 0 0 5px black;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {}

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 55px;
    height: 55px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 35px;
    color: black;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  @keyframes titleFont {
    0% {
      opacity: 1;
    }

    34% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    64% {
      opacity: 0;
    }

    65% {
      opacity: 1;
    }

    84% {
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleFont2 {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    70% {
      opacity: 1
    }

    71% {
      opacity: 0
    }

    85% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .intro {
    color: black;
    font-size: 120px;
    position: relative;
    margin-bottom: 25%;
  }

  .name1 {
    position: absolute;
    animation-name: titleFont;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .name2 {
    position: absolute;
    font-family: 'Underdog', sans-serif;
    animation-name: titleFont2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .shakeWord {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .name3 {
    position: absolute;
    animation-name: titleFont3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 0.5%;
  }

  .intro2 {
    color: black;
    font-size: 40px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -25%;
  }

  .mintDiv {
    width: 40%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid black;
    box-shadow: black -5px 5px;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 28px;
    text-align: center;
    color: black;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .discord:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black -5px 5px;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black 5px 5px;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #fef8e2;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    box-shadow: black 0px 5px;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 174, 0);
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: black;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(37, 59, 23);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 85%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2 {
    background-color: #fef8e2;
    color: black;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 33px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid black;
    transition: transform .2s;
    box-shadow: black 0px 3px;
    border-radius: 10px;
    font-weight: bold;
  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 60px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background-image: linear-gradient(144deg, #a08fa1, #9895a4 50%, #9696a5);
    border: 0;
    box-shadow: black -5px 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    border-radius: 10px;
    transform: rotate(-3deg);
    border: 2px solid black;
    margin-top: 5%;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 70%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 2px solid #585858;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #2c2c2c;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }
  .ser {
    color: rgb(0, 0, 0);
    font-size: 30px;
  }

  .closeNote2 {
    position: fixed;
    right: 0;
    top: 5%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote2:hover,
  .closeNote2:focus {
    color: rgb(71, 70, 70);
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    display: none;

  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.856);
    position: fixed;
    width: 80%;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #414141;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #252525;
    z-index: 100000;
    padding: 5%;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    background-color: #ffffffb0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }
  .mainImage {
    position: relative;
    bottom: 0;

  }

  .beast1 {
    width: 480px;
    bottom: 0;
  }

  .beast2 {
    width: 480px;
    bottom: 0;
    position: relative;
    animation: mainImageAnimation ease 2s infinite;

  }

  @keyframes mainImageAnimation {
    0% {
      opacity: 0;
    }

    49% {

      opacity: 0;

    }

    50% {

      opacity: 1;

    }

    100% {
      opacity: 1;
    }
  }


  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 14s, 5348ms;
    animation-delay: 0ms, 3421ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(44vh) scale(0.62);
    }

    4% {
      transform: translateX(-4vw) translateY(50vh) scale(0.77);
    }

    8% {
      transform: translateX(43vw) translateY(-35vh) scale(0.85);
    }

    12% {
      transform: translateX(6vw) translateY(-16vh) scale(0.32);
    }

    16% {
      transform: translateX(40vw) translateY(-28vh) scale(0.61);
    }

    20% {
      transform: translateX(16vw) translateY(-33vh) scale(0.62);
    }

    24% {
      transform: translateX(-13vw) translateY(12vh) scale(0.99);
    }

    28% {
      transform: translateX(-6vw) translateY(15vh) scale(0.52);
    }

    32% {
      transform: translateX(39vw) translateY(40vh) scale(0.42);
    }

    36% {
      transform: translateX(-19vw) translateY(-31vh) scale(0.72);
    }

    40% {
      transform: translateX(47vw) translateY(18vh) scale(0.74);
    }

    44% {
      transform: translateX(-24vw) translateY(1vh) scale(0.8);
    }

    48% {
      transform: translateX(36vw) translateY(-6vh) scale(0.65);
    }

    52% {
      transform: translateX(-6vw) translateY(11vh) scale(0.28);
    }

    56% {
      transform: translateX(34vw) translateY(35vh) scale(0.79);
    }

    60% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.48);
    }

    64% {
      transform: translateX(33vw) translateY(-1vh) scale(0.47);
    }

    68% {
      transform: translateX(-45vw) translateY(-41vh) scale(0.54);
    }

    72% {
      transform: translateX(-20vw) translateY(40vh) scale(0.92);
    }

    76% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.64);
    }

    80% {
      transform: translateX(-23vw) translateY(-18vh) scale(0.92);
    }

    84% {
      transform: translateX(-37vw) translateY(7vh) scale(0.98);
    }

    88% {
      transform: translateX(-31vw) translateY(-35vh) scale(0.92);
    }

    92% {
      transform: translateX(1vw) translateY(-43vh) scale(0.29);
    }

    96% {
      transform: translateX(-41vw) translateY(-20vh) scale(0.44);
    }

    100% {
      transform: translateX(-4vw) translateY(-33vh) scale(0.38);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 11s, 6289ms;
    animation-delay: 0ms, 6547ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-17vw) translateY(40vh) scale(0.32);
    }

    3.5714285714% {
      transform: translateX(9vw) translateY(34vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-21vw) translateY(-5vh) scale(0.64);
    }

    10.7142857143% {
      transform: translateX(47vw) translateY(6vh) scale(0.76);
    }

    14.2857142857% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.93);
    }

    17.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.36);
    }

    21.4285714286% {
      transform: translateX(-34vw) translateY(38vh) scale(0.46);
    }

    25% {
      transform: translateX(-23vw) translateY(-42vh) scale(0.58);
    }

    28.5714285714% {
      transform: translateX(6vw) translateY(-31vh) scale(0.93);
    }

    32.1428571429% {
      transform: translateX(-37vw) translateY(31vh) scale(0.47);
    }

    35.7142857143% {
      transform: translateX(49vw) translateY(-37vh) scale(0.58);
    }

    39.2857142857% {
      transform: translateX(13vw) translateY(-42vh) scale(0.86);
    }

    42.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.48);
    }

    46.4285714286% {
      transform: translateX(-13vw) translateY(-13vh) scale(0.85);
    }

    50% {
      transform: translateX(7vw) translateY(49vh) scale(0.39);
    }

    53.5714285714% {
      transform: translateX(44vw) translateY(-15vh) scale(0.9);
    }

    57.1428571429% {
      transform: translateX(-2vw) translateY(-20vh) scale(0.27);
    }

    60.7142857143% {
      transform: translateX(-3vw) translateY(34vh) scale(0.51);
    }

    64.2857142857% {
      transform: translateX(-40vw) translateY(-5vh) scale(0.39);
    }

    67.8571428571% {
      transform: translateX(1vw) translateY(39vh) scale(0.53);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(-12vh) scale(0.45);
    }

    75% {
      transform: translateX(-17vw) translateY(-36vh) scale(0.63);
    }

    78.5714285714% {
      transform: translateX(27vw) translateY(36vh) scale(0.92);
    }

    82.1428571429% {
      transform: translateX(18vw) translateY(-5vh) scale(0.8);
    }

    85.7142857143% {
      transform: translateX(40vw) translateY(13vh) scale(0.92);
    }

    89.2857142857% {
      transform: translateX(3vw) translateY(-4vh) scale(0.33);
    }

    92.8571428571% {
      transform: translateX(-31vw) translateY(43vh) scale(0.71);
    }

    96.4285714286% {
      transform: translateX(-42vw) translateY(-18vh) scale(0.64);
    }

    100% {
      transform: translateX(24vw) translateY(14vh) scale(0.67);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 9s, 7991ms;
    animation-delay: 0ms, 1922ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-22vw) translateY(24vh) scale(0.62);
    }

    3.5714285714% {
      transform: translateX(-44vw) translateY(37vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-47vw) translateY(46vh) scale(0.91);
    }

    10.7142857143% {
      transform: translateX(39vw) translateY(15vh) scale(0.67);
    }

    14.2857142857% {
      transform: translateX(7vw) translateY(44vh) scale(0.73);
    }

    17.8571428571% {
      transform: translateX(40vw) translateY(11vh) scale(0.56);
    }

    21.4285714286% {
      transform: translateX(23vw) translateY(-30vh) scale(0.98);
    }

    25% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.4);
    }

    28.5714285714% {
      transform: translateX(31vw) translateY(30vh) scale(0.78);
    }

    32.1428571429% {
      transform: translateX(-10vw) translateY(-22vh) scale(0.7);
    }

    35.7142857143% {
      transform: translateX(-31vw) translateY(2vh) scale(0.87);
    }

    39.2857142857% {
      transform: translateX(45vw) translateY(-3vh) scale(0.33);
    }

    42.8571428571% {
      transform: translateX(-7vw) translateY(-30vh) scale(0.88);
    }

    46.4285714286% {
      transform: translateX(8vw) translateY(22vh) scale(0.51);
    }

    50% {
      transform: translateX(31vw) translateY(25vh) scale(0.31);
    }

    53.5714285714% {
      transform: translateX(47vw) translateY(13vh) scale(0.76);
    }

    57.1428571429% {
      transform: translateX(-19vw) translateY(7vh) scale(0.36);
    }

    60.7142857143% {
      transform: translateX(11vw) translateY(6vh) scale(0.83);
    }

    64.2857142857% {
      transform: translateX(48vw) translateY(-38vh) scale(0.62);
    }

    67.8571428571% {
      transform: translateX(41vw) translateY(-18vh) scale(0.68);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(50vh) scale(0.34);
    }

    75% {
      transform: translateX(-31vw) translateY(-15vh) scale(0.51);
    }

    78.5714285714% {
      transform: translateX(-41vw) translateY(9vh) scale(0.91);
    }

    82.1428571429% {
      transform: translateX(-39vw) translateY(16vh) scale(0.45);
    }

    85.7142857143% {
      transform: translateX(-44vw) translateY(9vh) scale(0.83);
    }

    89.2857142857% {
      transform: translateX(-41vw) translateY(8vh) scale(0.66);
    }

    92.8571428571% {
      transform: translateX(-44vw) translateY(42vh) scale(0.56);
    }

    96.4285714286% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.86);
    }

    100% {
      transform: translateX(-4vw) translateY(-8vh) scale(0.42);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 17s, 5968ms;
    animation-delay: 0ms, 1513ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(45vw) translateY(13vh) scale(0.5);
    }

    5.2631578947% {
      transform: translateX(37vw) translateY(14vh) scale(0.98);
    }

    10.5263157895% {
      transform: translateX(-6vw) translateY(-16vh) scale(0.7);
    }

    15.7894736842% {
      transform: translateX(-24vw) translateY(-47vh) scale(0.29);
    }

    21.0526315789% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.63);
    }

    26.3157894737% {
      transform: translateX(-11vw) translateY(-16vh) scale(0.49);
    }

    31.5789473684% {
      transform: translateX(-11vw) translateY(7vh) scale(0.45);
    }

    36.8421052632% {
      transform: translateX(13vw) translateY(-6vh) scale(0.34);
    }

    42.1052631579% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.37);
    }

    47.3684210526% {
      transform: translateX(5vw) translateY(25vh) scale(0.53);
    }

    52.6315789474% {
      transform: translateX(27vw) translateY(14vh) scale(0.74);
    }

    57.8947368421% {
      transform: translateX(-26vw) translateY(-28vh) scale(0.64);
    }

    63.1578947368% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.46);
    }

    68.4210526316% {
      transform: translateX(-23vw) translateY(17vh) scale(0.37);
    }

    73.6842105263% {
      transform: translateX(9vw) translateY(-6vh) scale(0.69);
    }

    78.9473684211% {
      transform: translateX(-41vw) translateY(-42vh) scale(0.75);
    }

    84.2105263158% {
      transform: translateX(34vw) translateY(1vh) scale(0.86);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-26vh) scale(0.91);
    }

    94.7368421053% {
      transform: translateX(-26vw) translateY(13vh) scale(0.49);
    }

    100% {
      transform: translateX(1vw) translateY(-1vh) scale(0.76);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 13s, 9409ms;
    animation-delay: 0ms, 3495ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(34vw) translateY(18vh) scale(0.64);
    }

    4.1666666667% {
      transform: translateX(34vw) translateY(11vh) scale(0.39);
    }

    8.3333333333% {
      transform: translateX(-37vw) translateY(-43vh) scale(0.47);
    }

    12.5% {
      transform: translateX(17vw) translateY(-5vh) scale(0.49);
    }

    16.6666666667% {
      transform: translateX(30vw) translateY(7vh) scale(0.47);
    }

    20.8333333333% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.76);
    }

    25% {
      transform: translateX(-20vw) translateY(28vh) scale(0.7);
    }

    29.1666666667% {
      transform: translateX(-16vw) translateY(-5vh) scale(0.84);
    }

    33.3333333333% {
      transform: translateX(-17vw) translateY(-41vh) scale(0.42);
    }

    37.5% {
      transform: translateX(19vw) translateY(-20vh) scale(0.81);
    }

    41.6666666667% {
      transform: translateX(12vw) translateY(15vh) scale(0.75);
    }

    45.8333333333% {
      transform: translateX(30vw) translateY(-32vh) scale(0.47);
    }

    50% {
      transform: translateX(-40vw) translateY(-38vh) scale(0.59);
    }

    54.1666666667% {
      transform: translateX(4vw) translateY(44vh) scale(0.82);
    }

    58.3333333333% {
      transform: translateX(44vw) translateY(-14vh) scale(0.55);
    }

    62.5% {
      transform: translateX(-15vw) translateY(39vh) scale(0.33);
    }

    66.6666666667% {
      transform: translateX(-28vw) translateY(-30vh) scale(0.29);
    }

    70.8333333333% {
      transform: translateX(40vw) translateY(27vh) scale(0.61);
    }

    75% {
      transform: translateX(22vw) translateY(-21vh) scale(0.53);
    }

    79.1666666667% {
      transform: translateX(-49vw) translateY(21vh) scale(0.64);
    }

    83.3333333333% {
      transform: translateX(7vw) translateY(14vh) scale(0.81);
    }

    87.5% {
      transform: translateX(12vw) translateY(14vh) scale(0.8);
    }

    91.6666666667% {
      transform: translateX(-44vw) translateY(-42vh) scale(0.49);
    }

    95.8333333333% {
      transform: translateX(43vw) translateY(41vh) scale(0.6);
    }

    100% {
      transform: translateX(6vw) translateY(-29vh) scale(0.7);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 10229ms;
    animation-delay: 0ms, 7081ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(23vw) translateY(18vh) scale(0.76);
    }

    4.1666666667% {
      transform: translateX(-26vw) translateY(6vh) scale(0.41);
    }

    8.3333333333% {
      transform: translateX(-1vw) translateY(-18vh) scale(0.63);
    }

    12.5% {
      transform: translateX(22vw) translateY(43vh) scale(0.63);
    }

    16.6666666667% {
      transform: translateX(25vw) translateY(48vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(3vw) translateY(-29vh) scale(0.33);
    }

    25% {
      transform: translateX(26vw) translateY(0vh) scale(0.86);
    }

    29.1666666667% {
      transform: translateX(-33vw) translateY(5vh) scale(0.51);
    }

    33.3333333333% {
      transform: translateX(4vw) translateY(-9vh) scale(0.39);
    }

    37.5% {
      transform: translateX(26vw) translateY(12vh) scale(0.65);
    }

    41.6666666667% {
      transform: translateX(31vw) translateY(18vh) scale(0.42);
    }

    45.8333333333% {
      transform: translateX(25vw) translateY(-45vh) scale(0.9);
    }

    50% {
      transform: translateX(-11vw) translateY(49vh) scale(0.46);
    }

    54.1666666667% {
      transform: translateX(-45vw) translateY(48vh) scale(0.81);
    }

    58.3333333333% {
      transform: translateX(46vw) translateY(5vh) scale(0.57);
    }

    62.5% {
      transform: translateX(35vw) translateY(-47vh) scale(0.56);
    }

    66.6666666667% {
      transform: translateX(22vw) translateY(-44vh) scale(0.83);
    }

    70.8333333333% {
      transform: translateX(-40vw) translateY(-11vh) scale(0.73);
    }

    75% {
      transform: translateX(-19vw) translateY(13vh) scale(0.69);
    }

    79.1666666667% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.83);
    }

    83.3333333333% {
      transform: translateX(33vw) translateY(21vh) scale(0.67);
    }

    87.5% {
      transform: translateX(-36vw) translateY(27vh) scale(0.79);
    }

    91.6666666667% {
      transform: translateX(5vw) translateY(17vh) scale(0.56);
    }

    95.8333333333% {
      transform: translateX(29vw) translateY(-10vh) scale(0.51);
    }

    100% {
      transform: translateX(-1vw) translateY(-14vh) scale(0.29);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 18s, 6470ms;
    animation-delay: 0ms, 8336ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(40vw) translateY(47vh) scale(1);
    }

    3.5714285714% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.78);
    }

    7.1428571429% {
      transform: translateX(24vw) translateY(17vh) scale(0.28);
    }

    10.7142857143% {
      transform: translateX(41vw) translateY(23vh) scale(0.96);
    }

    14.2857142857% {
      transform: translateX(14vw) translateY(13vh) scale(0.95);
    }

    17.8571428571% {
      transform: translateX(-17vw) translateY(49vh) scale(0.69);
    }

    21.4285714286% {
      transform: translateX(9vw) translateY(20vh) scale(0.29);
    }

    25% {
      transform: translateX(-11vw) translateY(30vh) scale(0.96);
    }

    28.5714285714% {
      transform: translateX(32vw) translateY(-27vh) scale(0.43);
    }

    32.1428571429% {
      transform: translateX(-48vw) translateY(-7vh) scale(0.9);
    }

    35.7142857143% {
      transform: translateX(-19vw) translateY(49vh) scale(0.41);
    }

    39.2857142857% {
      transform: translateX(7vw) translateY(-36vh) scale(0.5);
    }

    42.8571428571% {
      transform: translateX(-3vw) translateY(-40vh) scale(0.78);
    }

    46.4285714286% {
      transform: translateX(47vw) translateY(6vh) scale(0.26);
    }

    50% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.3);
    }

    53.5714285714% {
      transform: translateX(-38vw) translateY(-10vh) scale(0.71);
    }

    57.1428571429% {
      transform: translateX(36vw) translateY(-4vh) scale(0.96);
    }

    60.7142857143% {
      transform: translateX(16vw) translateY(6vh) scale(0.62);
    }

    64.2857142857% {
      transform: translateX(-30vw) translateY(-39vh) scale(0.52);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(33vh) scale(0.45);
    }

    71.4285714286% {
      transform: translateX(-10vw) translateY(-28vh) scale(0.78);
    }

    75% {
      transform: translateX(48vw) translateY(16vh) scale(0.71);
    }

    78.5714285714% {
      transform: translateX(-45vw) translateY(-39vh) scale(0.63);
    }

    82.1428571429% {
      transform: translateX(22vw) translateY(-49vh) scale(0.37);
    }

    85.7142857143% {
      transform: translateX(49vw) translateY(27vh) scale(0.26);
    }

    89.2857142857% {
      transform: translateX(23vw) translateY(-44vh) scale(0.73);
    }

    92.8571428571% {
      transform: translateX(-2vw) translateY(29vh) scale(0.47);
    }

    96.4285714286% {
      transform: translateX(-2vw) translateY(-30vh) scale(0.57);
    }

    100% {
      transform: translateX(-10vw) translateY(-29vh) scale(0.96);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 15s, 6398ms;
    animation-delay: 0ms, 565ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(8vw) translateY(-41vh) scale(0.92);
    }

    4.5454545455% {
      transform: translateX(-26vw) translateY(43vh) scale(0.97);
    }

    9.0909090909% {
      transform: translateX(38vw) translateY(-44vh) scale(0.43);
    }

    13.6363636364% {
      transform: translateX(-35vw) translateY(40vh) scale(0.78);
    }

    18.1818181818% {
      transform: translateX(-7vw) translateY(-35vh) scale(0.68);
    }

    22.7272727273% {
      transform: translateX(-43vw) translateY(-29vh) scale(0.48);
    }

    27.2727272727% {
      transform: translateX(-18vw) translateY(27vh) scale(0.7);
    }

    31.8181818182% {
      transform: translateX(42vw) translateY(-45vh) scale(0.77);
    }

    36.3636363636% {
      transform: translateX(1vw) translateY(-13vh) scale(0.84);
    }

    40.9090909091% {
      transform: translateX(35vw) translateY(-26vh) scale(0.84);
    }

    45.4545454545% {
      transform: translateX(-3vw) translateY(48vh) scale(0.29);
    }

    50% {
      transform: translateX(9vw) translateY(-4vh) scale(0.99);
    }

    54.5454545455% {
      transform: translateX(-36vw) translateY(-36vh) scale(0.5);
    }

    59.0909090909% {
      transform: translateX(42vw) translateY(-44vh) scale(0.39);
    }

    63.6363636364% {
      transform: translateX(26vw) translateY(20vh) scale(0.76);
    }

    68.1818181818% {
      transform: translateX(30vw) translateY(48vh) scale(0.9);
    }

    72.7272727273% {
      transform: translateX(-9vw) translateY(35vh) scale(0.97);
    }

    77.2727272727% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.53);
    }

    81.8181818182% {
      transform: translateX(-49vw) translateY(-29vh) scale(0.26);
    }

    86.3636363636% {
      transform: translateX(-24vw) translateY(36vh) scale(0.36);
    }

    90.9090909091% {
      transform: translateX(-33vw) translateY(20vh) scale(0.52);
    }

    95.4545454545% {
      transform: translateX(14vw) translateY(10vh) scale(0.8);
    }

    100% {
      transform: translateX(14vw) translateY(-30vh) scale(0.54);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 15s, 5044ms;
    animation-delay: 0ms, 1556ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(27vw) translateY(-37vh) scale(0.9);
    }

    4.1666666667% {
      transform: translateX(12vw) translateY(-22vh) scale(0.44);
    }

    8.3333333333% {
      transform: translateX(-13vw) translateY(-28vh) scale(0.53);
    }

    12.5% {
      transform: translateX(-43vw) translateY(18vh) scale(0.39);
    }

    16.6666666667% {
      transform: translateX(34vw) translateY(31vh) scale(0.97);
    }

    20.8333333333% {
      transform: translateX(47vw) translateY(-33vh) scale(0.97);
    }

    25% {
      transform: translateX(35vw) translateY(-47vh) scale(0.42);
    }

    29.1666666667% {
      transform: translateX(-47vw) translateY(39vh) scale(0.4);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-45vh) scale(0.65);
    }

    37.5% {
      transform: translateX(43vw) translateY(-23vh) scale(0.6);
    }

    41.6666666667% {
      transform: translateX(38vw) translateY(15vh) scale(0.63);
    }

    45.8333333333% {
      transform: translateX(49vw) translateY(34vh) scale(0.82);
    }

    50% {
      transform: translateX(-30vw) translateY(-9vh) scale(0.91);
    }

    54.1666666667% {
      transform: translateX(18vw) translateY(-31vh) scale(0.73);
    }

    58.3333333333% {
      transform: translateX(32vw) translateY(-11vh) scale(0.97);
    }

    62.5% {
      transform: translateX(-12vw) translateY(31vh) scale(0.77);
    }

    66.6666666667% {
      transform: translateX(26vw) translateY(12vh) scale(0.84);
    }

    70.8333333333% {
      transform: translateX(2vw) translateY(-46vh) scale(0.38);
    }

    75% {
      transform: translateX(46vw) translateY(35vh) scale(0.79);
    }

    79.1666666667% {
      transform: translateX(50vw) translateY(26vh) scale(0.46);
    }

    83.3333333333% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.48);
    }

    87.5% {
      transform: translateX(6vw) translateY(-18vh) scale(0.78);
    }

    91.6666666667% {
      transform: translateX(33vw) translateY(-31vh) scale(0.75);
    }

    95.8333333333% {
      transform: translateX(27vw) translateY(-13vh) scale(0.52);
    }

    100% {
      transform: translateX(4vw) translateY(11vh) scale(0.86);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 5550ms;
    animation-delay: 0ms, 4125ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-26vw) translateY(-38vh) scale(0.49);
    }

    5.5555555556% {
      transform: translateX(21vw) translateY(7vh) scale(0.8);
    }

    11.1111111111% {
      transform: translateX(-43vw) translateY(-2vh) scale(0.75);
    }

    16.6666666667% {
      transform: translateX(29vw) translateY(-17vh) scale(1);
    }

    22.2222222222% {
      transform: translateX(-43vw) translateY(13vh) scale(0.88);
    }

    27.7777777778% {
      transform: translateX(33vw) translateY(-48vh) scale(0.86);
    }

    33.3333333333% {
      transform: translateX(17vw) translateY(-23vh) scale(0.95);
    }

    38.8888888889% {
      transform: translateX(-7vw) translateY(39vh) scale(0.4);
    }

    44.4444444444% {
      transform: translateX(-44vw) translateY(-35vh) scale(0.39);
    }

    50% {
      transform: translateX(-23vw) translateY(-10vh) scale(0.54);
    }

    55.5555555556% {
      transform: translateX(8vw) translateY(39vh) scale(0.63);
    }

    61.1111111111% {
      transform: translateX(-3vw) translateY(28vh) scale(0.46);
    }

    66.6666666667% {
      transform: translateX(19vw) translateY(-35vh) scale(0.55);
    }

    72.2222222222% {
      transform: translateX(-32vw) translateY(1vh) scale(0.87);
    }

    77.7777777778% {
      transform: translateX(-18vw) translateY(11vh) scale(0.62);
    }

    83.3333333333% {
      transform: translateX(-24vw) translateY(-31vh) scale(0.42);
    }

    88.8888888889% {
      transform: translateX(5vw) translateY(34vh) scale(0.5);
    }

    94.4444444444% {
      transform: translateX(-11vw) translateY(32vh) scale(0.36);
    }

    100% {
      transform: translateX(5vw) translateY(-8vh) scale(0.99);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 16s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 16s, 10297ms;
    animation-delay: 0ms, 4707ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-36vw) translateY(-2vh) scale(0.37);
    }

    4.7619047619% {
      transform: translateX(-27vw) translateY(30vh) scale(0.38);
    }

    9.5238095238% {
      transform: translateX(45vw) translateY(-23vh) scale(0.33);
    }

    14.2857142857% {
      transform: translateX(25vw) translateY(9vh) scale(0.7);
    }

    19.0476190476% {
      transform: translateX(24vw) translateY(-13vh) scale(0.48);
    }

    23.8095238095% {
      transform: translateX(43vw) translateY(-5vh) scale(0.68);
    }

    28.5714285714% {
      transform: translateX(41vw) translateY(2vh) scale(0.83);
    }

    33.3333333333% {
      transform: translateX(-46vw) translateY(-13vh) scale(0.7);
    }

    38.0952380952% {
      transform: translateX(-39vw) translateY(-25vh) scale(0.85);
    }

    42.8571428571% {
      transform: translateX(-27vw) translateY(23vh) scale(0.33);
    }

    47.619047619% {
      transform: translateX(-13vw) translateY(45vh) scale(0.3);
    }

    52.380952381% {
      transform: translateX(10vw) translateY(-28vh) scale(0.55);
    }

    57.1428571429% {
      transform: translateX(-30vw) translateY(-16vh) scale(0.26);
    }

    61.9047619048% {
      transform: translateX(40vw) translateY(30vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(45vw) translateY(37vh) scale(0.79);
    }

    71.4285714286% {
      transform: translateX(-13vw) translateY(-24vh) scale(0.62);
    }

    76.1904761905% {
      transform: translateX(33vw) translateY(-12vh) scale(0.94);
    }

    80.9523809524% {
      transform: translateX(10vw) translateY(-49vh) scale(0.3);
    }

    85.7142857143% {
      transform: translateX(-15vw) translateY(48vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(-42vw) translateY(40vh) scale(0.54);
    }

    95.2380952381% {
      transform: translateX(-22vw) translateY(0vh) scale(0.63);
    }

    100% {
      transform: translateX(32vw) translateY(14vh) scale(0.79);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 9s, 8018ms;
    animation-delay: 0ms, 4673ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(-30vw) translateY(47vh) scale(0.57);
    }

    3.5714285714% {
      transform: translateX(-19vw) translateY(19vh) scale(0.52);
    }

    7.1428571429% {
      transform: translateX(-14vw) translateY(48vh) scale(0.9);
    }

    10.7142857143% {
      transform: translateX(-44vw) translateY(13vh) scale(0.38);
    }

    14.2857142857% {
      transform: translateX(-10vw) translateY(43vh) scale(0.49);
    }

    17.8571428571% {
      transform: translateX(39vw) translateY(49vh) scale(0.79);
    }

    21.4285714286% {
      transform: translateX(50vw) translateY(-17vh) scale(0.4);
    }

    25% {
      transform: translateX(-20vw) translateY(22vh) scale(0.48);
    }

    28.5714285714% {
      transform: translateX(-27vw) translateY(32vh) scale(0.92);
    }

    32.1428571429% {
      transform: translateX(11vw) translateY(-7vh) scale(0.99);
    }

    35.7142857143% {
      transform: translateX(-27vw) translateY(46vh) scale(0.34);
    }

    39.2857142857% {
      transform: translateX(-24vw) translateY(3vh) scale(0.36);
    }

    42.8571428571% {
      transform: translateX(48vw) translateY(-30vh) scale(0.33);
    }

    46.4285714286% {
      transform: translateX(-44vw) translateY(-30vh) scale(0.5);
    }

    50% {
      transform: translateX(44vw) translateY(-2vh) scale(0.57);
    }

    53.5714285714% {
      transform: translateX(-47vw) translateY(-18vh) scale(0.39);
    }

    57.1428571429% {
      transform: translateX(45vw) translateY(24vh) scale(0.54);
    }

    60.7142857143% {
      transform: translateX(-32vw) translateY(14vh) scale(0.64);
    }

    64.2857142857% {
      transform: translateX(-24vw) translateY(37vh) scale(0.81);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(28vh) scale(0.67);
    }

    71.4285714286% {
      transform: translateX(11vw) translateY(-14vh) scale(0.71);
    }

    75% {
      transform: translateX(-28vw) translateY(-6vh) scale(0.47);
    }

    78.5714285714% {
      transform: translateX(15vw) translateY(-15vh) scale(0.79);
    }

    82.1428571429% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.51);
    }

    85.7142857143% {
      transform: translateX(43vw) translateY(47vh) scale(0.52);
    }

    89.2857142857% {
      transform: translateX(35vw) translateY(-38vh) scale(0.62);
    }

    92.8571428571% {
      transform: translateX(22vw) translateY(-44vh) scale(1);
    }

    96.4285714286% {
      transform: translateX(5vw) translateY(31vh) scale(0.33);
    }

    100% {
      transform: translateX(3vw) translateY(39vh) scale(0.63);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 10s, 9679ms;
    animation-delay: 0ms, 8051ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(22vw) translateY(-45vh) scale(0.86);
    }

    4.1666666667% {
      transform: translateX(20vw) translateY(6vh) scale(0.73);
    }

    8.3333333333% {
      transform: translateX(-36vw) translateY(15vh) scale(0.72);
    }

    12.5% {
      transform: translateX(25vw) translateY(-1vh) scale(0.38);
    }

    16.6666666667% {
      transform: translateX(-19vw) translateY(-28vh) scale(0.91);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(29vh) scale(0.47);
    }

    25% {
      transform: translateX(0vw) translateY(14vh) scale(0.55);
    }

    29.1666666667% {
      transform: translateX(-41vw) translateY(-28vh) scale(0.39);
    }

    33.3333333333% {
      transform: translateX(-11vw) translateY(34vh) scale(0.37);
    }

    37.5% {
      transform: translateX(-10vw) translateY(38vh) scale(0.75);
    }

    41.6666666667% {
      transform: translateX(-3vw) translateY(7vh) scale(0.47);
    }

    45.8333333333% {
      transform: translateX(22vw) translateY(-11vh) scale(0.97);
    }

    50% {
      transform: translateX(41vw) translateY(20vh) scale(0.37);
    }

    54.1666666667% {
      transform: translateX(23vw) translateY(-14vh) scale(0.41);
    }

    58.3333333333% {
      transform: translateX(43vw) translateY(-30vh) scale(0.34);
    }

    62.5% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(-21vw) translateY(-11vh) scale(0.42);
    }

    70.8333333333% {
      transform: translateX(47vw) translateY(11vh) scale(0.29);
    }

    75% {
      transform: translateX(20vw) translateY(-27vh) scale(0.4);
    }

    79.1666666667% {
      transform: translateX(-47vw) translateY(37vh) scale(0.52);
    }

    83.3333333333% {
      transform: translateX(0vw) translateY(3vh) scale(0.44);
    }

    87.5% {
      transform: translateX(27vw) translateY(47vh) scale(0.88);
    }

    91.6666666667% {
      transform: translateX(-5vw) translateY(3vh) scale(0.82);
    }

    95.8333333333% {
      transform: translateX(16vw) translateY(10vh) scale(0.61);
    }

    100% {
      transform: translateX(5vw) translateY(32vh) scale(0.33);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 18s, 8910ms;
    animation-delay: 0ms, 3832ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(21vw) translateY(3vh) scale(0.42);
    }

    4.347826087% {
      transform: translateX(-30vw) translateY(-40vh) scale(0.96);
    }

    8.6956521739% {
      transform: translateX(-26vw) translateY(-40vh) scale(0.82);
    }

    13.0434782609% {
      transform: translateX(-19vw) translateY(-2vh) scale(0.72);
    }

    17.3913043478% {
      transform: translateX(-49vw) translateY(36vh) scale(0.31);
    }

    21.7391304348% {
      transform: translateX(4vw) translateY(-6vh) scale(0.52);
    }

    26.0869565217% {
      transform: translateX(-16vw) translateY(40vh) scale(0.96);
    }

    30.4347826087% {
      transform: translateX(15vw) translateY(15vh) scale(0.9);
    }

    34.7826086957% {
      transform: translateX(26vw) translateY(-6vh) scale(0.28);
    }

    39.1304347826% {
      transform: translateX(9vw) translateY(46vh) scale(0.35);
    }

    43.4782608696% {
      transform: translateX(-25vw) translateY(19vh) scale(0.79);
    }

    47.8260869565% {
      transform: translateX(-33vw) translateY(46vh) scale(0.74);
    }

    52.1739130435% {
      transform: translateX(-22vw) translateY(1vh) scale(0.36);
    }

    56.5217391304% {
      transform: translateX(-49vw) translateY(9vh) scale(0.52);
    }

    60.8695652174% {
      transform: translateX(-29vw) translateY(39vh) scale(0.7);
    }

    65.2173913043% {
      transform: translateX(25vw) translateY(31vh) scale(0.34);
    }

    69.5652173913% {
      transform: translateX(19vw) translateY(18vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(-25vw) translateY(-25vh) scale(0.71);
    }

    78.2608695652% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.77);
    }

    82.6086956522% {
      transform: translateX(31vw) translateY(18vh) scale(0.41);
    }

    86.9565217391% {
      transform: translateX(41vw) translateY(-27vh) scale(0.5);
    }

    91.3043478261% {
      transform: translateX(37vw) translateY(12vh) scale(0.78);
    }

    95.652173913% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.68);
    }

    100% {
      transform: translateX(31vw) translateY(30vh) scale(0.96);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 17s, 8620ms;
    animation-delay: 0ms, 742ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(30vw) translateY(29vh) scale(0.57);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(-47vh) scale(0.3);
    }

    9.5238095238% {
      transform: translateX(33vw) translateY(46vh) scale(0.27);
    }

    14.2857142857% {
      transform: translateX(46vw) translateY(7vh) scale(0.82);
    }

    19.0476190476% {
      transform: translateX(-25vw) translateY(41vh) scale(0.87);
    }

    23.8095238095% {
      transform: translateX(-46vw) translateY(22vh) scale(0.99);
    }

    28.5714285714% {
      transform: translateX(4vw) translateY(11vh) scale(0.59);
    }

    33.3333333333% {
      transform: translateX(-43vw) translateY(20vh) scale(0.97);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(-18vh) scale(0.79);
    }

    42.8571428571% {
      transform: translateX(18vw) translateY(-7vh) scale(0.4);
    }

    47.619047619% {
      transform: translateX(-10vw) translateY(-36vh) scale(1);
    }

    52.380952381% {
      transform: translateX(-4vw) translateY(28vh) scale(0.8);
    }

    57.1428571429% {
      transform: translateX(-43vw) translateY(50vh) scale(0.99);
    }

    61.9047619048% {
      transform: translateX(30vw) translateY(-40vh) scale(0.99);
    }

    66.6666666667% {
      transform: translateX(48vw) translateY(-7vh) scale(0.73);
    }

    71.4285714286% {
      transform: translateX(18vw) translateY(-19vh) scale(0.47);
    }

    76.1904761905% {
      transform: translateX(-14vw) translateY(-35vh) scale(0.71);
    }

    80.9523809524% {
      transform: translateX(34vw) translateY(17vh) scale(0.97);
    }

    85.7142857143% {
      transform: translateX(48vw) translateY(-29vh) scale(0.88);
    }

    90.4761904762% {
      transform: translateX(44vw) translateY(46vh) scale(0.99);
    }

    95.2380952381% {
      transform: translateX(22vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(-3vw) translateY(-48vh) scale(0.54);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw #FFF01F;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw #FFF01F;
    }
  }

  .projectName {
    width: 50%;
  }

  .beastDiv {}

  .beast {
    width: 750px;
  }

  .fire {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0px;
  }

  .fire4 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire4 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -130px;
    top: 0px;
  }

  .fire5 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire5 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    top: 0px;
  }

  .fire6 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire6 {
    width: 75px;
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .fire2 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire2 {
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire2 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 {
    width: 60px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }


  .flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
  }

  .flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  .flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
  }

  .flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
  }

  .flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
  }

  .flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
  }

  .flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
  }

  @keyframes flameWobble {
    50% {
      transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
  }

  @keyframes flamefly {
    0% {
      transform: translate(0) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(-20px, -100px) rotate(180deg);
      opacity: 0;
    }
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 22px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: black;
    text-shadow: 0 0 5px black;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {}

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 55px;
    height: 55px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 35px;
    color: black;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  @keyframes titleFont {
    0% {
      opacity: 1;
    }

    34% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    64% {
      opacity: 0;
    }

    65% {
      opacity: 1;
    }

    84% {
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleFont2 {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    70% {
      opacity: 1
    }

    71% {
      opacity: 0
    }

    85% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .intro {
    color: black;
    font-size: 120px;
    position: relative;
    margin-bottom: 25%;
  }

  .name1 {
    position: absolute;
    animation-name: titleFont;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .name2 {
    position: absolute;
    font-family: 'Underdog', sans-serif;
    animation-name: titleFont2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .shakeWord {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .name3 {
    position: absolute;
    animation-name: titleFont3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 0.5%;
  }

  .intro2 {
    color: black;
    font-size: 40px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -25%;
  }

  .mintDiv {
    width: 40%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid black;
    box-shadow: black -5px 5px;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .discord:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black -5px 5px;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black 5px 5px;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #fef8e2;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    box-shadow: black 0px 5px;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 174, 0);
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: black;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(37, 59, 23);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 85%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2 {
    background-color: #fef8e2;
    color: black;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 33px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid black;
    transition: transform .2s;
    box-shadow: black 0px 3px;
    border-radius: 10px;
    font-weight: bold;
  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 60px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background-image: linear-gradient(144deg, #a08fa1, #9895a4 50%, #9696a5);
    border: 0;
    box-shadow: black -5px 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    border-radius: 10px;
    transform: rotate(-3deg);
    border: 2px solid black;
    margin-top: 5%;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 70%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 2px solid #585858;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #2c2c2c;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 3840px) {

  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }
  .ser {
    color: rgb(0, 0, 0);
    font-size: 30px;
  }

  .closeNote2 {
    position: fixed;
    right: 0;
    top: 5%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote2:hover,
  .closeNote2:focus {
    color: rgb(71, 70, 70);
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
    display: none;

  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }


  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.856);
    position: fixed;
    width: 80%;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid #414141;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 15px #252525;
    z-index: 100000;
    padding: 5%;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    background-color: #ffffffb0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }


  .icons{
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: max-content;
  }
  .mainImage {
    position: relative;
    bottom: 0;

  }

  .beast1 {
    width: 480px;
    bottom: 0;
  }

  .beast2 {
    width: 480px;
    bottom: 0;
    position: relative;
    animation: mainImageAnimation ease 2s infinite;

  }

  @keyframes mainImageAnimation {
    0% {
      opacity: 0;
    }

    49% {

      opacity: 0;

    }

    50% {

      opacity: 1;

    }

    100% {
      opacity: 1;
    }
  }


  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }

  .firefly::before,
  .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
    animation: drift ease alternate infinite, flash ease infinite;
  }

  .firefly:nth-child(1) {
    animation-name: move1;
  }

  .firefly:nth-child(1)::before {
    animation-duration: 14s;
  }

  .firefly:nth-child(1)::after {
    animation-duration: 14s, 5348ms;
    animation-delay: 0ms, 3421ms;
  }

  @keyframes move1 {
    0% {
      transform: translateX(-41vw) translateY(44vh) scale(0.62);
    }

    4% {
      transform: translateX(-4vw) translateY(50vh) scale(0.77);
    }

    8% {
      transform: translateX(43vw) translateY(-35vh) scale(0.85);
    }

    12% {
      transform: translateX(6vw) translateY(-16vh) scale(0.32);
    }

    16% {
      transform: translateX(40vw) translateY(-28vh) scale(0.61);
    }

    20% {
      transform: translateX(16vw) translateY(-33vh) scale(0.62);
    }

    24% {
      transform: translateX(-13vw) translateY(12vh) scale(0.99);
    }

    28% {
      transform: translateX(-6vw) translateY(15vh) scale(0.52);
    }

    32% {
      transform: translateX(39vw) translateY(40vh) scale(0.42);
    }

    36% {
      transform: translateX(-19vw) translateY(-31vh) scale(0.72);
    }

    40% {
      transform: translateX(47vw) translateY(18vh) scale(0.74);
    }

    44% {
      transform: translateX(-24vw) translateY(1vh) scale(0.8);
    }

    48% {
      transform: translateX(36vw) translateY(-6vh) scale(0.65);
    }

    52% {
      transform: translateX(-6vw) translateY(11vh) scale(0.28);
    }

    56% {
      transform: translateX(34vw) translateY(35vh) scale(0.79);
    }

    60% {
      transform: translateX(-33vw) translateY(-35vh) scale(0.48);
    }

    64% {
      transform: translateX(33vw) translateY(-1vh) scale(0.47);
    }

    68% {
      transform: translateX(-45vw) translateY(-41vh) scale(0.54);
    }

    72% {
      transform: translateX(-20vw) translateY(40vh) scale(0.92);
    }

    76% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.64);
    }

    80% {
      transform: translateX(-23vw) translateY(-18vh) scale(0.92);
    }

    84% {
      transform: translateX(-37vw) translateY(7vh) scale(0.98);
    }

    88% {
      transform: translateX(-31vw) translateY(-35vh) scale(0.92);
    }

    92% {
      transform: translateX(1vw) translateY(-43vh) scale(0.29);
    }

    96% {
      transform: translateX(-41vw) translateY(-20vh) scale(0.44);
    }

    100% {
      transform: translateX(-4vw) translateY(-33vh) scale(0.38);
    }
  }

  .firefly:nth-child(2) {
    animation-name: move2;
  }

  .firefly:nth-child(2)::before {
    animation-duration: 11s;
  }

  .firefly:nth-child(2)::after {
    animation-duration: 11s, 6289ms;
    animation-delay: 0ms, 6547ms;
  }

  @keyframes move2 {
    0% {
      transform: translateX(-17vw) translateY(40vh) scale(0.32);
    }

    3.5714285714% {
      transform: translateX(9vw) translateY(34vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-21vw) translateY(-5vh) scale(0.64);
    }

    10.7142857143% {
      transform: translateX(47vw) translateY(6vh) scale(0.76);
    }

    14.2857142857% {
      transform: translateX(-16vw) translateY(-25vh) scale(0.93);
    }

    17.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.36);
    }

    21.4285714286% {
      transform: translateX(-34vw) translateY(38vh) scale(0.46);
    }

    25% {
      transform: translateX(-23vw) translateY(-42vh) scale(0.58);
    }

    28.5714285714% {
      transform: translateX(6vw) translateY(-31vh) scale(0.93);
    }

    32.1428571429% {
      transform: translateX(-37vw) translateY(31vh) scale(0.47);
    }

    35.7142857143% {
      transform: translateX(49vw) translateY(-37vh) scale(0.58);
    }

    39.2857142857% {
      transform: translateX(13vw) translateY(-42vh) scale(0.86);
    }

    42.8571428571% {
      transform: translateX(34vw) translateY(41vh) scale(0.48);
    }

    46.4285714286% {
      transform: translateX(-13vw) translateY(-13vh) scale(0.85);
    }

    50% {
      transform: translateX(7vw) translateY(49vh) scale(0.39);
    }

    53.5714285714% {
      transform: translateX(44vw) translateY(-15vh) scale(0.9);
    }

    57.1428571429% {
      transform: translateX(-2vw) translateY(-20vh) scale(0.27);
    }

    60.7142857143% {
      transform: translateX(-3vw) translateY(34vh) scale(0.51);
    }

    64.2857142857% {
      transform: translateX(-40vw) translateY(-5vh) scale(0.39);
    }

    67.8571428571% {
      transform: translateX(1vw) translateY(39vh) scale(0.53);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(-12vh) scale(0.45);
    }

    75% {
      transform: translateX(-17vw) translateY(-36vh) scale(0.63);
    }

    78.5714285714% {
      transform: translateX(27vw) translateY(36vh) scale(0.92);
    }

    82.1428571429% {
      transform: translateX(18vw) translateY(-5vh) scale(0.8);
    }

    85.7142857143% {
      transform: translateX(40vw) translateY(13vh) scale(0.92);
    }

    89.2857142857% {
      transform: translateX(3vw) translateY(-4vh) scale(0.33);
    }

    92.8571428571% {
      transform: translateX(-31vw) translateY(43vh) scale(0.71);
    }

    96.4285714286% {
      transform: translateX(-42vw) translateY(-18vh) scale(0.64);
    }

    100% {
      transform: translateX(24vw) translateY(14vh) scale(0.67);
    }
  }

  .firefly:nth-child(3) {
    animation-name: move3;
  }

  .firefly:nth-child(3)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(3)::after {
    animation-duration: 9s, 7991ms;
    animation-delay: 0ms, 1922ms;
  }

  @keyframes move3 {
    0% {
      transform: translateX(-22vw) translateY(24vh) scale(0.62);
    }

    3.5714285714% {
      transform: translateX(-44vw) translateY(37vh) scale(0.67);
    }

    7.1428571429% {
      transform: translateX(-47vw) translateY(46vh) scale(0.91);
    }

    10.7142857143% {
      transform: translateX(39vw) translateY(15vh) scale(0.67);
    }

    14.2857142857% {
      transform: translateX(7vw) translateY(44vh) scale(0.73);
    }

    17.8571428571% {
      transform: translateX(40vw) translateY(11vh) scale(0.56);
    }

    21.4285714286% {
      transform: translateX(23vw) translateY(-30vh) scale(0.98);
    }

    25% {
      transform: translateX(-14vw) translateY(-47vh) scale(0.4);
    }

    28.5714285714% {
      transform: translateX(31vw) translateY(30vh) scale(0.78);
    }

    32.1428571429% {
      transform: translateX(-10vw) translateY(-22vh) scale(0.7);
    }

    35.7142857143% {
      transform: translateX(-31vw) translateY(2vh) scale(0.87);
    }

    39.2857142857% {
      transform: translateX(45vw) translateY(-3vh) scale(0.33);
    }

    42.8571428571% {
      transform: translateX(-7vw) translateY(-30vh) scale(0.88);
    }

    46.4285714286% {
      transform: translateX(8vw) translateY(22vh) scale(0.51);
    }

    50% {
      transform: translateX(31vw) translateY(25vh) scale(0.31);
    }

    53.5714285714% {
      transform: translateX(47vw) translateY(13vh) scale(0.76);
    }

    57.1428571429% {
      transform: translateX(-19vw) translateY(7vh) scale(0.36);
    }

    60.7142857143% {
      transform: translateX(11vw) translateY(6vh) scale(0.83);
    }

    64.2857142857% {
      transform: translateX(48vw) translateY(-38vh) scale(0.62);
    }

    67.8571428571% {
      transform: translateX(41vw) translateY(-18vh) scale(0.68);
    }

    71.4285714286% {
      transform: translateX(-42vw) translateY(50vh) scale(0.34);
    }

    75% {
      transform: translateX(-31vw) translateY(-15vh) scale(0.51);
    }

    78.5714285714% {
      transform: translateX(-41vw) translateY(9vh) scale(0.91);
    }

    82.1428571429% {
      transform: translateX(-39vw) translateY(16vh) scale(0.45);
    }

    85.7142857143% {
      transform: translateX(-44vw) translateY(9vh) scale(0.83);
    }

    89.2857142857% {
      transform: translateX(-41vw) translateY(8vh) scale(0.66);
    }

    92.8571428571% {
      transform: translateX(-44vw) translateY(42vh) scale(0.56);
    }

    96.4285714286% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.86);
    }

    100% {
      transform: translateX(-4vw) translateY(-8vh) scale(0.42);
    }
  }

  .firefly:nth-child(4) {
    animation-name: move4;
  }

  .firefly:nth-child(4)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(4)::after {
    animation-duration: 17s, 5968ms;
    animation-delay: 0ms, 1513ms;
  }

  @keyframes move4 {
    0% {
      transform: translateX(45vw) translateY(13vh) scale(0.5);
    }

    5.2631578947% {
      transform: translateX(37vw) translateY(14vh) scale(0.98);
    }

    10.5263157895% {
      transform: translateX(-6vw) translateY(-16vh) scale(0.7);
    }

    15.7894736842% {
      transform: translateX(-24vw) translateY(-47vh) scale(0.29);
    }

    21.0526315789% {
      transform: translateX(-22vw) translateY(-34vh) scale(0.63);
    }

    26.3157894737% {
      transform: translateX(-11vw) translateY(-16vh) scale(0.49);
    }

    31.5789473684% {
      transform: translateX(-11vw) translateY(7vh) scale(0.45);
    }

    36.8421052632% {
      transform: translateX(13vw) translateY(-6vh) scale(0.34);
    }

    42.1052631579% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.37);
    }

    47.3684210526% {
      transform: translateX(5vw) translateY(25vh) scale(0.53);
    }

    52.6315789474% {
      transform: translateX(27vw) translateY(14vh) scale(0.74);
    }

    57.8947368421% {
      transform: translateX(-26vw) translateY(-28vh) scale(0.64);
    }

    63.1578947368% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.46);
    }

    68.4210526316% {
      transform: translateX(-23vw) translateY(17vh) scale(0.37);
    }

    73.6842105263% {
      transform: translateX(9vw) translateY(-6vh) scale(0.69);
    }

    78.9473684211% {
      transform: translateX(-41vw) translateY(-42vh) scale(0.75);
    }

    84.2105263158% {
      transform: translateX(34vw) translateY(1vh) scale(0.86);
    }

    89.4736842105% {
      transform: translateX(22vw) translateY(-26vh) scale(0.91);
    }

    94.7368421053% {
      transform: translateX(-26vw) translateY(13vh) scale(0.49);
    }

    100% {
      transform: translateX(1vw) translateY(-1vh) scale(0.76);
    }
  }

  .firefly:nth-child(5) {
    animation-name: move5;
  }

  .firefly:nth-child(5)::before {
    animation-duration: 13s;
  }

  .firefly:nth-child(5)::after {
    animation-duration: 13s, 9409ms;
    animation-delay: 0ms, 3495ms;
  }

  @keyframes move5 {
    0% {
      transform: translateX(34vw) translateY(18vh) scale(0.64);
    }

    4.1666666667% {
      transform: translateX(34vw) translateY(11vh) scale(0.39);
    }

    8.3333333333% {
      transform: translateX(-37vw) translateY(-43vh) scale(0.47);
    }

    12.5% {
      transform: translateX(17vw) translateY(-5vh) scale(0.49);
    }

    16.6666666667% {
      transform: translateX(30vw) translateY(7vh) scale(0.47);
    }

    20.8333333333% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.76);
    }

    25% {
      transform: translateX(-20vw) translateY(28vh) scale(0.7);
    }

    29.1666666667% {
      transform: translateX(-16vw) translateY(-5vh) scale(0.84);
    }

    33.3333333333% {
      transform: translateX(-17vw) translateY(-41vh) scale(0.42);
    }

    37.5% {
      transform: translateX(19vw) translateY(-20vh) scale(0.81);
    }

    41.6666666667% {
      transform: translateX(12vw) translateY(15vh) scale(0.75);
    }

    45.8333333333% {
      transform: translateX(30vw) translateY(-32vh) scale(0.47);
    }

    50% {
      transform: translateX(-40vw) translateY(-38vh) scale(0.59);
    }

    54.1666666667% {
      transform: translateX(4vw) translateY(44vh) scale(0.82);
    }

    58.3333333333% {
      transform: translateX(44vw) translateY(-14vh) scale(0.55);
    }

    62.5% {
      transform: translateX(-15vw) translateY(39vh) scale(0.33);
    }

    66.6666666667% {
      transform: translateX(-28vw) translateY(-30vh) scale(0.29);
    }

    70.8333333333% {
      transform: translateX(40vw) translateY(27vh) scale(0.61);
    }

    75% {
      transform: translateX(22vw) translateY(-21vh) scale(0.53);
    }

    79.1666666667% {
      transform: translateX(-49vw) translateY(21vh) scale(0.64);
    }

    83.3333333333% {
      transform: translateX(7vw) translateY(14vh) scale(0.81);
    }

    87.5% {
      transform: translateX(12vw) translateY(14vh) scale(0.8);
    }

    91.6666666667% {
      transform: translateX(-44vw) translateY(-42vh) scale(0.49);
    }

    95.8333333333% {
      transform: translateX(43vw) translateY(41vh) scale(0.6);
    }

    100% {
      transform: translateX(6vw) translateY(-29vh) scale(0.7);
    }
  }

  .firefly:nth-child(6) {
    animation-name: move6;
  }

  .firefly:nth-child(6)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(6)::after {
    animation-duration: 15s, 10229ms;
    animation-delay: 0ms, 7081ms;
  }

  @keyframes move6 {
    0% {
      transform: translateX(23vw) translateY(18vh) scale(0.76);
    }

    4.1666666667% {
      transform: translateX(-26vw) translateY(6vh) scale(0.41);
    }

    8.3333333333% {
      transform: translateX(-1vw) translateY(-18vh) scale(0.63);
    }

    12.5% {
      transform: translateX(22vw) translateY(43vh) scale(0.63);
    }

    16.6666666667% {
      transform: translateX(25vw) translateY(48vh) scale(0.93);
    }

    20.8333333333% {
      transform: translateX(3vw) translateY(-29vh) scale(0.33);
    }

    25% {
      transform: translateX(26vw) translateY(0vh) scale(0.86);
    }

    29.1666666667% {
      transform: translateX(-33vw) translateY(5vh) scale(0.51);
    }

    33.3333333333% {
      transform: translateX(4vw) translateY(-9vh) scale(0.39);
    }

    37.5% {
      transform: translateX(26vw) translateY(12vh) scale(0.65);
    }

    41.6666666667% {
      transform: translateX(31vw) translateY(18vh) scale(0.42);
    }

    45.8333333333% {
      transform: translateX(25vw) translateY(-45vh) scale(0.9);
    }

    50% {
      transform: translateX(-11vw) translateY(49vh) scale(0.46);
    }

    54.1666666667% {
      transform: translateX(-45vw) translateY(48vh) scale(0.81);
    }

    58.3333333333% {
      transform: translateX(46vw) translateY(5vh) scale(0.57);
    }

    62.5% {
      transform: translateX(35vw) translateY(-47vh) scale(0.56);
    }

    66.6666666667% {
      transform: translateX(22vw) translateY(-44vh) scale(0.83);
    }

    70.8333333333% {
      transform: translateX(-40vw) translateY(-11vh) scale(0.73);
    }

    75% {
      transform: translateX(-19vw) translateY(13vh) scale(0.69);
    }

    79.1666666667% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.83);
    }

    83.3333333333% {
      transform: translateX(33vw) translateY(21vh) scale(0.67);
    }

    87.5% {
      transform: translateX(-36vw) translateY(27vh) scale(0.79);
    }

    91.6666666667% {
      transform: translateX(5vw) translateY(17vh) scale(0.56);
    }

    95.8333333333% {
      transform: translateX(29vw) translateY(-10vh) scale(0.51);
    }

    100% {
      transform: translateX(-1vw) translateY(-14vh) scale(0.29);
    }
  }

  .firefly:nth-child(7) {
    animation-name: move7;
  }

  .firefly:nth-child(7)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(7)::after {
    animation-duration: 18s, 6470ms;
    animation-delay: 0ms, 8336ms;
  }

  @keyframes move7 {
    0% {
      transform: translateX(40vw) translateY(47vh) scale(1);
    }

    3.5714285714% {
      transform: translateX(-21vw) translateY(-17vh) scale(0.78);
    }

    7.1428571429% {
      transform: translateX(24vw) translateY(17vh) scale(0.28);
    }

    10.7142857143% {
      transform: translateX(41vw) translateY(23vh) scale(0.96);
    }

    14.2857142857% {
      transform: translateX(14vw) translateY(13vh) scale(0.95);
    }

    17.8571428571% {
      transform: translateX(-17vw) translateY(49vh) scale(0.69);
    }

    21.4285714286% {
      transform: translateX(9vw) translateY(20vh) scale(0.29);
    }

    25% {
      transform: translateX(-11vw) translateY(30vh) scale(0.96);
    }

    28.5714285714% {
      transform: translateX(32vw) translateY(-27vh) scale(0.43);
    }

    32.1428571429% {
      transform: translateX(-48vw) translateY(-7vh) scale(0.9);
    }

    35.7142857143% {
      transform: translateX(-19vw) translateY(49vh) scale(0.41);
    }

    39.2857142857% {
      transform: translateX(7vw) translateY(-36vh) scale(0.5);
    }

    42.8571428571% {
      transform: translateX(-3vw) translateY(-40vh) scale(0.78);
    }

    46.4285714286% {
      transform: translateX(47vw) translateY(6vh) scale(0.26);
    }

    50% {
      transform: translateX(-33vw) translateY(-38vh) scale(0.3);
    }

    53.5714285714% {
      transform: translateX(-38vw) translateY(-10vh) scale(0.71);
    }

    57.1428571429% {
      transform: translateX(36vw) translateY(-4vh) scale(0.96);
    }

    60.7142857143% {
      transform: translateX(16vw) translateY(6vh) scale(0.62);
    }

    64.2857142857% {
      transform: translateX(-30vw) translateY(-39vh) scale(0.52);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(33vh) scale(0.45);
    }

    71.4285714286% {
      transform: translateX(-10vw) translateY(-28vh) scale(0.78);
    }

    75% {
      transform: translateX(48vw) translateY(16vh) scale(0.71);
    }

    78.5714285714% {
      transform: translateX(-45vw) translateY(-39vh) scale(0.63);
    }

    82.1428571429% {
      transform: translateX(22vw) translateY(-49vh) scale(0.37);
    }

    85.7142857143% {
      transform: translateX(49vw) translateY(27vh) scale(0.26);
    }

    89.2857142857% {
      transform: translateX(23vw) translateY(-44vh) scale(0.73);
    }

    92.8571428571% {
      transform: translateX(-2vw) translateY(29vh) scale(0.47);
    }

    96.4285714286% {
      transform: translateX(-2vw) translateY(-30vh) scale(0.57);
    }

    100% {
      transform: translateX(-10vw) translateY(-29vh) scale(0.96);
    }
  }

  .firefly:nth-child(8) {
    animation-name: move8;
  }

  .firefly:nth-child(8)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(8)::after {
    animation-duration: 15s, 6398ms;
    animation-delay: 0ms, 565ms;
  }

  @keyframes move8 {
    0% {
      transform: translateX(8vw) translateY(-41vh) scale(0.92);
    }

    4.5454545455% {
      transform: translateX(-26vw) translateY(43vh) scale(0.97);
    }

    9.0909090909% {
      transform: translateX(38vw) translateY(-44vh) scale(0.43);
    }

    13.6363636364% {
      transform: translateX(-35vw) translateY(40vh) scale(0.78);
    }

    18.1818181818% {
      transform: translateX(-7vw) translateY(-35vh) scale(0.68);
    }

    22.7272727273% {
      transform: translateX(-43vw) translateY(-29vh) scale(0.48);
    }

    27.2727272727% {
      transform: translateX(-18vw) translateY(27vh) scale(0.7);
    }

    31.8181818182% {
      transform: translateX(42vw) translateY(-45vh) scale(0.77);
    }

    36.3636363636% {
      transform: translateX(1vw) translateY(-13vh) scale(0.84);
    }

    40.9090909091% {
      transform: translateX(35vw) translateY(-26vh) scale(0.84);
    }

    45.4545454545% {
      transform: translateX(-3vw) translateY(48vh) scale(0.29);
    }

    50% {
      transform: translateX(9vw) translateY(-4vh) scale(0.99);
    }

    54.5454545455% {
      transform: translateX(-36vw) translateY(-36vh) scale(0.5);
    }

    59.0909090909% {
      transform: translateX(42vw) translateY(-44vh) scale(0.39);
    }

    63.6363636364% {
      transform: translateX(26vw) translateY(20vh) scale(0.76);
    }

    68.1818181818% {
      transform: translateX(30vw) translateY(48vh) scale(0.9);
    }

    72.7272727273% {
      transform: translateX(-9vw) translateY(35vh) scale(0.97);
    }

    77.2727272727% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.53);
    }

    81.8181818182% {
      transform: translateX(-49vw) translateY(-29vh) scale(0.26);
    }

    86.3636363636% {
      transform: translateX(-24vw) translateY(36vh) scale(0.36);
    }

    90.9090909091% {
      transform: translateX(-33vw) translateY(20vh) scale(0.52);
    }

    95.4545454545% {
      transform: translateX(14vw) translateY(10vh) scale(0.8);
    }

    100% {
      transform: translateX(14vw) translateY(-30vh) scale(0.54);
    }
  }

  .firefly:nth-child(9) {
    animation-name: move9;
  }

  .firefly:nth-child(9)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(9)::after {
    animation-duration: 15s, 5044ms;
    animation-delay: 0ms, 1556ms;
  }

  @keyframes move9 {
    0% {
      transform: translateX(27vw) translateY(-37vh) scale(0.9);
    }

    4.1666666667% {
      transform: translateX(12vw) translateY(-22vh) scale(0.44);
    }

    8.3333333333% {
      transform: translateX(-13vw) translateY(-28vh) scale(0.53);
    }

    12.5% {
      transform: translateX(-43vw) translateY(18vh) scale(0.39);
    }

    16.6666666667% {
      transform: translateX(34vw) translateY(31vh) scale(0.97);
    }

    20.8333333333% {
      transform: translateX(47vw) translateY(-33vh) scale(0.97);
    }

    25% {
      transform: translateX(35vw) translateY(-47vh) scale(0.42);
    }

    29.1666666667% {
      transform: translateX(-47vw) translateY(39vh) scale(0.4);
    }

    33.3333333333% {
      transform: translateX(40vw) translateY(-45vh) scale(0.65);
    }

    37.5% {
      transform: translateX(43vw) translateY(-23vh) scale(0.6);
    }

    41.6666666667% {
      transform: translateX(38vw) translateY(15vh) scale(0.63);
    }

    45.8333333333% {
      transform: translateX(49vw) translateY(34vh) scale(0.82);
    }

    50% {
      transform: translateX(-30vw) translateY(-9vh) scale(0.91);
    }

    54.1666666667% {
      transform: translateX(18vw) translateY(-31vh) scale(0.73);
    }

    58.3333333333% {
      transform: translateX(32vw) translateY(-11vh) scale(0.97);
    }

    62.5% {
      transform: translateX(-12vw) translateY(31vh) scale(0.77);
    }

    66.6666666667% {
      transform: translateX(26vw) translateY(12vh) scale(0.84);
    }

    70.8333333333% {
      transform: translateX(2vw) translateY(-46vh) scale(0.38);
    }

    75% {
      transform: translateX(46vw) translateY(35vh) scale(0.79);
    }

    79.1666666667% {
      transform: translateX(50vw) translateY(26vh) scale(0.46);
    }

    83.3333333333% {
      transform: translateX(-33vw) translateY(-9vh) scale(0.48);
    }

    87.5% {
      transform: translateX(6vw) translateY(-18vh) scale(0.78);
    }

    91.6666666667% {
      transform: translateX(33vw) translateY(-31vh) scale(0.75);
    }

    95.8333333333% {
      transform: translateX(27vw) translateY(-13vh) scale(0.52);
    }

    100% {
      transform: translateX(4vw) translateY(11vh) scale(0.86);
    }
  }

  .firefly:nth-child(10) {
    animation-name: move10;
  }

  .firefly:nth-child(10)::before {
    animation-duration: 15s;
  }

  .firefly:nth-child(10)::after {
    animation-duration: 15s, 5550ms;
    animation-delay: 0ms, 4125ms;
  }

  @keyframes move10 {
    0% {
      transform: translateX(-26vw) translateY(-38vh) scale(0.49);
    }

    5.5555555556% {
      transform: translateX(21vw) translateY(7vh) scale(0.8);
    }

    11.1111111111% {
      transform: translateX(-43vw) translateY(-2vh) scale(0.75);
    }

    16.6666666667% {
      transform: translateX(29vw) translateY(-17vh) scale(1);
    }

    22.2222222222% {
      transform: translateX(-43vw) translateY(13vh) scale(0.88);
    }

    27.7777777778% {
      transform: translateX(33vw) translateY(-48vh) scale(0.86);
    }

    33.3333333333% {
      transform: translateX(17vw) translateY(-23vh) scale(0.95);
    }

    38.8888888889% {
      transform: translateX(-7vw) translateY(39vh) scale(0.4);
    }

    44.4444444444% {
      transform: translateX(-44vw) translateY(-35vh) scale(0.39);
    }

    50% {
      transform: translateX(-23vw) translateY(-10vh) scale(0.54);
    }

    55.5555555556% {
      transform: translateX(8vw) translateY(39vh) scale(0.63);
    }

    61.1111111111% {
      transform: translateX(-3vw) translateY(28vh) scale(0.46);
    }

    66.6666666667% {
      transform: translateX(19vw) translateY(-35vh) scale(0.55);
    }

    72.2222222222% {
      transform: translateX(-32vw) translateY(1vh) scale(0.87);
    }

    77.7777777778% {
      transform: translateX(-18vw) translateY(11vh) scale(0.62);
    }

    83.3333333333% {
      transform: translateX(-24vw) translateY(-31vh) scale(0.42);
    }

    88.8888888889% {
      transform: translateX(5vw) translateY(34vh) scale(0.5);
    }

    94.4444444444% {
      transform: translateX(-11vw) translateY(32vh) scale(0.36);
    }

    100% {
      transform: translateX(5vw) translateY(-8vh) scale(0.99);
    }
  }

  .firefly:nth-child(11) {
    animation-name: move11;
  }

  .firefly:nth-child(11)::before {
    animation-duration: 16s;
  }

  .firefly:nth-child(11)::after {
    animation-duration: 16s, 10297ms;
    animation-delay: 0ms, 4707ms;
  }

  @keyframes move11 {
    0% {
      transform: translateX(-36vw) translateY(-2vh) scale(0.37);
    }

    4.7619047619% {
      transform: translateX(-27vw) translateY(30vh) scale(0.38);
    }

    9.5238095238% {
      transform: translateX(45vw) translateY(-23vh) scale(0.33);
    }

    14.2857142857% {
      transform: translateX(25vw) translateY(9vh) scale(0.7);
    }

    19.0476190476% {
      transform: translateX(24vw) translateY(-13vh) scale(0.48);
    }

    23.8095238095% {
      transform: translateX(43vw) translateY(-5vh) scale(0.68);
    }

    28.5714285714% {
      transform: translateX(41vw) translateY(2vh) scale(0.83);
    }

    33.3333333333% {
      transform: translateX(-46vw) translateY(-13vh) scale(0.7);
    }

    38.0952380952% {
      transform: translateX(-39vw) translateY(-25vh) scale(0.85);
    }

    42.8571428571% {
      transform: translateX(-27vw) translateY(23vh) scale(0.33);
    }

    47.619047619% {
      transform: translateX(-13vw) translateY(45vh) scale(0.3);
    }

    52.380952381% {
      transform: translateX(10vw) translateY(-28vh) scale(0.55);
    }

    57.1428571429% {
      transform: translateX(-30vw) translateY(-16vh) scale(0.26);
    }

    61.9047619048% {
      transform: translateX(40vw) translateY(30vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(45vw) translateY(37vh) scale(0.79);
    }

    71.4285714286% {
      transform: translateX(-13vw) translateY(-24vh) scale(0.62);
    }

    76.1904761905% {
      transform: translateX(33vw) translateY(-12vh) scale(0.94);
    }

    80.9523809524% {
      transform: translateX(10vw) translateY(-49vh) scale(0.3);
    }

    85.7142857143% {
      transform: translateX(-15vw) translateY(48vh) scale(0.48);
    }

    90.4761904762% {
      transform: translateX(-42vw) translateY(40vh) scale(0.54);
    }

    95.2380952381% {
      transform: translateX(-22vw) translateY(0vh) scale(0.63);
    }

    100% {
      transform: translateX(32vw) translateY(14vh) scale(0.79);
    }
  }

  .firefly:nth-child(12) {
    animation-name: move12;
  }

  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }

  .firefly:nth-child(12)::after {
    animation-duration: 9s, 8018ms;
    animation-delay: 0ms, 4673ms;
  }

  @keyframes move12 {
    0% {
      transform: translateX(-30vw) translateY(47vh) scale(0.57);
    }

    3.5714285714% {
      transform: translateX(-19vw) translateY(19vh) scale(0.52);
    }

    7.1428571429% {
      transform: translateX(-14vw) translateY(48vh) scale(0.9);
    }

    10.7142857143% {
      transform: translateX(-44vw) translateY(13vh) scale(0.38);
    }

    14.2857142857% {
      transform: translateX(-10vw) translateY(43vh) scale(0.49);
    }

    17.8571428571% {
      transform: translateX(39vw) translateY(49vh) scale(0.79);
    }

    21.4285714286% {
      transform: translateX(50vw) translateY(-17vh) scale(0.4);
    }

    25% {
      transform: translateX(-20vw) translateY(22vh) scale(0.48);
    }

    28.5714285714% {
      transform: translateX(-27vw) translateY(32vh) scale(0.92);
    }

    32.1428571429% {
      transform: translateX(11vw) translateY(-7vh) scale(0.99);
    }

    35.7142857143% {
      transform: translateX(-27vw) translateY(46vh) scale(0.34);
    }

    39.2857142857% {
      transform: translateX(-24vw) translateY(3vh) scale(0.36);
    }

    42.8571428571% {
      transform: translateX(48vw) translateY(-30vh) scale(0.33);
    }

    46.4285714286% {
      transform: translateX(-44vw) translateY(-30vh) scale(0.5);
    }

    50% {
      transform: translateX(44vw) translateY(-2vh) scale(0.57);
    }

    53.5714285714% {
      transform: translateX(-47vw) translateY(-18vh) scale(0.39);
    }

    57.1428571429% {
      transform: translateX(45vw) translateY(24vh) scale(0.54);
    }

    60.7142857143% {
      transform: translateX(-32vw) translateY(14vh) scale(0.64);
    }

    64.2857142857% {
      transform: translateX(-24vw) translateY(37vh) scale(0.81);
    }

    67.8571428571% {
      transform: translateX(-37vw) translateY(28vh) scale(0.67);
    }

    71.4285714286% {
      transform: translateX(11vw) translateY(-14vh) scale(0.71);
    }

    75% {
      transform: translateX(-28vw) translateY(-6vh) scale(0.47);
    }

    78.5714285714% {
      transform: translateX(15vw) translateY(-15vh) scale(0.79);
    }

    82.1428571429% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.51);
    }

    85.7142857143% {
      transform: translateX(43vw) translateY(47vh) scale(0.52);
    }

    89.2857142857% {
      transform: translateX(35vw) translateY(-38vh) scale(0.62);
    }

    92.8571428571% {
      transform: translateX(22vw) translateY(-44vh) scale(1);
    }

    96.4285714286% {
      transform: translateX(5vw) translateY(31vh) scale(0.33);
    }

    100% {
      transform: translateX(3vw) translateY(39vh) scale(0.63);
    }
  }

  .firefly:nth-child(13) {
    animation-name: move13;
  }

  .firefly:nth-child(13)::before {
    animation-duration: 10s;
  }

  .firefly:nth-child(13)::after {
    animation-duration: 10s, 9679ms;
    animation-delay: 0ms, 8051ms;
  }

  @keyframes move13 {
    0% {
      transform: translateX(22vw) translateY(-45vh) scale(0.86);
    }

    4.1666666667% {
      transform: translateX(20vw) translateY(6vh) scale(0.73);
    }

    8.3333333333% {
      transform: translateX(-36vw) translateY(15vh) scale(0.72);
    }

    12.5% {
      transform: translateX(25vw) translateY(-1vh) scale(0.38);
    }

    16.6666666667% {
      transform: translateX(-19vw) translateY(-28vh) scale(0.91);
    }

    20.8333333333% {
      transform: translateX(-45vw) translateY(29vh) scale(0.47);
    }

    25% {
      transform: translateX(0vw) translateY(14vh) scale(0.55);
    }

    29.1666666667% {
      transform: translateX(-41vw) translateY(-28vh) scale(0.39);
    }

    33.3333333333% {
      transform: translateX(-11vw) translateY(34vh) scale(0.37);
    }

    37.5% {
      transform: translateX(-10vw) translateY(38vh) scale(0.75);
    }

    41.6666666667% {
      transform: translateX(-3vw) translateY(7vh) scale(0.47);
    }

    45.8333333333% {
      transform: translateX(22vw) translateY(-11vh) scale(0.97);
    }

    50% {
      transform: translateX(41vw) translateY(20vh) scale(0.37);
    }

    54.1666666667% {
      transform: translateX(23vw) translateY(-14vh) scale(0.41);
    }

    58.3333333333% {
      transform: translateX(43vw) translateY(-30vh) scale(0.34);
    }

    62.5% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.32);
    }

    66.6666666667% {
      transform: translateX(-21vw) translateY(-11vh) scale(0.42);
    }

    70.8333333333% {
      transform: translateX(47vw) translateY(11vh) scale(0.29);
    }

    75% {
      transform: translateX(20vw) translateY(-27vh) scale(0.4);
    }

    79.1666666667% {
      transform: translateX(-47vw) translateY(37vh) scale(0.52);
    }

    83.3333333333% {
      transform: translateX(0vw) translateY(3vh) scale(0.44);
    }

    87.5% {
      transform: translateX(27vw) translateY(47vh) scale(0.88);
    }

    91.6666666667% {
      transform: translateX(-5vw) translateY(3vh) scale(0.82);
    }

    95.8333333333% {
      transform: translateX(16vw) translateY(10vh) scale(0.61);
    }

    100% {
      transform: translateX(5vw) translateY(32vh) scale(0.33);
    }
  }

  .firefly:nth-child(14) {
    animation-name: move14;
  }

  .firefly:nth-child(14)::before {
    animation-duration: 18s;
  }

  .firefly:nth-child(14)::after {
    animation-duration: 18s, 8910ms;
    animation-delay: 0ms, 3832ms;
  }

  @keyframes move14 {
    0% {
      transform: translateX(21vw) translateY(3vh) scale(0.42);
    }

    4.347826087% {
      transform: translateX(-30vw) translateY(-40vh) scale(0.96);
    }

    8.6956521739% {
      transform: translateX(-26vw) translateY(-40vh) scale(0.82);
    }

    13.0434782609% {
      transform: translateX(-19vw) translateY(-2vh) scale(0.72);
    }

    17.3913043478% {
      transform: translateX(-49vw) translateY(36vh) scale(0.31);
    }

    21.7391304348% {
      transform: translateX(4vw) translateY(-6vh) scale(0.52);
    }

    26.0869565217% {
      transform: translateX(-16vw) translateY(40vh) scale(0.96);
    }

    30.4347826087% {
      transform: translateX(15vw) translateY(15vh) scale(0.9);
    }

    34.7826086957% {
      transform: translateX(26vw) translateY(-6vh) scale(0.28);
    }

    39.1304347826% {
      transform: translateX(9vw) translateY(46vh) scale(0.35);
    }

    43.4782608696% {
      transform: translateX(-25vw) translateY(19vh) scale(0.79);
    }

    47.8260869565% {
      transform: translateX(-33vw) translateY(46vh) scale(0.74);
    }

    52.1739130435% {
      transform: translateX(-22vw) translateY(1vh) scale(0.36);
    }

    56.5217391304% {
      transform: translateX(-49vw) translateY(9vh) scale(0.52);
    }

    60.8695652174% {
      transform: translateX(-29vw) translateY(39vh) scale(0.7);
    }

    65.2173913043% {
      transform: translateX(25vw) translateY(31vh) scale(0.34);
    }

    69.5652173913% {
      transform: translateX(19vw) translateY(18vh) scale(0.85);
    }

    73.9130434783% {
      transform: translateX(-25vw) translateY(-25vh) scale(0.71);
    }

    78.2608695652% {
      transform: translateX(-46vw) translateY(-32vh) scale(0.77);
    }

    82.6086956522% {
      transform: translateX(31vw) translateY(18vh) scale(0.41);
    }

    86.9565217391% {
      transform: translateX(41vw) translateY(-27vh) scale(0.5);
    }

    91.3043478261% {
      transform: translateX(37vw) translateY(12vh) scale(0.78);
    }

    95.652173913% {
      transform: translateX(-15vw) translateY(-29vh) scale(0.68);
    }

    100% {
      transform: translateX(31vw) translateY(30vh) scale(0.96);
    }
  }

  .firefly:nth-child(15) {
    animation-name: move15;
  }

  .firefly:nth-child(15)::before {
    animation-duration: 17s;
  }

  .firefly:nth-child(15)::after {
    animation-duration: 17s, 8620ms;
    animation-delay: 0ms, 742ms;
  }

  @keyframes move15 {
    0% {
      transform: translateX(30vw) translateY(29vh) scale(0.57);
    }

    4.7619047619% {
      transform: translateX(-23vw) translateY(-47vh) scale(0.3);
    }

    9.5238095238% {
      transform: translateX(33vw) translateY(46vh) scale(0.27);
    }

    14.2857142857% {
      transform: translateX(46vw) translateY(7vh) scale(0.82);
    }

    19.0476190476% {
      transform: translateX(-25vw) translateY(41vh) scale(0.87);
    }

    23.8095238095% {
      transform: translateX(-46vw) translateY(22vh) scale(0.99);
    }

    28.5714285714% {
      transform: translateX(4vw) translateY(11vh) scale(0.59);
    }

    33.3333333333% {
      transform: translateX(-43vw) translateY(20vh) scale(0.97);
    }

    38.0952380952% {
      transform: translateX(25vw) translateY(-18vh) scale(0.79);
    }

    42.8571428571% {
      transform: translateX(18vw) translateY(-7vh) scale(0.4);
    }

    47.619047619% {
      transform: translateX(-10vw) translateY(-36vh) scale(1);
    }

    52.380952381% {
      transform: translateX(-4vw) translateY(28vh) scale(0.8);
    }

    57.1428571429% {
      transform: translateX(-43vw) translateY(50vh) scale(0.99);
    }

    61.9047619048% {
      transform: translateX(30vw) translateY(-40vh) scale(0.99);
    }

    66.6666666667% {
      transform: translateX(48vw) translateY(-7vh) scale(0.73);
    }

    71.4285714286% {
      transform: translateX(18vw) translateY(-19vh) scale(0.47);
    }

    76.1904761905% {
      transform: translateX(-14vw) translateY(-35vh) scale(0.71);
    }

    80.9523809524% {
      transform: translateX(34vw) translateY(17vh) scale(0.97);
    }

    85.7142857143% {
      transform: translateX(48vw) translateY(-29vh) scale(0.88);
    }

    90.4761904762% {
      transform: translateX(44vw) translateY(46vh) scale(0.99);
    }

    95.2380952381% {
      transform: translateX(22vw) translateY(50vh) scale(0.85);
    }

    100% {
      transform: translateX(-3vw) translateY(-48vh) scale(0.54);
    }
  }

  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes flash {

    0%,
    30%,
    100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw #FFF01F;
    }

    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw #FFF01F;
    }
  }

  .projectName {
    width: 50%;
  }

  .beastDiv {}

  .beast {
    width: 750px;
  }

  .fire {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 0px;
  }

  .fire4 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire4 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -130px;
    top: 0px;
  }

  .fire5 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire5 {
    width: 75px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    top: 0px;
  }

  .fire6 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire6 {
    width: 75px;
    position: absolute;
    left: 50%;
    top: 0px;
  }

  .fire2 {
    justify-content: space-between;
    flex-flow: row nowrap;
    display: flex;
  }

  .mo-fire2 {
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire2 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 {
    width: 60px;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 0px;
    transform: rotate(10deg);

  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }

  .mo-fire3 svg {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: -50%;
  }


  .flame {
    animation-name: flamefly;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    opacity: 0;
    transform-origin: 50% 50% 0;
  }

  .flame.one {
    animation-delay: 1s;
    animation-duration: 3s;
  }

  .flame3.two {
    animation-duration: 5s;
    animation-delay: 1s;
  }

  .flame-main {
    animation-name: flameWobble;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .flame-main.one {
    animation-duration: 4s;
    animation-delay: 1s;
  }

  .flame-main.two {
    animation-duration: 3s;
    animation-delay: 2s;
  }

  .flame-main.three {
    animation-duration: 2.1s;
    animation-delay: 3s;
  }

  .flame-main.four {
    animation-duration: 3.2s;
    animation-delay: 4s;
  }

  .flame-main.five {
    animation-duration: 2.5s;
    animation-delay: 5s;
  }

  @keyframes flameWobble {
    50% {
      transform: scale(1, 1.2) translate(0, -30px) rotate(-2deg);
    }
  }

  @keyframes flamefly {
    0% {
      transform: translate(0) rotate(180deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(-20px, -100px) rotate(180deg);
      opacity: 0;
    }
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 22px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: black;
    text-shadow: 0 0 5px black;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    border: none;
  }

  .mintingAmount {}

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: bold;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 55px;
    height: 55px;
    cursor: pointer;

  }

  .price {
    text-align: center;
    font-size: 35px;
    color: black;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  @keyframes titleFont {
    0% {
      opacity: 1;
    }

    34% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    64% {
      opacity: 0;
    }

    65% {
      opacity: 1;
    }

    84% {
      opacity: 1;
    }

    85% {
      opacity: 0;
    }

    99% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleFont2 {
    0% {
      opacity: 0;
    }

    24% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    70% {
      opacity: 1
    }

    71% {
      opacity: 0
    }

    85% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  .intro {
    color: black;
    font-size: 120px;
    position: relative;
    margin-bottom: 25%;
  }

  .name1 {
    position: absolute;
    animation-name: titleFont;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .name2 {
    position: absolute;
    font-family: 'Underdog', sans-serif;
    animation-name: titleFont2;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .shakeWord {
    animation: shake 1s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }

  .name3 {
    position: absolute;
    animation-name: titleFont3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-top: 0.5%;
  }

  .intro2 {
    color: black;
    font-size: 40px;
    text-shadow: 4px 3px 0 #383d6e18;
    margin-top: -25%;
  }

  .mintDiv {
    width: 40%;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid black;
    box-shadow: black -5px 5px;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;

  }

  .loadingContainer {}

  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .discord:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black -5px 5px;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    box-shadow: black 5px 5px;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    background-color: #fef8e2;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    box-shadow: black 0px 5px;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border-radius: 10px;
    background-color: #fef8e2;
  }

  .btnfos-0-4 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(255, 174, 0);
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: black;
  }


  .btnfos-0-3:hover {

    color: rgb(78, 78, 78);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(37, 59, 23);
    font-size: 25px;
  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 85%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .wallet2 {
    background-color: #fef8e2;
    color: black;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 33px;
    padding-left: 25px;
    padding-right: 25px;
    border: 2px solid black;
    transition: transform .2s;
    box-shadow: black 0px 3px;
    border-radius: 10px;
    font-weight: bold;
  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 60px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    background-image: linear-gradient(144deg, #a08fa1, #9895a4 50%, #9696a5);
    border: 0;
    box-shadow: black -5px 5px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    display: flex;
    transition: transform .5s;
    border-radius: 10px;
    transform: rotate(-3deg);
    border: 2px solid black;
    margin-top: 5%;
  }

  .wallet3:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transform: scale(1.2);
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 70%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 2px solid #585858;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #2c2c2c;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background-color: white;
    min-height: 100vh;
    height: max-content;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}